import { UserGroups } from '../../../../common/constants';
import { BikeTransmissionLayout } from '../../../../resources/bikes/transmission';
import { RouteType } from '../../../routes/routesConfig';

const BikeTransmission = () => {
  return <BikeTransmissionLayout />;
};

const bikeTransmissionRoute: RouteType = {
  element: <BikeTransmission />,
  state: 'transmission',
  index: true,
  path: '/resources/bikes/transmissions',
  sideBarProps: {
    displayText: 'Transmissions',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeTransmission, bikeTransmissionRoute };
