import { Space, Typography, DatePicker } from 'antd';
import { FlexColumn } from '../components/Flex';
import { PeriodRadio } from './components/PeriodRadio';
import { BrandSelect } from './components/BrandSelect';
import { ChartBox } from './components/ChartBox';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

interface IBrandsAnalyticsLayoutProps {
  data: any;
  isFetching: boolean;
  selectedBrand: number | undefined;
  setSelectedBrand: (value: number | undefined) => void;
  range: string[];
  setRange: (value: string[]) => void;
  setTimePeriod: (value: 'day' | 'month' | 'year') => void;
  brandNames: any;
  isLoadingBrandNames: boolean;
  onRangeChange: (
    dates: null | (dayjs.Dayjs | null)[],
    dateStrings: string[],
  ) => void;
  rangePresets: any;
  periodTime: 'day' | 'month' | 'year';
}

export const BrandsAnalyticsLayout = ({
  data,
  isFetching,
  selectedBrand,
  setSelectedBrand,
  setTimePeriod,
  brandNames,
  isLoadingBrandNames,
  rangePresets,
  onRangeChange,
  periodTime,
}: IBrandsAnalyticsLayoutProps) => {
  function transformAndSortData(
    data: any,
    selectedBrand: number | undefined,
    periodTime: 'day' | 'month' | 'year',
  ) {
    const selectedData =
      data.find((d: any) => d.id === selectedBrand)?.views || [];

    const transformedData = selectedData.map((d: any) => ({
      date: periodTime === 'day' ? dayjs(d.date).format('YYYY-MM-DD') : d.date,
      views: d.views,
    }));

    return transformedData.sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA.getTime() - dateB.getTime();
    });
  }

  return (
    <>
      <FlexColumn
        style={{
          padding: 24,
          marginTop: 24,
          background: '#fff',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 2px 5px',
          borderRadius: '5px',
          height: '600px',
        }}
      >
        <Space
          style={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography.Title
            level={4}
            style={{
              margin: 0,
            }}
          >
            Brands Analytics - New
          </Typography.Title>
          <Space
            style={{
              marginBottom: 24,
              justifyContent: 'flex-end',
            }}
          >
            <PeriodRadio setPeriod={setTimePeriod} />
            <RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              defaultValue={[dayjs().add(-7, 'd'), dayjs()]}
            />
          </Space>
        </Space>
        <BrandSelect
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          brandNames={brandNames}
          isLoadingBrandNames={isLoadingBrandNames}
        />
        <ChartBox
          data={
            selectedBrand
              ? transformAndSortData(data, selectedBrand, periodTime)
              : []
          }
          isFetching={isFetching}
        />
      </FlexColumn>
    </>
  );
};
