import api from '../../../api/apiIndex';
import { RentalDealer } from '../../../models/dealers/rentals/RentalDealer';

export const rentalDealersEndpoint = 'v2/admin/rental-dealers';

interface IGetRentalDealers {
  active?: boolean;
  type?: string;
}
export interface IRentalDealersPayload {
  country_id: number;
  name: string;
  arabic_name: string;
  logo: string;
  city_id: number;
}

export const getRentalsDealers = async ({
  active,
  type,
}: IGetRentalDealers): Promise<RentalDealer[]> => {
  const response = await api.get(rentalDealersEndpoint, {
    params: { active },
  });
  return response.data;
};
