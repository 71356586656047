import { queryClient } from '../../App';
import { refreshAccessToken } from '../../api/ApiInterceptor';
import api from '../../api/apiIndex';
import User from '../../models/User';
import { TLoginMutation } from '../hooks/useLogin';

const logoutUrl = '/v2/auth/logout';

export type TLoginResponse = {
  accessToken: string;
  refreshToken: string;
  userData: any;
};

type getAuthUserProps = {
  apiToken: string | null;
};

const loginUrl = '/v2/auth/login';

export const deleteApiTokens = () => {
  localStorage.removeItem('refreshToken');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('accessToken');
  localStorage.removeItem('accessToken');
  delete api.defaults.headers.common.Authorization;
};

const login = async ({
  email,
  password,
}: TLoginMutation): Promise<TLoginResponse> => {
  const response = await api.post(loginUrl, {
    email,
    password,
  });
  const accessToken = response.data.access_token;
  const refreshToken = response.data.refresh_token;
  const userData = JSON.parse(atob(accessToken.split('.')[1]));
  return { accessToken, refreshToken, userData };
};

const logout = async () => {
  const refresh_token =
    localStorage.getItem('refreshToken') ||
    sessionStorage.getItem('refreshToken');
  const response = await api.post(logoutUrl, {
    refresh_token,
  });
  return response.data;
};

const getAuthenticatedUser = async () => {
  const apiToken = await refreshAccessToken();
  api.defaults.headers.common.Authorization = `Bearer ${apiToken}`;
  return JSON.parse(atob(apiToken.split('.')[1]));
};
export { login, logout, getAuthenticatedUser };
