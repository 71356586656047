import { Modal } from 'antd';
import { CarOptionalFeatureForm } from './CarOptionalFeatureForm';
import { IEngineType } from '../../../../models/resources/CarEngineType';
import { IOptionalFeature } from '../utils/apiUtils';

interface CarOptionalFeatureModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarOptionalFeature?: IOptionalFeature;
  setSelectedCarOptionalFeature: React.Dispatch<React.SetStateAction<any>>;
  carEngineTypes: IEngineType[] | null;
}

export const CarOptionalFeatureModal = ({
  isOpen,
  setIsOpen,
  selectedCarOptionalFeature,
  setSelectedCarOptionalFeature,
  carEngineTypes,
}: CarOptionalFeatureModalProps) => {
  const isEdit = !!selectedCarOptionalFeature;

  const handleClose = () => {
    try {
      setIsOpen(false);
      setSelectedCarOptionalFeature(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Optional Feature"
      open={isOpen}
      onCancel={handleClose}
      width={1200}
      footer={null}
      key={selectedCarOptionalFeature?.id}
      destroyOnClose={true}
    >
      <CarOptionalFeatureForm
        handleClose={handleClose}
        selectedCarOptionalFeature={selectedCarOptionalFeature}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setSelectedCarOptionalFeature={setSelectedCarOptionalFeature}
        carEngineTypes={carEngineTypes}
      />
    </Modal>
  );
};
