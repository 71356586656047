import { Button, Col, Form, Input, Radio, Row, Select, Space } from 'antd';
import { INewBikeDealPayload } from '../apis';
import { INewBike } from '../../../../models/listings/bikes/newBikes';
import { IBikeModel } from '../../../../models/resources/bikes/BikeModel';
import { useEffect, useState } from 'react';
import { Option } from '../../../../components/inputs/DropDown';
import { useCountries } from '../../../../countries/hooks/useCountries';
import { ICity } from '../../../../models/countries/City';
import { CustomizedRequiredMark } from '../../../../components/misc/RequiredMark';
import { RadioChangeEvent } from 'antd/lib';
import { useGetAllDealers } from '../../../../dealers/Automotive/hooks';

const { Item } = Form;

type AddEditNewBikeDealFormProps = {
  deal: INewBike;
  model: IBikeModel;
  isEditing: boolean;
  onSubmit: (deal: INewBikeDealPayload) => Promise<void>;
  isLoading: boolean;
  onClose: () => void;
};

export const AddEditNewBikeDealForm = ({
  deal,
  isEditing,
  isLoading,
  model,
  onClose,
  onSubmit,
}: AddEditNewBikeDealFormProps) => {
  const [form] = Form.useForm();
  const { data: dealers, isLoading: isLoadingDealers } = useGetAllDealers({
    enabled: true,
  });

  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const countryId = Form.useWatch('country_id', form);

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const dealerFormat = () => {
    if (Array.isArray(dealers)) {
      return dealers.map(dealer => ({
        id: dealer.id,
        name: dealer.name,
      }));
    } else if (typeof dealers === 'object') {
      return [dealers];
    } else {
      return null;
    }
  };

  const selectedDealer = dealerFormat();

  const initialValues = {
    bike_dealer_id: deal?.bike_dealers.id,
    title: deal?.title,
    arabic_title: deal?.t?.[0]?.title,
    country_id: countries?.find(country =>
      country?.cities?.find(city => city?.id === deal?.city_id),
    )?.id,
    city_id: deal?.city_id,
    price: isEditing ? deal?.price : null,
    call_to_action: isEditing ? deal?.call_to_action : null,
    arabic_call_to_action: isEditing ? deal?.t?.[0]?.call_to_action : null,
    test_drive_url: deal?.test_drive_url,
    arabic_test_drive_url: deal?.t?.[0]?.test_drive_url,
    description: deal?.description,
    arabic_description: deal?.t?.[0]?.description,
    bike_model_id: model?.id,
  };

  const [selectedValue, setSelectedValue] = useState(
    initialValues.price !== null ? initialValues.price : null,
  );

  const [selectedLeadValue, setSelectedLeadValue] = useState(
    initialValues.call_to_action !== null &&
      initialValues.call_to_action?.trim() !== ''
      ? initialValues.call_to_action
      : initialValues.arabic_call_to_action !== null &&
        initialValues.arabic_call_to_action?.trim() !== ''
      ? initialValues.arabic_call_to_action
      : null,
  );

  const handleLeadRadioChange = (e: RadioChangeEvent) => {
    setSelectedLeadValue(e.target.value);
    if (e.target.value === null) {
      form.setFieldsValue({
        call_to_action: null,
        arabic_call_to_action: null,
      });
    }
  };

  const handlePriceRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [deal, form, countries]);

  return (
    <Form
      form={form}
      onFinish={async (values: INewBikeDealPayload) => {
        try {
          const valuesWithModelId = {
            ...values,
            bike_model_id: model?.id,
          };
          await onSubmit(valuesWithModelId);
        } catch (error) {
          console.log(error);
        }
      }}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={CustomizedRequiredMark}
    >
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="bike_dealer_id"
            label="Dealer"
            rules={[{ required: true, message: 'Please select a Dealer' }]}
          >
            <Select
              showSearch
              disabled={!dealers || isLoadingDealers}
              placeholder="Select a Dealer"
              optionFilterProp="children"
            >
              {selectedDealer &&
                selectedDealer.map(dealer => (
                  <Select.Option key={dealer.id} value={dealer.id}>
                    {dealer.name}
                  </Select.Option>
                ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter title' }]}
          >
            <Input placeholder="Please enter title" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_title"
            label="Arabic Title"
            rules={[{ required: true, message: 'Please enter arabic title' }]}
          >
            <Input placeholder="Please enter arabic title" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: 'Please select a country',
              },
            ]}
          >
            <Select
              disabled={!countries || isLoadingCountries}
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={() => {
                form.setFieldsValue({
                  city_id: undefined,
                });
              }}
              size="large"
            >
              {countries?.map(country => {
                return (
                  <Select.Option key={country?.id} value={country?.id}>
                    {country?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="City"
            name="city_id"
            rules={[
              {
                required: true,
                message: 'Please select a city',
              },
            ]}
          >
            <Select
              disabled={!countryId || isLoadingCountries}
              showSearch
              placeholder="Select a city"
              optionFilterProp="children"
              size="large"
            >
              {cityOptions?.map(city => {
                return (
                  <Select.Option key={city.code} value={city.code}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={24} wrap>
        <Col span={24}>
          <Item name="price">
            <Radio.Group
              onChange={handlePriceRadioChange}
              value={selectedValue}
            >
              <Radio value={null}>Get Quotation</Radio>
              <Radio
                value={initialValues.price !== null ? initialValues.price : ''}
              >
                Price
              </Radio>
            </Radio.Group>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="price"
            label="Price"
            rules={
              selectedValue !== null
                ? [
                    {
                      required: true,
                      message: 'Please enter a price',
                    },
                  ]
                : []
            }
          >
            <Input
              placeholder="Please enter a price"
              disabled={selectedValue === null}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={24} wrap>
        <Col span={24}>
          <Item name="call_to_action">
            <Radio.Group
              onChange={handleLeadRadioChange}
              value={selectedLeadValue}
            >
              <Radio value={null}>Leads</Radio>
              <Radio
                value={
                  initialValues.call_to_action !== null
                    ? initialValues.call_to_action
                    : '' && initialValues.arabic_call_to_action !== null
                    ? initialValues.arabic_call_to_action
                    : ''
                }
              >
                Traffic
              </Radio>
            </Radio.Group>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="call_to_action"
            label="Call to Action Url"
            rules={
              selectedLeadValue !== null
                ? [
                    {
                      required: true,
                      message: 'Please enter a call to action url',
                    },
                  ]
                : []
            }
          >
            <Input
              placeholder="Please enter a call to action url"
              disabled={selectedLeadValue === null}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_call_to_action"
            label="Arabic Call to Action Url"
            rules={
              selectedLeadValue !== null
                ? [
                    {
                      required: true,
                      message: 'Please enter an arabic call to action url',
                    },
                  ]
                : []
            }
          >
            <Input
              placeholder="Please enter an arabic call to action url"
              disabled={selectedLeadValue === null}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="test_drive_url"
            label="Test Drive Url"
            rules={[
              {
                message: 'Please enter test drive url',
              },
            ]}
          >
            <Input placeholder="Please enter test drive url" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_test_drive_url"
            label="Arabic Test Drive Url"
            rules={[
              {
                message: 'Please enter arabic test drive url',
              },
            ]}
          >
            <Input placeholder="Please enter arabic test drive url" />
          </Item>
        </Col>
      </Row>
      <Row gutter={24} wrap>
        <Col span={12}>
          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Please enter description',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Please enter description"
              rows={4}
              maxLength={500}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_description"
            label="Arabic Description"
            rules={[
              {
                required: true,
                message: 'Please enter arabic description',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Please enter arabic description"
              rows={4}
              maxLength={500}
            />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoadingDealers || isLoadingCountries}
          >
            {isEditing ? 'Edit Deal' : 'Add Deal'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
