import ImageEditOverlay from '../../../components/misc/ImageEditOverlay';
import UploadedImagePreview from '../../../components/misc/UploadedImagePreview';

type ModelDisplayCardProps = {
  coverImageUrl: string;
  brandLogoUrl: string;
  brandName: string | number;
  modelName: string;
  trimName?: string | null;
  onEdit: () => void;
  onDelete: () => void;
  canEdit?: boolean;
};

const ModelDisplayCard = ({
  coverImageUrl,
  brandLogoUrl,
  brandName,
  modelName,
  trimName = null,
  onEdit,
  onDelete,
  canEdit = true,
}: ModelDisplayCardProps) => {
  return (
    <div
      className="flex flex-column shadow-4"
      style={{ height: '280px', aspectRatio: '1' }}
    >
      <div className="relative flex flex-column" style={{ height: '80%' }}>
        <UploadedImagePreview url={coverImageUrl} preview={false} />
        {canEdit && <ImageEditOverlay onDelete={onDelete} onEdit={onEdit} />}
      </div>
      <div className="flex relative justify-content-between p-2">
        <div style={{ fontSize: '18px' }}>
          <p className="m-0">{brandName}</p>
          <p className="m-0">
            <strong>{modelName}</strong>
          </p>
          {trimName != null && (
            <h4 className="m-0">
              <strong>{trimName}</strong>
            </h4>
          )}
        </div>
        <div className="relative" style={{ aspectRatio: '1', height: '50px' }}>
          <UploadedImagePreview url={brandLogoUrl} preview={false} />
        </div>
      </div>
    </div>
  );
};

export default ModelDisplayCard;
