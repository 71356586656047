import { Modal, message } from 'antd';
import { useState } from 'react';
import { IBank } from '../../models/banks/Bank';
import { ALL_BANKS_KEY, useCreateBank, useEditBank } from '../hooks/useBanks';
import { AddEditBankForm } from './AddEditBankForm';
import { ICreateBank, IEditBank } from '../utils';
import { queryClient } from '../../App';

interface BanksModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentBank: IBank | null;
  setCurrentBank: (value: IBank | null) => void;
}

export interface IBankLogoUpload {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export const BanksModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentBank,
  setCurrentBank,
}: BanksModalProps) => {
  const [uploadedLogo, setUploadedLogo] = useState<IBankLogoUpload | null>(
    null,
  );

  const { mutateAsync: createBank, isLoading: isCreating } = useCreateBank();
  const { mutateAsync: updateBank, isLoading: isUpdating } = useEditBank();

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentBank(null);
  };

  const _create = async (data: any) => {
    if (data == null) return;
    data['add_logo'] = { id: uploadedLogo!.id };
    data['bank_benefits'] = data.benefits.map(
      (benefit: any) => benefit.bank_benefit,
    );
    data['arabic_bank_benefits'] = data.benefits.map(
      (benefit: any) => benefit.arabic_bank_benefit,
    );
    delete data.logo;
    delete data.benefits;
    try {
      const response = await createBank(data);
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ALL_BANKS_KEY,
      });
      message.success('Bank created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    try {
      data['add_logo'] =
        uploadedLogo !== null ? { id: uploadedLogo!.id } : undefined;
      data['remove_logo'] =
        uploadedLogo !== null ? { url: currentBank!.logo } : undefined;
      data['bank_benefits'] = data.benefits.map(
        (benefit: any) => benefit.bank_benefit,
      );
      data['arabic_bank_benefits'] = data.benefits.map(
        (benefit: any) => benefit.arabic_bank_benefit,
      );
      delete data.logo;
      delete data.benefits;
      const response = await updateBank({
        id: currentBank!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentBank(null);
      queryClient.invalidateQueries({
        queryKey: ALL_BANKS_KEY,
      });
      message.success('Bank updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Bank' : 'Add Bank'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={closeForm}
        footer={null}
        width={1200}
        key={currentBank?.id}
      >
        <AddEditBankForm
          bank={currentBank}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={closeForm}
          setUploadedLogo={setUploadedLogo}
        />
      </Modal>
    </>
  );
};
