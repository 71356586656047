import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICarOptionalFeaturesPayload,
  activateCarOptionalFeature,
  createCarOptionalFeature,
  deactivateCarOptionalFeature,
  deleteCarOptionalFeature,
  getCarOptionalFeatures,
  updateCarOptionalFeature,
} from '../carOptionalFeaturesApi';
import { message } from 'antd';
import { queryClient } from '../../../../App';

export const ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY = ['allCarFeatures'];

interface IUseCarOptionalFeatures {
  enabled: boolean;
  active?: boolean;
}

interface ICarOptionalFeatureFormPayload {
  name: string;
  arabicName: string;
  status: number;
  btnState: string;
  websiteBtn: string;
  engineTypes: Array<string>;
}

export const useCarOptionalFeatures = ({
  enabled,
  active,
}: IUseCarOptionalFeatures) => {
  return useQuery({
    queryKey: ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY,
    queryFn: () => getCarOptionalFeatures({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCarOptionalFeature = () => {
  return useMutation({
    mutationFn: (carOptionalFeatureData: ICarOptionalFeaturesPayload) =>
      createCarOptionalFeature(carOptionalFeatureData),
    onSuccess: () => {
      message.success('Car Optional Feature Created Successfully');
    },
  });
};

export const useUpdateCarOptionalFeature = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: ICarOptionalFeaturesPayload;
    }) => updateCarOptionalFeature(id, data),
    onError: (err: any) => {
      console.log(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};

export const useDeleteCarOptionalFeature = () => {
  return useMutation({
    mutationFn: (id: number) => deleteCarOptionalFeature(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};

export const useActivateCarOptionalFeature = () => {
  return useMutation({
    mutationFn: (id: number) => activateCarOptionalFeature(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};

export const useDeactivateCarOptionalFeature = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateCarOptionalFeature(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_CAR_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};
