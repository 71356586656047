import { ILoginFields } from '../components/LoginForm';
import { useLogin } from './useLogin';
import { useState } from 'react';

export const useLoginForm = () => {
  const [error, setError] = useState('');
  const { mutateAsync, isLoading, isSuccess } = useLogin();

  const handleSubmit = async (values: ILoginFields) => {
    const { email, password, remember } = values;

    try {
      if (isSuccess) return;
      const { accessToken, refreshToken, userData } = await mutateAsync({
        email,
        password,
      });

      if (remember) {
        localStorage.setItem('refreshToken', refreshToken);
      } else {
        sessionStorage.setItem('refreshToken', refreshToken);
      }

      sessionStorage.setItem('accessToken', accessToken);

      return { accessToken, refreshToken, userData };
    } catch (error: any) {
      if (error.response.status < 500) {
        setError('Invalid credentials');
        setTimeout(() => {
          setError('');
        }, 4500);
      }
    }
  };
  return { handleSubmit, isLoading, isSuccess, error };
};
