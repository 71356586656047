import { useMutation, useQuery } from '@tanstack/react-query';
import { createLeadEmail, deleteLeadEmail, getLeadEmails } from '../apis';
import { queryClient } from '../../../App';

interface IUseGetLeadEmails {
  enabled: boolean;
}

const ALL_LEAD_EMAILS_KEY = ['All_LEAD_EMAILS'];

export const useGetLeadEmails = ({ enabled }: IUseGetLeadEmails) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_LEAD_EMAILS_KEY,
    queryFn: () => getLeadEmails(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateLeadEmail = () => {
  return useMutation({
    mutationFn: createLeadEmail,
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_LEAD_EMAILS_KEY);
    },
  });
};

export const useDeleteLeadEmail = () => {
  return useMutation({
    mutationFn: deleteLeadEmail,
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_LEAD_EMAILS_KEY);
    },
  });
};
