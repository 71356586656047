import CarTransmissionsLayout from '../../../resources/cars/car_transmission/CarTransmissionsLayout';
import { RouteType } from '../../routes/routesConfig';

const Transmission = () => {
  return <CarTransmissionsLayout />;
};

const transmissionRoute: RouteType = {
  element: <Transmission />,
  state: 'transmission',
  index: true,
  path: '/resources/cars/transmission',
  sideBarProps: {
    displayText: 'Transmission',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Transmission, transmissionRoute };
