import { RouteType } from '../../../../routes/routesConfig';
import { NewBike } from '../../../../../listings/bikes/new_bikes';
import { UserGroups } from '../../../../../common/constants';

const NewBikes = () => {
  return <NewBike />;
};

const newBikesRoute: RouteType = {
  element: <NewBikes />,
  state: 'new_bikes',
  index: true,
  path: '/listings/bikes/new-bikes',
  sideBarProps: {
    displayText: 'New Bikes',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { NewBikes, newBikesRoute };
