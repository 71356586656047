import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getRentalLeadsDetails,
  getRentalLeads,
  IEditRentalLead,
  ICreateRentalLead,
  createRentalLead,
  editRentalLead,
  deleteRentalLead,
} from '../utils/rentalLeadsApi';

interface IUseLeadsDetails {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

export const useRentalLeads = () => {
  return useQuery({
    queryKey: ['rentalLeads'],
    queryFn: () => getRentalLeads(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useRentalLeadsDetail = ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: IUseLeadsDetails) => {
  return useQuery({
    queryKey: ['rentalLeadsDetails', dealerSlug, brandSlug, modelSlug],
    queryFn: () =>
      getRentalLeadsDetails({
        dealerSlug,
        brandSlug,
        modelSlug,
      }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateRentalLead = () => {
  return useMutation({
    mutationFn: (rentalDealer: ICreateRentalLead) =>
      createRentalLead(rentalDealer),
  });
};

export const useEditRentalLead = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditRentalLead }) =>
      editRentalLead({ id, data }),
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteRentalLead = () => {
  return useMutation({
    mutationFn: (id: number) => deleteRentalLead(id),
  });
};
