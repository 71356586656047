import { RcFile } from 'antd/es/upload';

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const urlToFile = async (
  url: string,
  filename: string,
  mimeType: string,
) => {
  try {
    if (!url) return new File([], '');
    const imageName = url.split('/').pop();
    const apiRoute =
      process.env.REACT_APP_BASE_URL + '/cars/used/image/' + imageName;
    const imageBlob = await fetch(apiRoute).then(r => r.blob());
    const metadata = {
      type: mimeType,
    };
    return new File([imageBlob], filename, metadata);
  } catch (error) {
    console.log(error);
  }
};
