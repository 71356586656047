import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getLocations,
  deleteLocation,
  createLocation,
  ILocationPayload,
  updateLocation,
  activateLocation,
  deactivateLocation,
} from './locationsApi';
import { queryClient } from '../../../App';

export const useGetLocations = ({ active }: { active?: boolean }) => {
  return useQuery({
    queryFn: () => getLocations({ active }),
    queryKey: ['locations'],
    cacheTime: 1000 * 60 * 60 * 1,
    staleTime: 1000 * 60 * 60 * 1,
    enabled: true,
  });
};

export const useCreateLocation = () => {
  return useMutation({
    mutationFn: (data: ILocationPayload) => createLocation(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
    },
  });
};

export const useUpdateLocation = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ILocationPayload }) =>
      updateLocation(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
    },
  });
};

export const useDeleteLocation = () => {
  return useMutation({
    mutationFn: (id: number) => deleteLocation(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
    },
  });
};

export const useActivateLocation = () => {
  return useMutation({
    mutationFn: (id: number) => activateLocation(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
    },
  });
};

export const useDeactivateLocation = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateLocation(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
    },
  });
};
