import { Card, Select, Space } from 'antd';
import { useState } from 'react';
import {
  useAttachLeadEmailToBrand,
  useDetachLeadEmailFromBrand,
  useGetLeadEmailsWithBrands,
} from '../hooks';
import { IGetLeadEmailsWithBrands } from '../apis';

export const BrandLeadEmailLayout = () => {
  const [search, setSearch] = useState<boolean>(false);

  const { data: brands, isLoading: isFetching } = useGetLeadEmailsWithBrands();

  const { mutateAsync: attachLeadEmailToBrand, isLoading: isAttaching } =
    useAttachLeadEmailToBrand();

  const { mutateAsync: detachLeadEmailFromBrand, isLoading: isDetaching } =
    useDetachLeadEmailFromBrand();

  const handleSelect = async (value: string, brand_id: number) => {
    await attachLeadEmailToBrand({ email: value, brand_id });
  };

  const handleDeselect = async (value: string, brand_id: number) => {
    await detachLeadEmailFromBrand({ email: value, brand_id });
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {brands?.map((brand: IGetLeadEmailsWithBrands) => {
          return (
            <Card title={`${brand.name} Email Notifications`}>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                onDeselect={value => handleDeselect(value, brand.id)}
                onSelect={value => handleSelect(value, brand.id)}
                tokenSeparators={[',']}
                onSearch={handleSearch}
                loading={isFetching || isAttaching || isDetaching}
                disabled={isFetching || isAttaching || isDetaching}
                options={brand?.unassociated_emails?.map(email => {
                  return { value: email?.email };
                })}
                popupClassName={`select-popup-hide-selected-options ${
                  !search &&
                  brand?.unassociated_emails?.length === 0 &&
                  `select-popup-hide`
                }`}
                value={brand?.brand_lead_emails?.map(lead => lead.email)}
              />
            </Card>
          );
        })}
      </Space>
    </>
  );
};
