import { Modal, message } from 'antd';
import { useCreateBatteryRange, useUpdateBatteryRange } from '../hooks';
import { ICarBatteryRange } from '../../../../models/resources/CarBatteryRange';
import { BatteryRangeAddEditForm } from './BatteryRangeAddEditForm';

interface BatteryRangeModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentBatteryRange: ICarBatteryRange | null;
  setCurrentBatteryRange: (value: ICarBatteryRange | null) => void;
  engineTypes: any;
}

export const BatteryRangeModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBatteryRange,
  setCurrentBatteryRange,
  engineTypes,
}: BatteryRangeModalProps) => {
  const { mutateAsync: createBatteryRange, isLoading: isCreating } =
    useCreateBatteryRange();
  const { mutateAsync: updateBatterRange, isLoading: isUpdating } =
    useUpdateBatteryRange();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createBatteryRange(data);
      setIsFormOpen(false);
      message.success('Battery Range Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateBatterRange({
        id: currentBatteryRange!.id,
        data,
      });
      message.success('Battery Range Updated Successfully');
      setIsFormOpen(false);
      setCurrentBatteryRange(null);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Battery Range' : 'Add Battery Range'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBatteryRange?.id}
      >
        <BatteryRangeAddEditForm
          resource={currentBatteryRange}
          engineTypes={engineTypes}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
        />
      </Modal>
    </>
  );
};
