import { RouteType } from '../../routes/routesConfig';
import UsersLayout from '../../../users/components/UsersLayout';
import { useEffect } from 'react';

const Users = () => {
  return <UsersLayout />;
};

const usersRoute: RouteType = {
  element: <Users />,
  state: 'users',
  index: true,
  path: '/users',
  sideBarProps: {
    displayText: 'Users',
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Users, usersRoute };
