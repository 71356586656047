import { Modal, message } from 'antd';
import { IBikeTransmissions } from '../../../../models/resources/bikes/BikeTransmission';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { useCreateBikeTransmission, useUpdateBikeTransmission } from '../hooks';
import { IBikeTransmissionPayload } from '../apis';
import { AddEditBikeTransmissionsFrom } from './AddEditBikeTransmissionsFrom';

interface IBikeTransmissionsModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentBikeTransmission: IBikeTransmissions | null;
  setCurrentBikeTransmission: (value: IBikeTransmissions | null) => void;
  bikeEngineTypes: IBikeEngineType[];
}

export const BikeTransmissionsModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBikeTransmission,
  setCurrentBikeTransmission,
  bikeEngineTypes,
}: IBikeTransmissionsModalProps) => {
  const { mutateAsync: createBikeTransmission, isLoading: isCreating } =
    useCreateBikeTransmission();
  const { mutateAsync: updateBikeTransmission, isLoading: isUpdating } =
    useUpdateBikeTransmission();

  const _create = async (data: IBikeTransmissionPayload) => {
    if (data == null) return;
    const response = await createBikeTransmission(data);
    message.success('Transmission created successfully');
    setIsFormOpen(false);
    setCurrentBikeTransmission(null);
    return response;
  };

  const _edit = async (data: IBikeTransmissionPayload) => {
    if (data == null) return;
    const response = await updateBikeTransmission({
      id: currentBikeTransmission!.id,
      data,
    });
    message.success('Transmission updated successfully');
    setIsFormOpen(false);
    setCurrentBikeTransmission(null);
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Transmission' : 'Add Transmission'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeTransmission?.id}
        destroyOnClose
      >
        <AddEditBikeTransmissionsFrom
          bikeEngineTypes={bikeEngineTypes}
          bikeTransmissions={currentBikeTransmission}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
        />
      </Modal>
    </>
  );
};
