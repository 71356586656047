import { Modal } from 'antd';
import { CarInteriorColorForm } from './CarInteriorColorForm';
import { ICarInteriorColor } from '../utils/apiUtils';

interface CarInteriorColorModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarInteriorColor?: ICarInteriorColor;
  setSelectedCarInteriorColor: React.Dispatch<React.SetStateAction<any>>;
}

export const CarInteriorColorModal = ({
  isOpen,
  setIsOpen,
  selectedCarInteriorColor,
  setSelectedCarInteriorColor,
}: CarInteriorColorModalProps) => {
  const isEdit = !!selectedCarInteriorColor;

  const handleClose = () => {
    try {
      setIsOpen(false);
      setSelectedCarInteriorColor(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Interior Color"
      open={isOpen}
      onCancel={handleClose}
      width={1200}
      footer={null}
      key={selectedCarInteriorColor?.id}
      destroyOnClose={true}
    >
      <CarInteriorColorForm
        handleClose={handleClose}
        selectedCarInteriorColor={selectedCarInteriorColor}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setSelectedCarInteriorColor={setSelectedCarInteriorColor}
      />
    </Modal>
  );
};
