import BikeTypesEditAddDeleteForm from './BikeTypesEditAddDeleteForm';
import { useCreateBikeType, useUpdateBikeType } from '../hooks';
import { Modal, message } from 'antd';
import { IBikeType } from '../../../../models/resources/bikes/BikeType';

interface IBikeTypesModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentType: IBikeType | null;
  setCurrentType: (value: IBikeType | null) => void;
  isLoadingTypes: boolean;
}

const BikeTypesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentType,
  setCurrentType,
  isLoadingTypes,
}: IBikeTypesModalProps) => {
  const { mutateAsync: createBikeType, isLoading: isCreating } =
    useCreateBikeType();
  const { mutateAsync: updateBikeType, isLoading: isUpdating } =
    useUpdateBikeType();

  const _create = async (data: any) => {
    try {
      const response = await createBikeType(data);
      setIsFormOpen(false);
      message.success('Bike type created successfully');
      return response;
    } catch (error: any) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateBikeType({
        id: currentType!.id,
        data,
      });
      message.success('Bike type updated successfully');
      setCurrentType(null);
      setIsFormOpen(false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Bike Type' : 'Add Bike Type'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentType?.id}
      >
        <BikeTypesEditAddDeleteForm
          resourceType={currentType}
          isLoading={isLoadingTypes}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          onClose={() => setIsFormOpen(false)}
          isSubmitting={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};

export default BikeTypesModal;
