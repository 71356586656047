import { useState } from 'react';
import { Modal, message } from 'antd';
import { useCreateArticle, useUpdateArticle } from '../hooks';
import { IArticleImages } from '../../models/articles/Article';
import { AddEditArticleForm } from './AddEditArticleForm';

interface ArticleModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentArticle: any;
  setCurrentArticle: (value: any) => void;
}

export const ArticleModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentArticle,
  setCurrentArticle,
}: ArticleModalProps) => {
  const [addedImages, setAddedImages] = useState<IArticleImages[]>([]);
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);

  const { mutateAsync: createArticle, isLoading: isCreatingArticle } =
    useCreateArticle();

  const { mutateAsync: updateArticle, isLoading: isUpdatingArticle } =
    useUpdateArticle();

  const _create = async (article: any) => {
    if (article == null) return;
    try {
      delete article.image;
      delete article.thumbnail;
      article['add_images'] = addedImages.map(image => ({ id: image.id }));
      const response = await createArticle(article);
      setIsFormOpen(false);
      setCurrentArticle(null);
      message.success('Article Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (currentArticle == null) return;
    try {
      delete data.image;
      delete data.thumbnail;
      data['add_images'] = addedImages.map(image => ({ id: image.id }));
      data['remove_images'] = removedImages;
      const response = await updateArticle({
        id: currentArticle.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentArticle(null);
      message.success('Article Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        className="crud-dialog"
        title={isEditing ? 'Edit Article' : 'Create Article'}
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        width={1200}
        footer={null}
        key={currentArticle?.id}
        style={{ top: 20 }}
      >
        <AddEditArticleForm
          isEditing={isEditing}
          onClose={() => setIsFormOpen(false)}
          article={currentArticle}
          onSubmit={isEditing ? _edit : _create}
          isLoading={isCreatingArticle || isUpdatingArticle}
          addedImages={addedImages}
          removedImages={removedImages}
          setAddedImages={setAddedImages}
          setRemovedImages={setRemovedImages}
        />
      </Modal>
    </>
  );
};
