import { UserGroups } from '../../../common/constants';
import BankingTable from '../../../overview/components/BankingTable';
import BankingLeadsLayout from './BankingLeadsLayout';

const BankingLeads = () => {
  return <BankingLeadsLayout />;
};

const bankingLeadsRoute = {
  path: '/leads/financing',
  index: false,
  element: <BankingLeads />,
  state: 'leads',
  sideBarProps: {
    displayText: 'Financing',
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Banking],
};

export { BankingLeads, bankingLeadsRoute };
