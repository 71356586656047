import api from '../../../api/apiIndex';

const citiesEndpointV2 = 'v2/admin/resources/cities';

export interface IGetCities {
  active?: boolean;
}

export interface ICityPayload {
  name: string;
  arabic_name: string;
  country_id: number;
}

export const getCities = async ({ active }: IGetCities) => {
  try {
    const response = await api.get(citiesEndpointV2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createCity = async (data: ICityPayload) => {
  try {
    const response = await api.post(citiesEndpointV2, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateCity = async (id: number, data: ICityPayload) => {
  try {
    const response = await api.put(`${citiesEndpointV2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCity = async (id: number) => {
  try {
    const response = await api.delete(`${citiesEndpointV2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCity = async (id: number) => {
  try {
    const response = await api.put(`${citiesEndpointV2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCity = async (id: number) => {
  try {
    const response = await api.put(`${citiesEndpointV2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
