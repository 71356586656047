import { RouteType } from '../../routes/routesConfig';
import { BanksLayout } from '../../../banks/components/BanksLayout';
import { UserGroups } from '../../../common/constants';

const AllBanks = () => {
  return <BanksLayout />;
};

const allBanksRoutes: RouteType = {
  element: <AllBanks />,
  state: 'all-banks',
  index: true,
  path: '/banks',
  sideBarProps: {
    displayText: 'Banking',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Banking],
};

export { AllBanks, allBanksRoutes };
