import { UserGroups } from '../../../common/constants';
import { RouteType } from '../../routes/routesConfig';
import { ramadanOffersRoute } from './RamadanOffers';
import { rentalsRoute } from './Rentals';
import { usedCarsRoute } from './UsedCars';
import { newBikesRoute } from './bikes/new_bikes';
import { usedBikesRoute } from './bikes/UsedBikes';
import { newCarsRoute } from './new_cars/NewCars';

export const allListingsRoutes: RouteType = {
  index: false,
  state: 'listings',
  children: [
    {
      index: false,
      state: 'listings.cars',
      children: [newCarsRoute, usedCarsRoute, rentalsRoute, ramadanOffersRoute],
      sideBarProps: {
        displayText: 'Cars',
      },
      allowedGroups: ['ONE AUTOCAR', 'Automotive', 'Rental Dealer'],
    },
    {
      index: false,
      state: 'listings.bikes',
      children: [newBikesRoute, usedBikesRoute],
      sideBarProps: {
        displayText: 'Bikes',
      },
      allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
    },
  ],
  sideBarProps: {
    displayText: 'Listings',
  },
  allowedGroups: ['ONE AUTOCAR', 'Automotive', 'Rental Dealer'],
};
