import api from '../../../../api/apiIndex';
import { CarCategory } from '../../../../listings/cars/used_cars/carsApi';

const modelsEndpoint = '/cars/resources/models';

const modelsEndpointV2 = '/v2/admin/resources/cars/models';

export interface ICarModelPayload {
  name: string;
  arabic_name: string;
  brand_id: number;
  description: string;
  arabic_description: string;
  types: number[];
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
}

export interface IGetModels {
  dealerId?: number;
  brandId?: number;
  active?: boolean;
  type?: string;
}

const getModels = async (
  category: string,
  page: number,
  modelsPerPage: number,
) => {
  try {
    const response = await api.get(
      `${modelsEndpoint}?condition=${category}&page=${page}&perpage=${modelsPerPage}`,
    );
    return {
      models: response.data.data.data,
      totalModels: response.data.data.total,
    };
  } catch (error) {
    console.error(error);
  }
};

const getAllModels = async ({
  dealerId,
  brandId,
  active,
  type,
}: IGetModels) => {
  try {
    const response = await api.get(`${modelsEndpointV2}`, {
      params: {
        dealer: dealerId,
        brand: brandId,
        active,
        type,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createModel = async (data: ICarModelPayload) => {
  try {
    const response = await api.post(modelsEndpointV2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateModel = async (id: number, data: ICarModelPayload) => {
  try {
    const response = await api.put(`${modelsEndpointV2}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteModel = async (id: number) => {
  try {
    const response = await api.delete(`${modelsEndpointV2}/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateModel = async (id: number) => {
  try {
    const response = await api.put(`${modelsEndpointV2}/activate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateModel = async (id: number) => {
  try {
    const response = await api.put(`${modelsEndpointV2}/deactivate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getModelsByBrand = async (
  category: CarCategory,
  brandId: number | undefined,
) => {
  const response = await api.get(
    `${modelsEndpoint}/brands?condition=${category}&brand_id=${brandId}`,
  );
  return response.data;
};

const getModelsByBrandV2 = async (id: number) => {
  try {
    const response = await api.get(`${modelsEndpointV2}/brand/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getModelNames = async () => {
  const response = await api.get(`${modelsEndpoint}/names`);

  return response.data.data;
};

const getModelBySlug = async (slug: string) => {
  try {
    const response = await api.get(`${modelsEndpointV2}/${slug}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getModels,
  getAllModels,
  getModelsByBrand,
  getModelBySlug,
  getModelNames,
  createModel,
  updateModel,
  deleteModel,
  getModelsByBrandV2,
};
