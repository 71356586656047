import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  activateBrand,
  createBrand,
  deactivateBrand,
  deleteBrand,
  editBrand,
  getAllBrandsNames,
  getBrandFilters,
  getBrands,
  getBrandsWithModelsCount,
  ICarBrandPayload,
} from '../apis/brandsApi';
import { queryClient } from '../../../../App';

const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;

export interface IBrandNames {
  id: number;
  name: string;
  smallthumbnail: string;
  arabic_name: string;
}

interface IUseBrandNames {
  enabled: boolean;
}

export const ALL_BRAND_NAMES_KEY = ['ALL_BRAND_NAMES_KEY'];

const queryOptions: UseQueryOptions<IBrandNames[], Error> = {
  queryKey: ALL_BRAND_NAMES_KEY,
  queryFn: getBrandFilters,
  onSuccess: () => getBrandFilters,
  cacheTime: ONE_DAY,
  staleTime: ONE_DAY,
  refetchOnWindowFocus: false,
};

export const useBrandNames = ({ enabled }: IUseBrandNames) => {
  return useQuery<IBrandNames[], Error>(ALL_BRAND_NAMES_KEY, getBrandFilters, {
    ...queryOptions,
    enabled,
  });
};

export interface IAllBrandNames {
  id: number;
  name: string;
  slug: string;
  arabic_name: string;
  smallthumbnail: string;
}

export interface IUseBrandsOptions {
  enabled: boolean;
}

interface IUseBrands {
  enabled?: boolean;
  dealerId?: number;
  active?: boolean;
  type?: string;
}

export const ALL_BRAND_NAMES = ['ALL_BRAND_NAMES'];

const queryOptionsAll: UseQueryOptions<IAllBrandNames[]> = {
  queryKey: ALL_BRAND_NAMES,
  queryFn: getAllBrandsNames,
  cacheTime: ONE_DAY,
  staleTime: ONE_DAY,
  refetchOnWindowFocus: false,
};

export const useAllBrandNames = ({ enabled }: IUseBrandsOptions) => {
  return useQuery<IAllBrandNames[]>(ALL_BRAND_NAMES, {
    ...queryOptionsAll,
    enabled,
  });
};

export const useGetAllBrands = ({
  enabled = true,
  dealerId,
  active,
  type,
}: IUseBrands) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: [...ALL_BRAND_NAMES, dealerId, active],
    queryFn: () => getBrands({ dealerId, active, type }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useGetAllBrandsWithModelsCount = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['BRANDS_WITH_MODELS_COUNT'],
    queryFn: () => getBrandsWithModelsCount(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useActivateBrand = () => {
  return useMutation({
    mutationFn: (id: number) => activateBrand(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BRAND_NAMES);
    },
  });
};

export const useDeactivateBrand = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBrand(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BRAND_NAMES);
    },
  });
};

export const useDeleteBrand = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBrand(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BRAND_NAMES);
    },
  });
};

export const useCreateBrand = () => {
  return useMutation({
    mutationFn: (data: ICarBrandPayload) => createBrand(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BRAND_NAMES);
    },
  });
};

export const useEditBrand = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICarBrandPayload }) =>
      editBrand(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BRAND_NAMES);
    },
  });
};
