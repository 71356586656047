import api from '../../api/apiIndex';

const reviewsEndpoint = 'v2/admin/reviews';

export interface IReviewPayload {
  title: string;
  description: string;
  model_id: number;
  city_id: number;
  is_active: boolean;
  is_featured?: boolean;
  show_in_model?: boolean;
  add_video?: { id: number };
  remove_video?: { url: string };
}

export const getReviews = async () => {
  const response = await api.get(reviewsEndpoint);
  return response.data;
};

export const createReview = async (request: IReviewPayload) => {
  const response = await api.post(reviewsEndpoint, request);
  return response.data;
};

export const updateReview = async (id: number, data: IReviewPayload) => {
  const response = await api.put(`${reviewsEndpoint}/${id}`, data);
  return response.data;
};

export const deleteReview = async (id: number) => {
  const response = await api.delete(`${reviewsEndpoint}/${id}`);
  return response.data;
};

export const activateReview = async (id: number) => {
  const response = await api.put(`${reviewsEndpoint}/activate/${id}`);
  return response.data;
};

export const deactivateReview = async (id: number) => {
  const response = await api.put(`${reviewsEndpoint}/deactivate/${id}`);
  return response.data;
};
