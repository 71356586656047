import { CountriesLayout } from '../../../resources/countries';
import { RouteType } from '../../routes/routesConfig';

export const Coutry = () => {
  return <CountriesLayout />;
};

export const countryRoute: RouteType = {
  element: <Coutry />,
  state: 'country',
  index: true,
  path: '/resources/countries',
  sideBarProps: {
    displayText: 'Countries',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};
