import api from '../../../api/apiIndex';
import { rentalDealersEndpoint } from './getRentalDealers';

export interface ICreateRentalDealer {
  name: string;
  arabic_name: string;
  city_id: number;
  add_logo: { id: number };
}

export const createRentalDealers = async (
  rentalDealer: ICreateRentalDealer,
) => {
  try {
    const response = await api.post(rentalDealersEndpoint, rentalDealer);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
