import api from '../../../api/apiIndex';

const carInteriorColorsEndpointv2 = 'v2/admin/resources/cars/interior-colors';

export interface IGetInteriorColors {
  active?: boolean;
}

export interface ICarInteriorColorPayload {
  name: string;
  arabic_name: string;
}

const getCarInteriorColors = async ({ active }: IGetInteriorColors) => {
  try {
    const response = await api.get(carInteriorColorsEndpointv2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createCarInteriorColor = async (data: ICarInteriorColorPayload) => {
  try {
    const response = await api.post(carInteriorColorsEndpointv2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateCarInteriorColor = async (
  id: number,
  data: ICarInteriorColorPayload,
) => {
  try {
    const response = await api.put(
      `${carInteriorColorsEndpointv2}/${id}`,
      data,
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteCarInteriorColor = async (id: number) => {
  try {
    const response = await api.delete(`${carInteriorColorsEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCarInteriorColor = async (id: number) => {
  try {
    const response = await api.put(
      `${carInteriorColorsEndpointv2}/activate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCarInteriorColor = async (id: number) => {
  try {
    const response = await api.put(
      `${carInteriorColorsEndpointv2}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getCarInteriorColors,
  createCarInteriorColor,
  updateCarInteriorColor,
  deleteCarInteriorColor,
};
