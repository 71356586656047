import api from '../../../../api/apiIndex';

const bikeTransmissionEndpoint = 'v2/admin/resources/bikes/transmissions';

export interface IGetBikeTransmissions {
  active?: boolean;
}

export interface IBikeTransmissionPayload {
  name: string;
  arabic_name: string;
  bike_engine_types: Array<string>;
}

export const getBikeTransmissions = async ({
  active,
}: IGetBikeTransmissions) => {
  try {
    const response = await api.get(bikeTransmissionEndpoint, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeTransmission = async (
  data: IBikeTransmissionPayload,
) => {
  const response = await api.post(bikeTransmissionEndpoint, data);
  return response.data;
};

export const updateBikeTransmission = async (
  id: number,
  data: IBikeTransmissionPayload,
) => {
  const response = await api.put(`${bikeTransmissionEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeTransmission = async (id: number) => {
  try {
    const response = await api.delete(`${bikeTransmissionEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeTransmission = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeTransmissionEndpoint}/activate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeTransmission = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeTransmissionEndpoint}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
