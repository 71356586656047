import { useCallback, useEffect, useState } from 'react';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { Form, Row, Col, Space, Button, Input } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import FileUpload from '../../components/inputs/FileUpload';
import { Editor } from 'primereact/editor';
import { CustomizedRequiredMark } from '../../components/misc/RequiredMark';
import { IReviewer, IReviewerImage } from '../../models/reviewers';
import { useUploadReviewerImage } from '../hooks';

type AddEditReviewerFormProps = {
  onClose: () => void;
  isEditing: boolean;
  reviewer: IReviewer | null;
  onSubmit: (reviewer: IReviewer) => void;
  isLoading: boolean;
  setUploadedImage: (image: IReviewerImage) => void;
};

export const AddEditReviewerForm = ({
  onClose,
  isEditing,
  reviewer,
  onSubmit,
  isLoading,
  setUploadedImage,
}: AddEditReviewerFormProps) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  const initialImages = reviewer?.reviewer_images
    ? [
        {
          uid: `${reviewer?.id}`,
          name: reviewer?.reviewer_images?.split('/').pop(),
          status: 'old',
          url: reviewer?.reviewer_images,
        },
      ]
    : [];

  const initialValues = reviewer
    ? {
        name: reviewer?.name,
        autobiography: reviewer?.autobiography,
        reviewer_images: initialImages,
      }
    : {};

  const [fileList, setFileList] = useState<UploadFile[]>(
    initialImages as UploadFile[],
  );

  const { mutateAsync: uploadReviewerImage, isLoading: isUploadingImage } =
    useUploadReviewerImage();

  const imageUpload = async ({
    onSuccess,
    onProgress,
    file,
    onError,
  }: UploadRequestOption) => {
    uploadReviewerImage(
      {
        file,
        path: 'reviewer-image',
        onProgress: onProgress,
      },
      {
        onSuccess: data => {
          if (onSuccess) onSuccess('ok');
          setUploadedImage(data);
        },
      },
    );
  };

  const resetForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    resetForm();
  }, [reviewer, form, resetForm]);

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
        setFileList([]);
      }}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={CustomizedRequiredMark}
    >
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter a name' }]}
          >
            <Input placeholder="Please enter a name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            label="Auto Biography"
            name="autobiography"
            rules={[
              {
                required: true,
                message: 'Please enter an auto biography',
              },
            ]}
          >
            <Editor
              value={form.getFieldValue('autobiography')}
              placeholder="Auto Biography"
              onTextChange={(e: any) =>
                form.setFieldsValue({ autobiography: e.htmlValue })
              }
            />
          </Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        wrap
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '1rem',
          width: '100%',
          marginLeft: '10%',
        }}
      >
        <Col span={8}>
          <FileUpload
            fileList={fileList}
            label="Logo"
            maxNbFiles={1}
            name="review_images"
            setFileList={setFileList}
            customRequest={props => imageUpload({ ...props })}
          />
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isUploadingImage}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
