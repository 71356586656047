import { Button, Input, Popconfirm, Table, Tag, message } from 'antd';
import {
  useActivateBikeOptionalFeature,
  useBikeOptionalFeatures,
  useDeactivateBikeOptionalFeature,
  useDeleteBikeOptionalFeature,
} from './hooks';
import { useEffect, useState } from 'react';
import { IBikeOptionalFeature } from '../../../models/resources/bikes/BikeOptionalFeatures';
import { useSearch } from '../../../hooks/useSearch';
import { Layout } from '../../../components/Layout';
import { ColumnsType } from 'antd/es/table';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import { BikeOptionalFeatureModal } from './components';
import { useGetBikeEngineType } from '../engine_types';

const { Search } = Input;

export const BikeOptionalFeaturesLayout = () => {
  const {
    data: BikeOptionalFeatures,
    isLoading: isLoadingBikeOptionalFeatures,
  } = useBikeOptionalFeatures({ enabled: true });
  const { data: bikeEngineTypes } = useGetBikeEngineType({ enabled: true });

  const { mutateAsync: deleteOptionalFeature, isLoading: isDeleting } =
    useDeleteBikeOptionalFeature();
  const { mutateAsync: activateOptionalFeature, isLoading: isActivating } =
    useActivateBikeOptionalFeature();
  const { mutateAsync: deactivateOptionalFeature, isLoading: isDeactivating } =
    useDeactivateBikeOptionalFeature();

  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBikeOptionalFeature, setSelectedBikeOptionalFeature] =
    useState<IBikeOptionalFeature | null>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBikeFeature } = useSearch();
  const [filteredData, setFilteredData] = useState(BikeOptionalFeatures);

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBikeOptionalFeature | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<IBikeOptionalFeature | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateOptionalFeature(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Optional Feature deactivated successfully');
          },
        })
      : activateOptionalFeature(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Optional Feature activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteOptionalFeature(id, {
      onSuccess: () => {
        setSelectedBikeOptionalFeature(null);
        message.success('Optional feature deleted successfully');
        setOpen(false);
      },
    });
  };

  const onEditButtonPress = (bikeOptionalFeature: IBikeOptionalFeature) => {
    setSelectedBikeOptionalFeature(bikeOptionalFeature);
    setIsEditing(true);
    setOpen(true);
  };

  const openEmptyForm = () => {
    setSelectedBikeOptionalFeature(null);
    setOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchBikeFeature(BikeOptionalFeatures, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchBikeFeature(BikeOptionalFeatures, searchValue);
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(BikeOptionalFeatures);
  }, [BikeOptionalFeatures]);

  const tableColumns: ColumnsType<IBikeOptionalFeature> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${BikeOptionalFeatures?.map(
        (carOptionalFeature: IBikeOptionalFeature) =>
          carOptionalFeature.t[0]?.name,
      )}`,
      key: `${BikeOptionalFeatures?.map(
        (carOptionalFeature: IBikeOptionalFeature) =>
          carOptionalFeature.t[0]?.name,
      )}`,
      render: (text: any, record: IBikeOptionalFeature) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Used Bikes Button',
      dataIndex: `${BikeOptionalFeatures?.map(
        (carOptionalFeature: IBikeOptionalFeature) =>
          carOptionalFeature.button_state,
      )}`,
      key: `${BikeOptionalFeatures?.map(
        (carOptionalFeature: IBikeOptionalFeature) =>
          carOptionalFeature.button_state,
      )}`,
      render: (text: any, record: IBikeOptionalFeature) =>
        record.button_state === 'ThreeState' ? (
          <Tag color="cyan">Three State</Tag>
        ) : (
          <Tag color="purple">Two State</Tag>
        ),
    },
    {
      title: 'Sell Your Bike Button',
      dataIndex: `${BikeOptionalFeatures?.map(
        (carOptionalFeature: IBikeOptionalFeature) =>
          carOptionalFeature.website_button_state,
      )}`,
      key: `${BikeOptionalFeatures?.map(
        (carOptionalFeature: IBikeOptionalFeature) =>
          carOptionalFeature.website_button_state,
      )}`,
      render: (text: any, record: IBikeOptionalFeature) =>
        record.website_button_state === 'ThreeState' ? (
          <Tag color="cyan">Three State</Tag>
        ) : (
          <Tag color="purple">Two State</Tag>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IBikeOptionalFeature) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              onClick={() => {
                onEditButtonPress(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Are you sure you want to delete this optional feature?"
              open={openDeleteDialog === rowData}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpenDeleteDialog(rowData);
                }}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this optional feature.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Optional Features"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />

        <Button type="primary" onClick={openEmptyForm}>
          Add Optional Feature
        </Button>
      </div>
      <Table
        dataSource={filteredData}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
        columns={tableColumns}
        pagination={false}
        loading={isLoadingBikeOptionalFeatures}
      />
      <BikeOptionalFeatureModal
        bikeEngineTypes={bikeEngineTypes}
        isEditing={isEditing}
        isOpen={open}
        setIsEditing={setIsEditing}
        setIsOpen={setOpen}
        setSelectedBikeOptionalFeature={setSelectedBikeOptionalFeature}
        key={selectedBikeOptionalFeature?.id}
        selectedBikeOptionalFeature={selectedBikeOptionalFeature}
      />
    </Layout>
  );
};
