import { Modal, message } from 'antd';
import { queryClient } from '../../../App';
import { UsedBikeLeadsDetail } from '../../../models/overview/UsedBikeLead';
import { useCreateUsedBikeLead } from '../hooks';
import { ICreateUsedBikeLead } from '../apis';
import { AddEditUsedBikeLeadForm } from './AddEditUsedBikeLeadForm';

interface UsedBikeLeadModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  currentLead: UsedBikeLeadsDetail | null;
  setCurrentLead?: (value: UsedBikeLeadsDetail | null) => void;
}

export const UsedBikeLeadModal = ({
  isFormOpen,
  setIsFormOpen,
  currentLead,
  setCurrentLead,
}: UsedBikeLeadModalProps) => {
  const { mutateAsync: createLead, isLoading: isCreating } =
    useCreateUsedBikeLead();

  const closeForm = () => {
    setIsFormOpen(false);
    setCurrentLead && setCurrentLead(null);
  };

  const _create = async (lead: ICreateUsedBikeLead) => {
    if (lead == null) return;
    const response = await createLead({
      name: lead.name,
      email: lead.email,
      mobile_number: `+974${lead.mobile_number?.toString()}`,
      used_bike_id: lead.used_bike_id,
      source: lead.source,
      contacted: lead.contacted,
      notes: lead.notes,
    });
    setIsFormOpen(false);
    queryClient.invalidateQueries({
      queryKey: ['usedBikeLeads'],
    });
    queryClient.invalidateQueries({
      queryKey: ['usedBikeLeadsDetails'],
    });
    message.success('Used bike lead created successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={'Add Used Bike Lead'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={closeForm}
        footer={null}
        width={1200}
        key={currentLead?.id}
        destroyOnClose
      >
        <AddEditUsedBikeLeadForm
          lead={currentLead}
          onSubmit={_create}
          isLoading={isCreating}
          onClose={closeForm}
        />
      </Modal>
    </>
  );
};
