import _ from 'lodash';
import api from '../../../../api/apiIndex';

const bikeBrandsEndpoint = '/v2/admin/resources/bikes/brands';

export enum EBrandLogoType {
  OriginalLogo = 'original',
  WhiteLogo = 'white',
}

export interface IGetBikeBrands {
  dealerId?: number;
  active?: boolean;
  type?: string;
}

export interface IBikeBrandPayload {
  name: string;
  arabic_name: string;
  add_logos?: Array<{ id: number }>;
  remove_logos?: Array<{ url: string }>;
}

export const getBikeBrands = async ({
  dealerId,
  active,
  type,
}: IGetBikeBrands) => {
  try {
    const response = await api.get(`${bikeBrandsEndpoint}`, {
      params: {
        dealer: dealerId,
        active,
        type,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeBrand = async (data: IBikeBrandPayload) => {
  const response = await api.post(bikeBrandsEndpoint, data);
  return response.data;
};

export const editBikeBrand = async (id: number, data: IBikeBrandPayload) => {
  const response = await api.put(`${bikeBrandsEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeBrand = async (id: number) => {
  try {
    const response = await api.delete(`${bikeBrandsEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeBrand = async (id: number) => {
  try {
    const response = await api.put(`${bikeBrandsEndpoint}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeBrand = async (id: number) => {
  try {
    const response = await api.put(`${bikeBrandsEndpoint}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
