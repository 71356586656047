import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeTransmissionPayload,
  activateBikeTransmission,
  createBikeTransmission,
  deactivateBikeTransmission,
  deleteBikeTransmission,
  getBikeTransmissions,
  updateBikeTransmission,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_BIKE_TRANSMISSIONS_KEY = ['allBikeTransmissions'];

interface IUseBikeTransmissions {
  enabled: boolean;
  active?: boolean;
}

export const useGetBikeTransmissions = ({
  enabled,
  active,
}: IUseBikeTransmissions) => {
  return useQuery({
    queryKey: ALL_BIKE_TRANSMISSIONS_KEY,
    queryFn: () => getBikeTransmissions({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeTransmission = () => {
  return useMutation({
    mutationFn: (data: IBikeTransmissionPayload) =>
      createBikeTransmission(data),
    mutationKey: ['createBikeTransmission'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_TRANSMISSIONS_KEY);
    },
  });
};

export const useUpdateBikeTransmission = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: IBikeTransmissionPayload;
    }) => updateBikeTransmission(id, data),
    mutationKey: ['updateBikeTransmission'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_TRANSMISSIONS_KEY);
    },
  });
};

export const useDeleteBikeTransmission = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeTransmission(id),
    mutationKey: ['deleteBikeTransmission'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_TRANSMISSIONS_KEY);
    },
  });
};

export const useActivateBikeTransmission = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeTransmission(id),
    mutationKey: ['activateBikeTransmission'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_TRANSMISSIONS_KEY);
    },
  });
};

export const useDeactivateBikeTransmission = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeTransmission(id),
    mutationKey: ['deactivateBikeTransmission'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_TRANSMISSIONS_KEY);
    },
  });
};
