import { useEffect, useState } from 'react';
import { Option } from '../../components/inputs/DropDown';
import { ICity } from '../../models/countries/City';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import {
  Form,
  Row,
  Col,
  Space,
  Switch,
  Typography,
  Input,
  Select,
  Button,
} from 'antd';
import { uploadImage } from '../../common/utils';
import { useCountries } from '../../countries/hooks/useCountries';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import {
  IReviews,
  IReviewsThumbnails,
  IReviewsVideos,
} from '../../models/reviews';
import { useGetAllModels } from '../../resources/cars/car_models/hooks';
import { ICarModel } from '../../models/resources';
import VideoUpload from '../../components/inputs/VideoUpload';
import FileUpload from '../../components/inputs/FileUpload';
import { Editor } from 'primereact/editor';
import { AxiosError } from 'axios';
import { uploadVideo } from '../../common/utils/videoApi';
import { CustomizedRequiredMark } from '../../components/misc/RequiredMark';
import { useGetAllBrands } from '../../resources/cars/car_brands/hooks';
import { CarBrand } from '../../models/resources/CarBrand';
import { useGetReviewers } from '../../reviewers/hooks';

export enum Language {
  English = 'en',
  Arabic = 'ar',
}

type CustomUploadFileStatus = UploadFileStatus | 'old';

type AddEditReviewFormProps = {
  onClose: () => void;
  isEditing: boolean;
  review: IReviews;
  onSubmit: (review: IReviews) => void;
  isLoading: boolean;
  addedVideos: any[];
  removedVideos: any[];
  setAddedVideos: (video: IReviewsVideos[]) => void;
  setRemovedVideos: (urls: Array<Record<string, string>>) => void;
  addedThumbnails: any[];
  removedThumbnails: any[];
  setAddedThumbnails: (thumbnails: IReviewsThumbnails[]) => void;
  setRemovedThumbnails: (urls: Array<Record<string, string>>) => void;
};

export const AddEditReviewForm = ({
  onClose,
  isEditing,
  review,
  onSubmit,
  isLoading,
  addedVideos,
  removedVideos,
  setAddedVideos,
  setRemovedVideos,
  addedThumbnails,
  removedThumbnails,
  setAddedThumbnails,
  setRemovedThumbnails,
}: AddEditReviewFormProps) => {
  const { Text } = Typography;
  const { Item } = Form;
  const [form] = Form.useForm();
  const countryId = Form.useWatch('country_id', form);
  const brandId = Form.useWatch('brand_id', form);

  const maxTitleLength = 59;

  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const { data: brands, isLoading: isLoadingBrands } = useGetAllBrands({
    active: true,
  });

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { data: models, isLoading: isLoadingModels } = useGetAllModels({
    enabled: isEditing || !!brandId,
    brandId: brandId,
    active: true,
    type: 'new',
  });

  const { data: reviewers, isLoading: isLoadingReviewers } = useGetReviewers({
    active: true,
  });

  const initialVideo = review?.video
    ? [
        {
          uid: `${review?.id}`,
          name: review?.video?.split('/').pop(),
          status: 'old',
          url: review?.video,
        },
      ]
    : [];

  const initialThumbnail = review?.thumbnail
    ? [
        {
          uid: `${review?.id}`,
          name: review?.thumbnail?.split('/').pop(),
          status: 'old',
          url: review?.thumbnail,
        },
      ]
    : [];

  const initialValues = review
    ? {
        title: review?.title,
        model_id: models?.find(
          (model: ICarModel) => model.id === review.model_id,
        )?.id,
        brand_id: brands?.find(
          (brand: CarBrand) =>
            brand.id ===
            models?.find((model: ICarModel) => model.id === review?.model_id)
              ?.brand_id,
        )?.id,
        description: review?.description,
        reviewer_id: isEditing ? review?.reviewer?.name : review?.reviewer_id,
        lang: review?.lang,
        video: initialVideo,
        thumbnail: initialThumbnail,
        is_featured: review?.is_featured,
        show_in_model: review?.show_in_model,
        country_id: countries?.find(country =>
          country.cities?.find(city => city?.id === review?.city_id),
        )?.id,
        city_id: review?.city_id,
      }
    : {};

  const [videoFileList, setVideoFileList] = useState<UploadFile[]>(
    initialVideo as UploadFile[],
  );

  const [thumbnailFileList, setThumbnailFileList] = useState<UploadFile[]>(
    initialThumbnail as UploadFile[],
  );

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const thumbnailUpload = async ({
    onSuccess,
    onProgress,
    file,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'review-thumbnail',
        onProgress: onProgress,
      });
      if (onSuccess) onSuccess('ok');
      const addedThumbnail = { ...response, status: 'done', file };
      setAddedThumbnails([...addedThumbnails, addedThumbnail]);
      setIsUploadingImage(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const removeThumbnail = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedThumbnails([
        ...removedThumbnails,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedThumbnails(
        addedThumbnails.filter(thumbnail => thumbnail.file.uid !== file.uid),
      );
    }
  };

  const videoUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadVideo({
        file,
        path: 'review-video',
        onProgress: onProgress,
        extraBodyData: {
          type: data?.type,
        },
      });
      if (onSuccess) onSuccess('ok');
      const addedVideo = { ...response, status: 'done', file };
      setAddedVideos([...addedVideos, addedVideo]);
      setIsUploadingImage(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const removeVideo = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedVideos([
        ...removedVideos,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedVideos(addedVideos.filter(video => video.file.uid !== file.uid));
    }
  };

  const resetForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [review, form, countries, brands]);

  useEffect(() => {
    if (!brandId) {
      form.resetFields();
    }
  }, [models]);

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
        setVideoFileList([]);
        setAddedVideos([]);
        setRemovedVideos([]);
        setThumbnailFileList([]);
        setAddedThumbnails([]);
        setRemovedThumbnails([]);
      }}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={CustomizedRequiredMark}
    >
      <Row>
        <Col span={6}>
          <Space wrap align="start">
            <div
              style={{
                paddingTop: '5px',
              }}
            >
              <Text>Featured</Text>
            </div>
            <Item name="is_featured" valuePropName="checked">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                title="Featured"
                checked={review?.is_featured}
              />
            </Item>
          </Space>
        </Col>
        <Col span={6}>
          <Space wrap align="start">
            <div
              style={{
                paddingTop: '5px',
              }}
            >
              <Text>Show In Model</Text>
            </div>
            <Item name="show_in_model" valuePropName="checked">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                title="Show In Model"
                checked={review?.show_in_model}
              />
            </Item>
          </Space>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={8}>
          <Item
            label="Language"
            name="lang"
            rules={[
              {
                required: true,
                message: 'Please select a language',
              },
            ]}
          >
            <Select placeholder="Select a language" size="large">
              {Object.keys(Language).map((langKey: string) => {
                const langValue = Language[langKey as keyof typeof Language];
                return (
                  <Select.Option key={langValue} value={langValue}>
                    {langKey}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: 'Please select the country',
              },
            ]}
          >
            <Select
              disabled={!countries || isLoadingCountries}
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={() => {
                form.setFieldsValue({
                  city_id: undefined,
                });
              }}
              size="large"
            >
              {countries?.map(country => {
                return (
                  <Select.Option key={country?.id} value={country?.id}>
                    {country?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="City"
            name="city_id"
            rules={[
              {
                required: true,
                message: 'Please select the city',
              },
            ]}
          >
            <Select
              disabled={!countryId || isLoadingCountries}
              showSearch
              placeholder="Select a city"
              optionFilterProp="children"
              size="large"
            >
              {cityOptions?.map(city => {
                return (
                  <Select.Option key={city.code} value={city.code}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Reviewer"
            name="reviewer_id"
            rules={[
              {
                required: true,
                message: 'Please select the reviewer',
              },
            ]}
          >
            {isEditing ? (
              <Input disabled />
            ) : (
              <Select
                disabled={!reviewers || isLoadingReviewers}
                showSearch
                placeholder="Select a reviewer"
                optionFilterProp="children"
                size="large"
              >
                {reviewers?.map(reviewer => {
                  return (
                    <Select.Option key={reviewer?.id} value={reviewer?.id}>
                      {reviewer?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: 'Please enter a title' },
              {
                max: maxTitleLength,
                message: `Title should be less than ${
                  maxTitleLength + 1
                } characters`,
              },
            ]}
          >
            <Input placeholder="Please enter a title" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item label="Brand" name="brand_id">
            <Select
              showSearch
              size="large"
              placeholder="Select a brand"
              optionFilterProp="children"
              loading={isLoadingBrands}
              disabled={isLoadingBrands}
              onChange={() => {
                form.setFieldsValue({
                  model_id: undefined,
                });
              }}
            >
              {brands?.map((brand: CarBrand) => (
                <Select.Option key={brand.id} value={brand.id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item label="Model" name="model_id">
            <Select
              disabled={!models || isLoadingModels || !brandId}
              showSearch
              placeholder="Select a model"
              optionFilterProp="children"
              size="large"
            >
              {models?.map((model: ICarModel) => {
                return (
                  <Select.Option key={model?.id} value={model?.id}>
                    {model?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter a description',
              },
            ]}
          >
            <Editor
              value={form.getFieldValue('description')}
              placeholder="Description"
              onTextChange={(e: any) =>
                form.setFieldsValue({ description: e.htmlValue })
              }
            />
          </Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        wrap
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '1rem',
          width: '100%',
          marginLeft: '10%',
        }}
      >
        <Col span={8}>
          <VideoUpload
            fileList={videoFileList}
            label="Video"
            maxNbFiles={1}
            name="video"
            setFileList={setVideoFileList}
            customRequest={props => videoUpload({ ...props })}
            onRemove={removeVideo}
            requiredMessage="Please upload a video."
          />
        </Col>
        <Col span={8}>
          <FileUpload
            fileList={thumbnailFileList}
            label="Thumbnail"
            maxNbFiles={1}
            name="thumbnail"
            setFileList={setThumbnailFileList}
            customRequest={props => thumbnailUpload({ ...props })}
            onRemove={removeThumbnail}
            requiredMessage="Please upload a thumbnail image for the video."
          />
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
              setVideoFileList([]);
              setAddedVideos([]);
              setRemovedVideos([]);
              setThumbnailFileList([]);
              setAddedThumbnails([]);
              setRemovedThumbnails([]);
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isUploadingImage}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
