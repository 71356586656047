import BatteryRangesLayout from '../../../resources/cars/car_battery_range/BatteryRangesLayout';
import { RouteType } from '../../routes/routesConfig';

const BatteryRange = () => {
  return <BatteryRangesLayout />;
};

const batteryRangeRoute: RouteType = {
  element: <BatteryRange />,
  state: 'battery-range',
  index: true,
  path: '/resources/cars/battery-range',
  sideBarProps: {
    displayText: 'Battery Range',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { BatteryRange, batteryRangeRoute };
