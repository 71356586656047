import api from '../../../api/apiIndex';
import { rentalDealersEndpoint } from './getRentalDealers';

export interface IEditRentalDealer {
  name?: string;
  arabic_name?: string;
  city_id?: number;
  add_logo?: { id: number };
  remove_logo?: { url: string };
}

export const editRentalDealer = async ({
  id,
  data: rentalDealer,
}: {
  id: number;
  data: IEditRentalDealer;
}) => {
  try {
    const response = await api.put(
      `${rentalDealersEndpoint}/${id}`,
      rentalDealer,
    );
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};
