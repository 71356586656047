import { useEffect, useState } from 'react';
import { ICarType } from '../../../models/resources/CarType';
import { Input, Table, Button, Popconfirm, message, Tag } from 'antd';
import { useSearch } from '../../../hooks/useSearch';
import { ColumnsType } from 'antd/es/table';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  useActivateType,
  useCarTypes,
  useDeactivateType,
  useDeleteCarType,
} from './hooks';
import CarTypesModal from './components/CarTypesModal';
import { Layout } from '../../../components/Layout';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';

const { Search } = Input;

const CarTypesLayout = () => {
  const { data: types, isLoading: isLoadingTypes } = useCarTypes({
    enabled: true,
  });
  const { mutateAsync: deleteTypeMutation, isLoading: isDeleting } =
    useDeleteCarType();
  const { mutateAsync: activateType, isLoading: isActivating } =
    useActivateType();
  const { mutateAsync: deactivateType, isLoading: isDeactivating } =
    useDeactivateType();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentType, setCurrentType] = useState<ICarType | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchCarTypes } = useSearch();
  const [filteredData, setFilteredData] = useState(types);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarType | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ICarType | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateType(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Type deactivated successfully');
          },
        })
      : activateType(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Type activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteTypeMutation(id, {
      onSuccess: () => {
        message.success('Type deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const openEmptyForm = () => {
    setCurrentType(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onEditButtonPress = (type: ICarType) => {
    setIsEditing(true);
    setCurrentType(type);
    setIsFormOpen(true);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchCarTypes(types, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchCarTypes(types, searchValue);
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(types);
  }, [types]);

  const tableColumns: ColumnsType<ICarType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: ICarType, b: ICarType) => a.name.localeCompare(b.name),
    },
    {
      title: 'Arabic Name',
      dataIndex: `${types?.map((type: ICarType) => type.t[0]?.name)}`,
      key: `${types?.map((type: ICarType) => type.t[0]?.name)}`,
      sorter: (a: ICarType, b: ICarType) =>
        a.t?.[0]?.name?.localeCompare(b.name),
      render: (text: any, record: ICarType) => <div>{record.t?.[0]?.name}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarType) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICarType) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone onClick={() => onEditButtonPress(record)} />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this type will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this type.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Types"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Type
        </Button>
      </div>
      <Table
        loading={types?.length === 0 || isLoadingTypes}
        columns={tableColumns}
        dataSource={filteredData}
        rowKey={record => record.id}
        pagination={false}
        scroll={{ x: 1300, y: 720 }}
      />
      <CarTypesModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        currentType={currentType}
        setCurrentType={setCurrentType}
        isLoadingTypes={isLoadingTypes}
      />
    </Layout>
  );
};

export default CarTypesLayout;
