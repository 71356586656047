import BannersLayout from '../../../ads/banners/components/BannersLayout';
import { RouteType } from '../../routes/routesConfig';

const Banners = () => {
  return <BannersLayout />;
};
const bannersRoute: RouteType = {
  path: '/ads/banners',
  index: true,
  element: <Banners />,
  state: 'banners',
  sideBarProps: {
    displayText: 'Ad Banners',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Banners, bannersRoute };
