import { Toast } from 'primereact/toast';
import {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { checkIfApiTokenExists as getApiTokenIfAny } from '../api/apiIndex';
import { getAuthenticatedUser } from '../authentication/apis/authenticationApi';
import User from '../models/User';
import Loader from '../components/misc/Loader';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

type ContextType = {
  isAuthenticated: boolean | null;
  userInfo: User | null;
  setIsAuthenticated: (authState: boolean) => void;
  setUserInfo: (user: User | null) => void;
};
const AppContext = createContext<ContextType>({
  isAuthenticated: null,
  userInfo: null,
  setIsAuthenticated: () => {},
  setUserInfo: () => {},
});

type AppContextProviderProps = {
  children: ReactNode;
};
export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [userInfo, setUserInfo] = useState<User | null>(null);

  const handleAuthenticationChange = (authState: boolean) => {
    setIsAuthenticated(authState);
  };

  const checkAuthentication = useCallback(async () => {
    try {
      const userInfo = await getAuthenticatedUser();
      setUserInfo(userInfo);
      handleAuthenticationChange(true);
    } catch (error: any) {
      setUserInfo(null);
      if (window.location.pathname != '/login')
        window.location.pathname = '/login';
      handleAuthenticationChange(false);
      return false;
    }
  }, []);

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        userInfo,
        setIsAuthenticated,
        setUserInfo,
      }}
    >
      {isAuthenticated == null ? (
        <Loader width="100px" height="100px" />
      ) : (
        children
      )}
    </AppContext.Provider>
  );
};

export default AppContext;
