import api from '../api/apiIndex';
import { Bank } from '../models/companies/Bank';

export enum CompanyType {
  Bank = 'financing',
  Insurance = 'insurance',
}

type CreateCompanyRequest = {
  name: string;
  arabicName: string;
  logo: Blob;
  details: string;
  arabicDetails: string;
  benefits: Array<string>;
  arabicBenefits: Array<string>;
  slug: string;
  countryId: number;
  cityId: number;
};

type UpdateCompanyRequest = {
  name?: string;
  arabicName?: string;
  logo?: Blob;
  details?: string;
  arabicDetails?: string;
  benefits?: Array<string>;
  arabicBenefits?: Array<string>;
  slug?: string;
  countryId?: number;
  cityId?: number;
};

const companyEndpoint = (type: CompanyType) => `/companies/${type}`;

const getAllCompanies = async (type: CompanyType) => {
  const response = await api.get(companyEndpoint(type));
  const mappedResponse = response.data.data.map((company: any) => {
    switch (company.type) {
      case CompanyType.Bank: {
        return new Bank(company);
      }
    }
  });
  return mappedResponse;
};

const createCompany = async (
  type: CompanyType,
  request: CreateCompanyRequest,
) => {
  const response = await api.post(
    companyEndpoint(type),
    mapToFormData(request),
  );
  const mappedCompany = new Bank(response.data.data);

  return mappedCompany;
};

const updateCompany = async (
  type: CompanyType,
  id: number,
  request: UpdateCompanyRequest,
) => {
  const response = await api.post(
    `${companyEndpoint(type)}/${id}`,
    mapToFormData(request),
  );
  const mappedCompany = new Bank(response.data.data);
  return mappedCompany;
};

const deleteCompany = async (type: CompanyType, id: number) => {
  const response = await api.delete(`${companyEndpoint(type)}/${id}`);
  return response.data.message;
};

const mapToFormData = (
  request: CreateCompanyRequest | UpdateCompanyRequest,
) => {
  const data = new FormData();
  if (request.name) data.append('name', request.name);
  if (request.arabicName) data.append('arabic_name', request.arabicName);
  if (request.logo) data.append('logo', request.logo);
  if (request.details) data.append('details', request.details);
  if (request.arabicDetails)
    data.append('arabic_details', request.arabicDetails);
  if (request.benefits && request.benefits.length > 0) {
    request.benefits.map((benefit: string, index: number) => {
      if (benefit.length === 0) return;
      data.append(`benefits[${index}]`, benefit);
    });
  }

  if (request.arabicBenefits && request.arabicBenefits.length > 0) {
    request.arabicBenefits.map((benefit: string, index: number) => {
      if (benefit.length === 0) return;
      data.append(`arabic_benefits[${index}]`, benefit);
    });
  }
  if (request.slug) data.append('slug', request.slug);
  if (request.countryId)
    data.append('country_id', request.countryId.toString());
  if (request.cityId) data.append('city_id', request.cityId.toString());

  return data;
};

export { getAllCompanies, createCompany, updateCompany, deleteCompany };
