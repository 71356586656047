import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import {
  Form,
  Select,
  Space,
  Button,
  Row,
  Col,
  Typography,
  Switch,
  Input,
} from 'antd';
import {
  IBannerResource,
  IBannerResourceImage,
} from '../../../models/ads/BannerResource';
import { useEffect, useState } from 'react';
import { BannerContentType, BannerPlacement, BannerSection } from '../apis';
import FileUpload from '../../../components/inputs/FileUpload';
import { uploadImage } from '../../../common/utils';
import { AxiosError } from 'axios';

type CustomUploadFileStatus = UploadFileStatus | 'old';

type BannersFormProps = {
  banner: IBannerResource | null;
  isEditing: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (banner: IBannerResource) => void;
  addedImages: any[];
  removedImages: any[];
  setAddedImages: (images: IBannerResourceImage[]) => void;
  setRemovedImages: (urls: Array<Record<string, string>>) => void;
};

const { Item } = Form;
const { Text } = Typography;

export const BannersForm = ({
  banner,
  isEditing,
  isLoading,
  onClose,
  onSubmit,
  addedImages,
  removedImages,
  setAddedImages,
  setRemovedImages,
}: BannersFormProps) => {
  const [form] = Form.useForm();
  const initialImage = banner?.banner_resource_images
    ? [
        {
          uid: banner.banner_resource_images,
          name: banner.banner_resource_images?.split('/').pop(),
          status: 'old',
          url: banner.banner_resource_images,
        },
      ]
    : [];

  const initialValues = banner
    ? {
        section_name: banner.section_name,
        placement: banner.placement,
        content_type: banner.content_type,
        banner_resource_images: initialImage,
        show_default_image: banner.show_default_image,
      }
    : undefined;

  const processFormValues = (values: IBannerResource) => {
    const {
      content_type,
      banner_resource_images,
      show_default_image,
      ...otherValues
    } = values;

    // Override show_default_image to false if content type is 'video'
    const finalValues = {
      ...otherValues,
      content_type,
      banner_resource_images,
      show_default_image:
        content_type === BannerContentType.Video ? false : show_default_image,
    };

    return finalValues;
  };

  const [fileList, setFileList] = useState<UploadFile[]>(
    initialImage as UploadFile[],
  );

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const imageUpload = async ({
    onSuccess,
    onProgress,
    file,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'banner-resource-image',
        onProgress: onProgress,
      });
      if (onSuccess) onSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedImages([...addedImages, addedImage]);
      setIsUploadingImage(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const removeImage = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedImages([
        ...removedImages,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedImages(addedImages.filter(image => image.file.uid !== file.uid));
    }
  };

  const resetForm = () => {
    setFileList([]);
    setAddedImages([]);
    setRemovedImages([]);
    form.resetFields();
  };

  const [showImageUpload, setShowImageUpload] = useState<boolean>(false);
  const [currentContentType, setCurrentContentType] = useState<
    string | undefined
  >(initialValues?.content_type);

  const updateVisibility = (contentType: string) => {
    setShowImageUpload(contentType !== BannerContentType.Video);
  };

  const onContentTypeChange = (value: string) => {
    form.setFieldsValue({ content_type: value });
    updateVisibility(value);
    const defaultImageValue = value !== BannerContentType.Video;
    form.setFieldsValue({ show_default_image: defaultImageValue });
  };

  useEffect(() => {
    if (banner) {
      updateVisibility(banner.content_type);
    }
  }, [banner]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="banner-form"
      initialValues={initialValues}
      onValuesChange={changedValues => {
        if ('content_type' in changedValues) {
          const contentType = changedValues['content_type'];
          setCurrentContentType(contentType);
          updateVisibility(contentType);
        }
      }}
      onFinish={(values: any) => {
        const finalValues = processFormValues(values);
        onSubmit(finalValues);
        resetForm();
      }}
    >
      <Row gutter={16} wrap>
        <Col span={8}>
          <Item
            label="Placement"
            name="placement"
            rules={[
              {
                required: true,
                message: 'Please select the placement of the banner',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a placement"
              optionFilterProp="children"
              size="large"
            >
              {Object.values(BannerPlacement).map(value => {
                return (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Section Name"
            name="section_name"
            rules={[{ required: true, message: 'Please enter section name' }]}
          >
            <Input size="large" placeholder="Section Name" />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Content Type"
            name="content_type"
            rules={[
              {
                required: true,
                message: 'Please select the content type of the banner',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a content type"
              optionFilterProp="children"
              size="large"
              onChange={value => {
                form.setFieldsValue({ content_type: value });
                onContentTypeChange(value);
              }}
            >
              {Object.values(BannerContentType).map(value => {
                return (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      {showImageUpload &&
      (!initialValues || currentContentType !== BannerContentType.Video) ? (
        <Row
          gutter={16}
          wrap
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '1rem',
            marginLeft: '20%',
          }}
        >
          <Col span={6}>
            <Text>Show Default Image</Text>
            <Item name="show_default_image" valuePropName="checked">
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                title="Default Image"
                checked={banner?.show_default_image}
              />
            </Item>
          </Col>
          <Col span={12}>
            <FileUpload
              label="Image"
              maxNbFiles={1}
              name="banner_resource_images"
              fileList={fileList}
              setFileList={setFileList}
              customRequest={props =>
                imageUpload({
                  ...props,
                })
              }
              onRemove={removeImage}
              requiredMessage='Please upload an image for the banner'
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              resetForm();
              onClose();
              form.resetFields();
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isUploadingImage}
          >
            {isEditing ? 'Edit Banner' : 'Add Banner'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
