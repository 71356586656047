import { FlexColumn } from '../../components/Flex';
import { Space, Radio, Button } from 'antd';

export interface IFilters {
  value: number | string;
  text: string;
  isDefault?: boolean;
  color?: string;
}

interface FilterDropDownProps {
  filter: string | number;
  setFilter: (value: any) => void;
  options: IFilters[];
  isLoading: boolean;
}

export const FilterDropDown = ({
  filter,
  setFilter,
  options,
  isLoading,
}: FilterDropDownProps) => {
  const onChange = (e: any) => {
    setFilter(e.target.value);
  };
  return (
    <FlexColumn
      style={{
        padding: '0.5rem',
      }}
    >
      <Radio.Group onChange={onChange} value={filter} disabled={isLoading}>
        <Space direction="vertical">
          {options.map((option: any) => (
            <Radio key={option.value} value={option.value}>
              {option.text}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Button
        type="primary"
        onClick={() =>
          setFilter(options.find(option => option.isDefault)?.value)
        }
        disabled={isLoading}
        size="small"
        style={{
          marginTop: '0.5rem',
          width: '50px',
          alignSelf: 'flex-end',
          bottom: '0',
        }}
      >
        Clear
      </Button>
    </FlexColumn>
  );
};
