import { IFilters } from '../../components/filters/FilterDropDown';

export enum BikeCategory {
  Used = 'used',
  New = 'new',
}

export enum BikeStatus {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
  draft = 'draft',
}

export const filterFeaturedOptions: IFilters[] = [
  { text: 'All', value: '', isDefault: true },
  { text: 'Featured', value: 'true' },
  { text: 'Not Featured', value: 'false' },
];

export const filterStatusOptions: IFilters[] = [
  { text: 'All', value: '', isDefault: true },
  { text: 'Approved', value: BikeStatus.approved, color: 'green' },
  { text: 'Pending', value: BikeStatus.pending, color: 'blue' },
  { text: 'Rejected', value: BikeStatus.rejected, color: 'red' },
  { text: 'Draft', value: BikeStatus.draft, color: 'orange' },
];
