import api from '../../../../api/apiIndex';

const transmisssionsEndpointv2 = 'v2/admin/resources/cars/transmissions';

export interface IGetTransmissions {
  active?: boolean;
}

export interface ITransmissionPayload {
  name: string;
  arabic_name: string;
  engine_types: Array<number>;
}

const getTransmissions = async ({ active }: IGetTransmissions) => {
  try {
    const response = await api.get(transmisssionsEndpointv2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createTransmission = async (data: ITransmissionPayload) => {
  try {
    const response = await api.post(transmisssionsEndpointv2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateTransmission = async (id: number, data: ITransmissionPayload) => {
  try {
    const response = await api.put(`${transmisssionsEndpointv2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteTransmission = async (id: number) => {
  try {
    const response = await api.delete(`${transmisssionsEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateTransmission = async (id: number) => {
  try {
    const response = await api.put(
      `${transmisssionsEndpointv2}/activate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateTransmission = async (id: number) => {
  try {
    const response = await api.put(
      `${transmisssionsEndpointv2}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getTransmissions,
  createTransmission,
  updateTransmission,
  deleteTransmission,
};
