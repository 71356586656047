import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IArticlePayload,
  activateArticle,
  createArticle,
  deactivateArticle,
  deleteArticle,
  getArticles,
  updateArticle,
} from '../apis';
import { queryClient } from '../../App';

export const ARTICLES_KEY = ['articles'];

export const useGetArticles = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ARTICLES_KEY,
    queryFn: () => getArticles(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useCreateArticle = () => {
  return useMutation({
    mutationFn: (request: IArticlePayload) => createArticle(request),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};

export const useUpdateArticle = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IArticlePayload }) =>
      updateArticle(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};

export const useDeleteArticle = () => {
  return useMutation({
    mutationFn: (id: number) => deleteArticle(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};

export const useActivateArticle = () => {
  return useMutation({
    mutationFn: (id: number) => activateArticle(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};

export const useDeactivateArticle = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateArticle(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ARTICLES_KEY);
    },
  });
};
