import { UserGroups } from '../../../../common/constants';
import { BikeColorsLayout } from '../../../../resources/bikes/colors';
import { RouteType } from '../../../routes/routesConfig';

const BikeColor = () => {
  return <BikeColorsLayout />;
};

const bikeColorRoute: RouteType = {
  element: <BikeColor />,
  state: 'color',
  index: true,
  path: '/resources/bikes/colors',
  sideBarProps: {
    displayText: 'Colors',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeColor, bikeColorRoute };
