import { useEffect, useState } from 'react';
import { RentalDealer } from '../../../models/dealers/rentals/RentalDealer';
import { useCountries } from '../../../countries/hooks/useCountries';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Button,
  UploadFile,
  Modal,
  Image,
} from 'antd';
import { Option } from '../../../components/inputs/DropDown';
import { ICity } from '../../../models/countries/City';
import FileUpload from '../../../components/inputs/FileUpload';
import { useUploadRentalDealerImage } from '../hooks';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { IRentalDealerLogoUpload } from './RentalDealerModal';

type AddEditDealerFormProps = {
  rental: RentalDealer | null;
  onSubmit: (formData: any) => void;
  isLoading: boolean;
  onClose: () => void;
  isEditing: boolean;
  setUploadedLogo: (value: IRentalDealerLogoUpload) => void;
};

export const AddEditRentalDealerForm = ({
  rental,
  onSubmit,
  isLoading,
  onClose,
  isEditing,
  setUploadedLogo,
}: AddEditDealerFormProps) => {
  const [cityOptions, setCityOptions] = useState<Array<Option>>();
  const [openImage, setOpenImage] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>(
    rental?.logo
      ? [
          {
            uid: `${rental?.id}`,
            name: 'image',
            status: 'done',
            url: rental.logo,
          },
        ]
      : [],
  );

  const [form] = Form.useForm();
  const { Item } = Form;
  const countryId = Form.useWatch('country_id', form);

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { mutateAsync: uploadRentalDealerImage, isLoading: isImageUploading } =
    useUploadRentalDealerImage();

  const initialValues = rental
    ? {
        name: rental.name,
        arabic_name: rental.t.find(t => t.lang === 'ar')?.name,
        country_id: countries?.find(country =>
          country.cities?.find(city => city.id === rental.city_id),
        )?.id,
        city_id: rental.city_id,
        logo: rental?.logo
          ? [
              {
                uid: `${rental?.id}`,
                name: rental?.logo.split('/').pop() || '',
                status: 'done',
                url: rental.logo,
              },
            ]
          : [],
        allow_cars: false,
        main_dealer: true,
      }
    : undefined;

  const imageUpload = ({
    onSuccess,
    onProgress,
    file,
  }: UploadRequestOption) => {
    uploadRentalDealerImage(
      {
        file,
        path: 'rental-dealer-logo',
        onProgress: onProgress,
      },
      {
        onSuccess: response => {
          if (onSuccess) onSuccess('ok');
          setUploadedLogo(response);
        },
      },
    );
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [rental, form, countries]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row gutter={24} wrap>
          <Col span={12}>
            <Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter name' }]}
            >
              <Input placeholder="English Name" size="large" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Arabic Name"
              name="arabic_name"
              rules={[
                { required: true, message: 'Please enter an arabic name' },
              ]}
            >
              <Input placeholder="Arabic Name" size="large" />
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={12}>
            <Item
              label="Country"
              name="country_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the country of the rental dealer',
                },
              ]}
            >
              <Select
                disabled={!countries || isLoadingCountries}
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                onChange={() => {
                  form.setFieldsValue({
                    city_id: undefined,
                  });
                }}
                loading={isLoadingCountries}
                size="large"
              >
                {countries?.map(country => {
                  return (
                    <Select.Option key={country.id} value={country.id}>
                      {country.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="City"
              name="city_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the city of the rental dealer',
                },
              ]}
            >
              <Select
                disabled={!countryId}
                showSearch
                placeholder="Select a city"
                optionFilterProp="children"
                size="large"
              >
                {cityOptions?.map((city: Option) => {
                  return (
                    <Select.Option key={city.code} value={city.code}>
                      {city.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '1rem',
            width: '100%',
            marginLeft: '45%',
          }}
        >
          <Col span={24}>
            <Modal
              open={openImage}
              title="Image Preview"
              onCancel={() => setOpenImage(false)}
              footer={null}
            >
              <Image
                style={{ width: '100%' }}
                src={rental?.logo}
                preview={false}
              />
            </Modal>

            <Col span={24}>
              <FileUpload
                label="Logo"
                maxNbFiles={1}
                name="logo"
                fileList={fileList}
                setFileList={setFileList}
                customRequest={imageUpload}
                requiredMessage="Please upload a logo for the rental dealer"
              />
            </Col>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Space>
            <Button onClick={onClose} danger>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isImageUploading}
            >
              {isEditing ? 'Edit' : 'Add'}
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};
