import { Form, Input, Col, Row, Button, Space, message } from 'antd';
import {
  CAR_SEAT_MATERIALS_QUERY_KEY,
  useCreateCarSeatMaterial,
  useUpdateCarSeatMaterial,
} from '../hooks/useCarSeatMaterial';
import { queryClient } from '../../../../App';
import { CarSeatMaterial } from '../../../../models/resources/CarSeatMaterial';
import { ICarSeatMaterial } from '../utils/apiUtils';

const { Item } = Form;

interface IRentalFormProps {
  handleClose: () => void;
  selectedCarSeatMaterial?: ICarSeatMaterial;
  isEdit?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCarSeatMaterial: React.Dispatch<React.SetStateAction<any>>;
}

export const CarSeatMaterialForm = ({
  handleClose,
  selectedCarSeatMaterial,
  isEdit,
  setIsOpen,
  setSelectedCarSeatMaterial,
}: IRentalFormProps) => {
  //Initial Values
  const initialValues = selectedCarSeatMaterial
    ? {
        name: selectedCarSeatMaterial.name,
        arabic_name: selectedCarSeatMaterial?.t?.[0]?.name,
      }
    : undefined;

  //Form
  const [form] = Form.useForm();

  // API Hooks
  const { mutateAsync: createCarSeatMaterial, isLoading: isCreating } =
    useCreateCarSeatMaterial();

  const { mutateAsync: updateCarSeatMaterial, isLoading: isUpdating } =
    useUpdateCarSeatMaterial();

  //Functions
  const handleFormSuccess = () => {
    form.resetFields();
    setIsOpen(false);
    setSelectedCarSeatMaterial(null);
    queryClient.invalidateQueries({
      queryKey: CAR_SEAT_MATERIALS_QUERY_KEY,
    });
  };
  //Form Logic
  const handleSubmit = async (values: any) => {
    values = { ...values, status: true };

    try {
      createCarSeatMaterial(values, {
        onSuccess: () => {
          handleFormSuccess();
          message.success('Seat material added successfully');
        },
      });
    } catch {
      console.log('error');
    }
  };

  const handleEdit = async (values: any) => {
    values = { ...values, status: true };
    try {
      if (selectedCarSeatMaterial)
        updateCarSeatMaterial(
          {
            id: selectedCarSeatMaterial.id,
            data: values,
          },
          {
            onSuccess: () => {
              handleFormSuccess();
              message.success('Seat material edited successfully');
            },
            onError: () => {},
          },
        );
    } catch {
      console.log('error');
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      onFinish={isEdit ? handleEdit : handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name of the seat material',
              },
            ]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic name of the seat material',
              },
            ]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button danger onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEdit ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
