import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICarConditionPayload,
  activateCarCondition,
  createCarCondition,
  deactivateCarCondition,
  deleteCarCondition,
  getCarConditions,
  updateCarCondition,
} from '../carConditionApi';
import { queryClient } from '../../../../App';

export const CAR_CONDITIONS_QUERY_KEY = ['carConditions'];

interface IUseCarConditions {
  enabled: boolean;
  active?: boolean;
}

interface ICarConditionFormPayload {
  name: string;
  arabicName: string;
  status: number;
}

export const useCarCondition = ({ enabled, active }: IUseCarConditions) => {
  return useQuery({
    queryKey: CAR_CONDITIONS_QUERY_KEY,
    queryFn: () => getCarConditions({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCarCondition = () => {
  return useMutation({
    mutationFn: (data: ICarConditionPayload) => createCarCondition(data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_CONDITIONS_QUERY_KEY);
    },
  });
};

export const useUpdateCarCondition = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICarConditionPayload }) =>
      updateCarCondition(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_CONDITIONS_QUERY_KEY);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });
};

export const useDeleteCarCondition = () => {
  return useMutation({
    mutationFn: (id: number) => deleteCarCondition(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_CONDITIONS_QUERY_KEY);
    },
  });
};

export const useActivateCarCondition = () => {
  return useMutation({
    mutationFn: (id: number) => activateCarCondition(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_CONDITIONS_QUERY_KEY);
    },
  });
};

export const useDeactivateCarCondition = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateCarCondition(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_CONDITIONS_QUERY_KEY);
    },
  });
};
