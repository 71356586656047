import { Card, Select } from 'antd';
import {
  useGetDealerLeadEmailsForUsedCars,
  useRegisterDealerLeadEmailsForUsedCars,
  useUnregisterDealerLeadEmailsFromUsedCars,
} from '../hooks';
import { useState } from 'react';

export const DealerLeadEmailsForUsedCarsLayout = () => {
  const [search, setSearch] = useState<boolean>(false);
  const { data: leadEmails, isLoading: isFetching } =
    useGetDealerLeadEmailsForUsedCars();

  const {
    mutateAsync: registerDealerLeadEmailForUsedCars,
    isLoading: isRegistering,
  } = useRegisterDealerLeadEmailsForUsedCars();

  const {
    mutateAsync: unregisterDealerLeadEmailsFromUsedCars,
    isLoading: isUnregistering,
  } = useUnregisterDealerLeadEmailsFromUsedCars();

  const handleSelect = async (value: string) => {
    await registerDealerLeadEmailForUsedCars(value);
  };

  const handleDeselect = async (value: any) => {
    await unregisterDealerLeadEmailsFromUsedCars(value);
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  return (
    <Card title="Used Cars Email Notifications">
      <Select
        mode="tags"
        style={{ width: '100%' }}
        onDeselect={handleDeselect}
        onSelect={handleSelect}
        tokenSeparators={[',']}
        onSearch={handleSearch}
        loading={isFetching || isRegistering || isUnregistering}
        disabled={isFetching || isRegistering || isUnregistering}
        options={leadEmails?.unregisteredEmails?.map(leadEmail => {
          return { value: leadEmail?.email };
        })}
        popupClassName={`select-popup-hide-selected-options ${
          !search &&
          leadEmails?.unregisteredEmails?.length === 0 &&
          `select-popup-hide`
        }`}
        value={leadEmails?.registeredEmails?.map(lead => lead.email)}
      />
    </Card>
  );
};
