import { useEffect, useState } from 'react';
import { ICarDealer } from '../../../models/dealers/cars/CarDealer';
import {
  Button,
  Divider,
  Image,
  Input,
  List,
  Space,
  Spin,
  Typography,
  message,
  Popconfirm,
} from 'antd';
import {
  useActivateDealer,
  useDeactivateDealer,
  useDeleteDealer,
  useGetAllDealers,
} from '../hooks';
import { Flex, FlexCentered, FlexColumn } from '../../../components/Flex';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { useGetAllBrands } from '../../../resources/cars/car_brands/hooks/useBrands';
import { DealerModal } from './DealerModal';
import { UserGroups } from '../../../common/constants';
import { EmptyState } from '../../../components/emptyState/EmptyState';
import { useSearch } from '../../../hooks/useSearch';
import { Layout } from '../../../components/Layout';
import fallBackLogo from '../../../assets/fallback.webp';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import useAuth from '../../../hooks/useAuth';
import { useGetAllBikeBrands } from '../../../resources/bikes/bike_brands/hooks';

const { Search } = Input;

const AllDealersLayout = () => {
  const { data: dealers, isLoading: LoadingDealers } = useGetAllDealers({
    enabled: true,
  });
  const { data: brands, isLoading: LoadingBrands } = useGetAllBrands({
    active: true,
  });
  const { data: bikeBrands } = useGetAllBikeBrands({
    active: true,
  });
  const { mutateAsync: deleteDealer, isLoading: isDeleting } =
    useDeleteDealer();
  const { mutateAsync: activeDealer, isLoading: isActive } =
    useActivateDealer();
  const { mutateAsync: deactiveDealer, isLoading: isDeactive } =
    useDeactivateDealer();

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isSuperAdmin = userGroup === UserGroups.OAC;

  // Search States
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchDealers } = useSearch();
  const [filteredData, setFilteredData] = useState(dealers);

  const [currentDealer, setCurrentDealer] = useState<ICarDealer | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarDealer | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ICarDealer | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactiveDealer(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Dealer Deactivated Successfully');
          },
        })
      : activeDealer(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Dealer Activated Successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteDealer(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Dealer Deleted Successfully');
      },
    });
  };

  const openEmptyForm = () => {
    setIsFormOpen(true);
    setCurrentDealer(null);
    setIsEditing(false);
  };

  const openEditForm = (dealer: ICarDealer) => {
    setCurrentDealer(dealer);
    setIsFormOpen(true);
    setIsEditing(true);
  };

  useEffect(() => {
    if (Array.isArray(dealers)) {
      const newData = handleSearchDealers(dealers, searchValue);
      setFilteredData(newData);
    } else if (dealers && typeof dealers === 'object') {
      const newData = handleSearchDealers([dealers], searchValue);
      setFilteredData(newData);
    }
  }, [searchValue, dealers]);

  useEffect(() => {
    if (Array.isArray(dealers)) {
      setFilteredData(dealers);
    } else if (dealers && typeof dealers === 'object') {
      setFilteredData([dealers]);
    }
  }, [dealers]);

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Dealers"
          onSearch={value => setSearchValue(value)}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        {isSuperAdmin && (
          <Button onClick={openEmptyForm} type="primary">
            New
          </Button>
        )}
      </div>
      {LoadingDealers ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {filteredData?.length > 0 ? (
            <List
              style={{
                padding: '0 1rem',
              }}
              grid={{
                gutter: 16,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 2,
                xxl: 3,
              }}
              dataSource={filteredData}
              renderItem={(dealer: ICarDealer) => (
                <List.Item
                  key={dealer.id}
                  style={{ borderBottom: '1px solid #e8e8e8' }}
                >
                  <List.Item.Meta key={dealer.id} />
                  <FlexCentered
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '2rem',
                    }}
                  >
                    <Flex style={{ alignItems: 'center' }}>
                      <Space size={'middle'}>
                        <Image
                          src={dealer?.logo}
                          width={120}
                          height={50}
                          preview={false}
                          style={{
                            objectFit: 'contain',
                          }}
                          fallback={fallBackLogo}
                          alt="fallback image"
                        />
                        <FlexColumn>
                          {dealer.name}
                          <Typography.Text type="secondary" className="mb-0">
                            {(dealer.brand_dealer?.length || 0) +
                              ' Car Brands / ' +
                              (dealer.bike_brand_dealer?.length || 0) +
                              ' Bike Brands'}
                          </Typography.Text>
                        </FlexColumn>
                      </Space>
                    </Flex>
                    <Space split={<Divider type="vertical" />}>
                      {isSuperAdmin && (
                        <Popconfirm
                          title="Are you sure?"
                          description={`Clicking "Yes" will ${
                            dealer.is_active ? 'deactivate' : 'activate'
                          } this dealer.`}
                          open={openToggleActivateDialog === dealer}
                          onConfirm={() =>
                            handleToggleActivate(dealer.id, dealer.is_active)
                          }
                          okButtonProps={{
                            loading: isActive || isDeactive,
                          }}
                          onCancel={() => setOpenToggleActivateDialog(null)}
                          okText="Yes"
                          placement="topRight"
                        >
                          {dealer.is_active ? (
                            <InactiveIcon
                              onClick={() => {
                                setOpenToggleActivateDialog(dealer);
                              }}
                            />
                          ) : (
                            <ActiveIcon
                              onClick={() => {
                                setOpenToggleActivateDialog(dealer);
                              }}
                            />
                          )}
                        </Popconfirm>
                      )}
                      <EditTwoTone onClick={() => openEditForm(dealer)} />
                      {isSuperAdmin && (
                        <Popconfirm
                          title="Are you sure?"
                          description="Deleting this dealer will delete all its related data."
                          open={openDeleteDialog === dealer}
                          onConfirm={() => handleDelete(dealer.id)}
                          okButtonProps={{ loading: isDeleting }}
                          onCancel={() => setOpenDeleteDialog(null)}
                          okText="Yes"
                          placement="topRight"
                        >
                          <DeleteTwoTone
                            style={{ fontSize: '1rem', color: 'var(--error)' }}
                            onClick={() => {
                              setOpenDeleteDialog(dealer);
                            }}
                          />
                        </Popconfirm>
                      )}
                    </Space>
                  </FlexCentered>
                </List.Item>
              )}
            />
          ) : (
            <EmptyState type="dealers" />
          )}
          <DealerModal
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            isEditing={isEditing}
            currentDealer={currentDealer}
            setCurrentDealer={setCurrentDealer}
            brands={brands}
            bikeBrands={bikeBrands}
            isLoadingBrands={LoadingBrands}
          />
        </>
      )}
    </Layout>
  );
};

export default AllDealersLayout;
