import { CheckCircleTwoTone } from '@ant-design/icons';

interface ActiveIconProps {
  onClick?: () => void;
}

const ActiveIcon = ({ onClick }: ActiveIconProps) => {
  return <CheckCircleTwoTone onClick={onClick} />;
};

export default ActiveIcon;
