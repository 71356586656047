import {
  Form,
  Input,
  Typography,
  Col,
  Row,
  Space,
  Switch,
  Select,
  Divider,
  DatePicker,
  InputNumber,
  Button,
} from 'antd';
import useAuth from '../../../../hooks/useAuth';
import { Flex } from '../../../../components/Flex';
import { useGetAllModels } from '../../../../resources/cars/car_models/hooks/useModels';
import { useGetAllBrands } from '../../../../resources/cars/car_brands/hooks/useBrands';
import dayjs from 'dayjs';
import { useTransmissions } from '../../../../resources/cars/car_transmission/hooks/useCarTransmissions';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IRentalCar } from '../../../../models/listings/cars/rentals/RentalCar';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import { CustomizedRequiredMark } from '../../../../components/misc/RequiredMark';
import { useUploadRentalImage } from '../hooks/useRentalCars';
import { useGetLocations } from '../../../../resources/cars/rental_locations/useLocationsApi';
import { ILocation } from '../../../../models/locations/Location';
import { CarBrand } from '../../../../models/resources/CarBrand';
import { ICarModel } from '../../../../models/resources/CarModel';
import FileUpload from '../../../../components/inputs/FileUpload';
import { IRentalCarImage } from '../../../../models/listings/cars/rentals/RentalCarImage';
import { useGetAllDealers } from '../../../../dealers/Automotive/hooks';
import { UserGroups } from '../../../../common/constants';
import { useGetRentalDealers } from '../../../../dealers/RentalDealer/hooks';

type CustomUploadFileStatus = UploadFileStatus | 'old';

const { Item } = Form;
const { Text } = Typography;

interface IRentalFormProps {
  selectedCar: IRentalCar | null;
  isEditing: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (formData: any) => void;
  addedImages: any[];
  removedImages: any[];
  setAddedImages: Dispatch<SetStateAction<IRentalCarImage[]>>;
  setRemovedImages: (urls: Array<Record<string, string>>) => void;
}

export const RentalForm = ({
  onClose,
  selectedCar,
  isEditing,
  isLoading,
  onSubmit,
  addedImages,
  removedImages,
  setAddedImages,
  setRemovedImages,
}: IRentalFormProps) => {
  //Initial Values
  const initialCoverImage = selectedCar?.cover
    ? [
        {
          uid: selectedCar.cover,
          status: 'old',
          url: selectedCar.cover,
          id: selectedCar.cover,
          name: selectedCar.cover?.split('/').pop(),
        },
      ]
    : [];

  const initialImages = selectedCar?.image
    ? Array.isArray(selectedCar?.image)
      ? selectedCar?.image.map((image: any) => {
          return {
            uid: image,
            status: 'old',
            url: image,
            id: image,
            name: image.split('/').pop(),
          };
        })
      : [
          {
            uid: selectedCar?.image,
            status: 'old',
            url: selectedCar.image,
            id: selectedCar.image,
            name: selectedCar.image?.split('/').pop(),
          },
        ]
    : [];

  //Form
  const [form] = Form.useForm();

  //States
  const [fileListCover, setFileListCover] = useState<UploadFile[]>(
    initialCoverImage as UploadFile[],
  );
  const [fileList, setFileList] = useState<UploadFile[]>(
    initialImages as UploadFile[],
  );

  //Auth Hooks
  const isOneAutocar = useAuth().userInfo?.group === UserGroups.OAC;

  //Watchers
  const brandId = Form.useWatch('brand_id', form);
  const modelId = Form.useWatch('model_id', form);

  // API Hooks

  const { mutateAsync: uploadRentalImage, isLoading: isImageUploading } =
    useUploadRentalImage();

  const { data: dealers, isLoading: isLoadingDealerNames } =
    useGetRentalDealers({
      enabled: true,
      active: true,
    });

  const { data: brands, isLoading: isLoadingBrands } = useGetAllBrands({
    active: true,
  });

  const { data: locations, isLoading: isLoadingLocations } = useGetLocations({
    active: true,
  });

  const { data: models, isLoading: isLoadingModels } = useGetAllModels({
    enabled: isEditing || !!brandId,
    brandId: brandId,
    active: true,
  });

  const { data: transmissions, isLoading: isLoadingTransmissions } =
    useTransmissions({
      enabled: true,
    });

  const initialValues = selectedCar
    ? {
        dealer_id: selectedCar.dealer_id,
        is_featured: selectedCar.is_featured,
        brand_id: brands?.find(
          (brand: CarBrand) =>
            brand.id ===
            models?.find(
              (model: ICarModel) => model.id === selectedCar?.model_id,
            )?.brand_id,
        )?.id,
        model_id: selectedCar.model_id,
        type_id: selectedCar.type_id,
        transmission_id: selectedCar.transmission_id,
        year: dayjs(selectedCar.year),
        seats: selectedCar.seats,
        small_baggage_size: selectedCar.small_baggage_size,
        large_baggage_size: selectedCar.large_baggage_size,
        price_per_day: selectedCar.price_per_day,
        price_per_week: selectedCar.price_per_week,
        price_per_month: selectedCar.price_per_month,
        price_per_year: selectedCar.price_per_year,
        description: selectedCar.description,
        arabic_description: selectedCar.t.find(trans => trans.lang === 'ar')
          ?.description,
        mobile_number:
          selectedCar.mobile_number &&
          selectedCar.mobile_number.startsWith('974')
            ? selectedCar.mobile_number.slice(3)
            : selectedCar.mobile_number,
        whatsapp_number:
          selectedCar.whatsapp_number &&
          selectedCar.whatsapp_number.startsWith('974')
            ? selectedCar.whatsapp_number.slice(3)
            : selectedCar.whatsapp_number,
        locations: selectedCar.location_rental_car?.map(
          location => location.location_id,
        ),
        cover_image: initialCoverImage,
        images: initialImages,
      }
    : undefined;

  //Images functions
  const imageUpload = ({
    onSuccess,
    onProgress,
    file,
    data,
  }: UploadRequestOption) => {
    uploadRentalImage(
      {
        file,
        path: 'rental-car-image',
        onProgress: onProgress,
        extraBodyData: {
          type: data?.type,
        },
      },
      {
        onSuccess: response => {
          if (onSuccess) onSuccess('ok');
          const addedImage = { ...response, status: 'done', file };
          setAddedImages(prevImages => [...prevImages, addedImage]);
        },
      },
    );
  };

  const removeImage = (file: UploadFile) => {
    console.log(file);
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedImages([
        ...removedImages,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedImages(addedImages.filter(image => image.file.uid !== file.uid));
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedCar, form, brands, dealers]);

  useEffect(() => {
    if (!brandId) {
      form.resetFields();
    }
  }, [models]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="rentalForm"
      scrollToFirstError
      requiredMark={CustomizedRequiredMark}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      {isOneAutocar && (
        <>
          <Row gutter={16} wrap justify="center">
            <Col span={12}>
              <Space.Compact block>
                <div
                  style={{
                    paddingTop: '5px',
                    width: '80px',
                  }}
                >
                  <span
                    style={{
                      color: 'red',
                      paddingRight: '3px',
                    }}
                  >
                    *
                  </span>
                  <Text>Dealer</Text>
                </div>
                <Item
                  name="dealer_id"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  style={{
                    width: '100%',
                  }}
                >
                  <Select
                    showSearch
                    placeholder="Select a dealer"
                    optionFilterProp="children"
                    loading={isLoadingDealerNames}
                    disabled={isLoadingDealerNames}
                  >
                    {dealers?.map((dealerName: any) => (
                      <Select.Option key={dealerName.id} value={dealerName.id}>
                        {dealerName.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Space.Compact>
            </Col>
            <Col span={4}>
              <Flex>
                <Space wrap align="start">
                  <div
                    style={{
                      paddingTop: '5px',
                    }}
                  >
                    <Text>Featured</Text>
                  </div>
                  <Item name="is_featured" valuePropName="checked">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={selectedCar?.is_featured ?? false}
                      title="Featured"
                    />
                  </Item>
                </Space>
              </Flex>
            </Col>
          </Row>
          <Divider />
        </>
      )}
      <Row gutter={16} wrap>
        <Col span={8}>
          <Item
            label="Brand"
            name="brand_id"
            rules={[
              {
                required: true,
                message: 'Please select the brand of the car',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a brand"
              optionFilterProp="children"
              loading={isLoadingBrands}
              disabled={isLoadingBrands}
              onChange={() => {
                form.setFieldsValue({
                  modelId: undefined,
                });
              }}
            >
              {brands?.map((brand: CarBrand) => (
                <Select.Option key={brand.id} value={brand.id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Model"
            name="model_id"
            rules={[
              {
                required: true,
                message: 'Please select the model of the car',
              },
            ]}
            dependencies={['brand']}
          >
            <Select
              showSearch
              placeholder="Select a model"
              optionFilterProp="children"
              loading={isLoadingModels}
              disabled={isLoadingModels || !brandId}
              onChange={() => {
                form.setFieldsValue({
                  type_id: undefined,
                });
              }}
            >
              {models?.map((model: any) => (
                <Select.Option key={model.id} value={model.id}>
                  {model.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            label="Type"
            name="type_id"
            rules={[
              {
                required: true,
                message: 'Please select the type of the car',
              },
            ]}
            dependencies={['model']}
          >
            <Select
              showSearch
              placeholder="Select a type"
              optionFilterProp="children"
              disabled={!modelId}
            >
              {models
                ?.find((model: any) => model.id === modelId)
                ?.model_type.map((type: any) => {
                  return (
                    <Select.Option key={type.types.id} value={type.types.id}>
                      {type.types.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap justify={'space-between'}>
        <Col span={8}>
          <Item
            label="Transmission"
            name="transmission_id"
            rules={[
              {
                required: true,
                message: 'Please select the transmission of the car',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a transmission"
              optionFilterProp="children"
              loading={isLoadingTransmissions}
              disabled={isLoadingTransmissions}
            >
              {transmissions?.map((transmission: any) => (
                <Select.Option key={transmission.id} value={transmission.id}>
                  {transmission.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={4}>
          <Item
            label="Model Year"
            name="year"
            rules={[
              {
                required: true,
                message: 'Select the model year of the car',
              },
            ]}
            tooltip="Model Year is the year the car was manufactured"
          >
            <DatePicker
              placeholder="Select a year"
              picker="year"
              disabledDate={current => {
                return current.year() > dayjs().year() + 1;
              }}
              style={{
                width: '100%',
              }}
            />
          </Item>
        </Col>
        <Col span={4}>
          <Item
            label="Seats"
            name="seats"
            rules={[
              {
                required: true,
                message: 'Enter the amount of seats',
              },
            ]}
          >
            <InputNumber
              max={10}
              min={0}
              style={{ width: '100%' }}
              placeholder="Seats Amount"
            />
          </Item>
        </Col>
        <Col span={4}>
          <Item
            label="Small Baggage"
            name="small_baggage_size"
            rules={[
              {
                required: true,
                message: 'Enter the amount of small baggage',
              },
            ]}
            tooltip="Small Baggage is the amount of small baggage that can be stored in the car"
          >
            <InputNumber
              max={5}
              min={0}
              style={{ width: '100%' }}
              placeholder="Small baggage Amount"
            />
          </Item>
        </Col>
        <Col span={4}>
          <Item
            label="Large Baggage"
            name="large_baggage_size"
            rules={[
              {
                required: true,
                message: 'Enter the amount of large baggage',
              },
            ]}
            tooltip="Large Baggage is the amount of large baggage that can be stored in the car"
          >
            <InputNumber
              max={5}
              min={0}
              style={{ width: '100%' }}
              placeholder="Large baggage Amount"
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={30} wrap justify="center">
        <Col span={6}>
          <Item
            label="Price per day"
            name="price_per_day"
            rules={[
              {
                required: true,
                message: 'Please enter the price per day',
              },
            ]}
          >
            <InputNumber
              placeholder="Price in QAR"
              min={0}
              addonAfter="QAR"
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Item>
        </Col>
        <Col span={6}>
          <Item label="Price per week" name="price_per_week">
            <InputNumber
              placeholder="Price in QAR"
              min={0}
              addonAfter="QAR"
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Item>
        </Col>
        <Col span={6}>
          <Item label="Price per month" name="price_per_month">
            <InputNumber
              placeholder="Price in QAR"
              min={0}
              addonAfter="QAR"
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Item>
        </Col>
        <Col span={6}>
          <Item label="Price per year" name="price_per_year">
            <InputNumber
              placeholder="Price in QAR"
              min={0}
              addonAfter="QAR"
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={30} wrap justify="center">
        <Col span={10}>
          <Item
            label="Locations"
            name="locations"
            rules={[
              {
                required: true,
                message: 'Please select the locations of the car',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a Location"
              optionFilterProp="children"
              loading={isLoadingLocations}
              disabled={isLoadingLocations}
              mode="multiple"
              maxTagCount={'responsive'}
              allowClear
            >
              {locations?.map((location: ILocation) => (
                <Select.Option key={location.id} value={location.id}>
                  {location.address}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={7}>
          <Item label="Mobile Number" name="mobile_number">
            <InputNumber
              placeholder="Mobile Number"
              addonBefore="+974"
              minLength={3}
              maxLength={17}
              min={0}
              controls={false}
            />
          </Item>
        </Col>
        <Col span={7}>
          <Item label="Whatsapp Number" name="whatsapp_number">
            <InputNumber
              placeholder="Whatsapp Number"
              addonBefore="+974"
              minLength={3}
              maxLength={17}
              min={0}
              controls={false}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please enter the description of the car',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Description of the car"
              rows={4}
              maxLength={600}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic description"
            name="arabic_description"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic description of the car',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Arabic description of the car"
              rows={4}
              maxLength={600}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={4}>
          <FileUpload
            label="Cover Image"
            maxNbFiles={1}
            name="cover_image"
            fileList={fileListCover}
            setFileList={setFileListCover}
            customRequest={props =>
              imageUpload({ ...props, data: { type: 'cover' } })
            }
            onRemove={removeImage}
            requiredMessage="Please upload a cover image for the rental car"
          />
        </Col>
        <Col span={18}>
          <FileUpload
            label="Images"
            maxNbFiles={8}
            name="images"
            fileList={fileList}
            setFileList={setFileList}
            customRequest={props =>
              imageUpload({ ...props, data: { type: 'image' } })
            }
            onRemove={removeImage}
            requiredMessage="Please upload images for the rental car"
          />
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button danger onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isImageUploading}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
