import React from 'react';
import { RentalLeadsDetail } from '../../../models/overview/RentalLead';
import { Modal, message } from 'antd';
import { queryClient } from '../../../App';
import AddEditRentalLeadForm from './AddEditRentalLeadForm';
import {
  useCreateRentalLead,
  useEditRentalLead,
} from '../hooks/useRentalLeads';

interface RentalLeadModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing?: (value: boolean) => void;
  currentLead: RentalLeadsDetail | null;
  setCurrentLead?: (value: RentalLeadsDetail | null) => void;
}

const RentalLeadModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentLead,
  setCurrentLead,
}: RentalLeadModalProps) => {
  const { mutateAsync: createLead, isLoading: isCreating } =
    useCreateRentalLead();
  const { mutateAsync: updateLead, isLoading: isUpdating } =
    useEditRentalLead();

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing && setIsEditing(false);
    setCurrentLead && setCurrentLead(null);
  };

  const _create = async (lead: any) => {
    if (lead == null) return;
    try {
      const response = await createLead({
        name: lead.name,
        email: lead.email,
        mobile_number: `+974${lead.mobile_number?.toString()}`,
        dealer_id: lead.dealer_id,
        brand_id: lead.brand_id,
        model_id: lead.model_id,
        source: lead.source,
        contacted: lead.contacted,
        notes: lead.notes,
      });
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['rentalLeads'],
      });
      queryClient.invalidateQueries({
        queryKey: ['rentalLeadsDetails'],
      });
      message.success('Rental lead created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (lead: any) => {
    try {
      const response = await updateLead({
        id: currentLead!.id,
        data: {
          name: lead.name,
          email: lead.email,
          mobile_number: `+974${lead.mobile_number?.toString()}`,
          source: lead.source,
          contacted: lead.contacted,
          notes: lead.notes,
        },
      });
      setIsFormOpen(false);
      setCurrentLead && setCurrentLead(null);
      queryClient.invalidateQueries({
        queryKey: ['rentalLeads'],
      });
      queryClient.invalidateQueries({
        queryKey: ['rentalLeadsDetails'],
      });
      message.success('Rental lead updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Rental Lead' : 'Add Rental Lead'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={closeForm}
        footer={null}
        width={1200}
        key={currentLead?.id}
      >
        <AddEditRentalLeadForm
          lead={currentLead}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={closeForm}
        />
      </Modal>
    </>
  );
};

export default RentalLeadModal;
