import TrimsLayout from '../../../resources/cars/car_trims/components/TrimsLayout';
import { RouteType } from '../../routes/routesConfig';

const Trims = () => {
  return <TrimsLayout />;
};

const trimsRoute: RouteType = {
  element: <Trims />,
  state: 'trims',
  index: true,
  path: '/resources/cars/trims',
  sideBarProps: {
    displayText: 'Trims',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Trims, trimsRoute };
