import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikesOptionalFeaturesPayload,
  activateBikeOptionalFeature,
  createBikeOptionalFeature,
  deactivateBikeOptionalFeature,
  deleteBikeOptionalFeature,
  getBikeOptionalFeatures,
  updateBikeOptionalFeature,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_BIKE_OPTIONAL_FEATURES_QUERY_KEY = ['allBikeFeatures'];

interface IUseBikeOptionalFeatures {
  enabled: boolean;
  active?: boolean;
}

export const useBikeOptionalFeatures = ({
  enabled,
  active,
}: IUseBikeOptionalFeatures) => {
  return useQuery({
    queryKey: ALL_BIKE_OPTIONAL_FEATURES_QUERY_KEY,
    queryFn: () => getBikeOptionalFeatures({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeOptionalFeature = () => {
  return useMutation({
    mutationFn: (data: IBikesOptionalFeaturesPayload) =>
      createBikeOptionalFeature(data),
    mutationKey: ['createBikeOptionalFeature'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};

export const useUpdateBikeOptionalFeature = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: IBikesOptionalFeaturesPayload;
    }) => updateBikeOptionalFeature(id, data),
    mutationKey: ['updateBikeOptionalFeature'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};

export const useDeleteBikeOptionalFeature = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeOptionalFeature(id),
    mutationKey: ['deleteBikeOptionalFeature'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};

export const useActivateBikeOptionalFeature = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeOptionalFeature(id),
    mutationKey: ['activateBikeOptionalFeature'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};

export const useDeactivateBikeOptionalFeature = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeOptionalFeature(id),
    mutationKey: ['deactivateBikeOptionalFeature'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_OPTIONAL_FEATURES_QUERY_KEY);
    },
  });
};
