import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICarInteriorColorPayload,
  activateCarInteriorColor,
  createCarInteriorColor,
  deactivateCarInteriorColor,
  deleteCarInteriorColor,
  getCarInteriorColors,
  updateCarInteriorColor,
} from '../carInteriorColorApi';
import { queryClient } from '../../../../App';

export const CAR_INTERIOR_COLOR_QUERY_KEY = ['allInteriorColors'];

interface IUseCarInteriorColor {
  enabled: boolean;
  active?: boolean;
}

interface ICarInteriorColorFormPayload {
  name: string;
  arabicName: string;
  status: number;
}

export const useCarInteriorColor = ({
  enabled,
  active,
}: IUseCarInteriorColor) => {
  return useQuery({
    queryKey: CAR_INTERIOR_COLOR_QUERY_KEY,
    queryFn: () => getCarInteriorColors({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCarInteriorColor = () => {
  return useMutation({
    mutationFn: (data: ICarInteriorColorPayload) =>
      createCarInteriorColor(data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_INTERIOR_COLOR_QUERY_KEY);
    },
  });
};

export const useUpdateCarInteriorColor = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: ICarInteriorColorPayload;
    }) => updateCarInteriorColor(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_INTERIOR_COLOR_QUERY_KEY);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });
};

export const useDeleteCarInteriorColor = () => {
  return useMutation({
    mutationFn: (id: number) => deleteCarInteriorColor(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_INTERIOR_COLOR_QUERY_KEY);
    },
  });
};

export const useActivateCarInteriorColor = () => {
  return useMutation({
    mutationFn: (id: number) => activateCarInteriorColor(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_INTERIOR_COLOR_QUERY_KEY);
    },
  });
};

export const useDeactivateCarInteriorColor = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateCarInteriorColor(id),
    onSuccess: () => {
      queryClient.invalidateQueries(CAR_INTERIOR_COLOR_QUERY_KEY);
    },
  });
};
