import { useEffect } from 'react';
import { RouteType } from '../../routes/routesConfig';
import { LocationsLayout } from '../../../resources/cars/rental_locations/components/LocationsLayout';

const Locations = () => {
  return <LocationsLayout />;
};

const locationsRoute: RouteType = {
  element: <Locations />,
  state: 'locations',
  index: true,
  path: '/resources/cars/locations',
  sideBarProps: {
    displayText: 'Rental Locations',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Locations, locationsRoute };
