import { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Button,
  UploadFile,
  Modal,
  Image,
  Tabs,
  Divider,
} from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { IBank } from '../../models/banks/Bank';
import { IBankLogoUpload } from './BanksModal';
import { ICountry } from '../../models/countries/Country';
import { Option } from '../../components/inputs/DropDown';
import { ICity } from '../../models/countries/City';
import { useCountries } from '../../countries/hooks/useCountries';
import FileUpload from '../../components/inputs/FileUpload';
import { useUploadBankLogo } from '../hooks/useBanks';
import { TabsProps } from 'antd/lib';
import { Language } from '../../helpers/helperFunctions';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

type AddEditBankFormProps = {
  bank: IBank | null;
  onSubmit: (formData: any) => void;
  isLoading: boolean;
  onClose: () => void;
  isEditing: boolean;
  setUploadedLogo: (value: IBankLogoUpload) => void;
};

export const AddEditBankForm = ({
  bank,
  onSubmit,
  isLoading,
  onClose,
  isEditing,
  setUploadedLogo,
}: AddEditBankFormProps) => {
  const [cityOptions, setCityOptions] = useState<Array<Option>>();
  const [openImage, setOpenImage] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>(
    bank?.logo
      ? [
          {
            uid: `${bank?.id}`,
            name: 'image',
            status: 'done',
            url: bank.logo,
          },
        ]
      : [],
  );

  const [form] = Form.useForm();
  const { Item, List } = Form;
  const { TextArea } = Input;
  const countryId = Form.useWatch('country_id', form);

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { mutateAsync: uploadBankLogo, isLoading: isLogoUploading } =
    useUploadBankLogo();

  const initialValues = bank
    ? {
        logo: bank?.logo
          ? [
              {
                uid: `${bank?.id}`,
                name: bank?.logo.split('/').pop() || '',
                status: 'done',
                url: bank.logo,
              },
            ]
          : [],
        country_id: countries?.find((country: ICountry) =>
          country.cities?.find(city => city.id === bank.city_id),
        )?.id,
        city_id: bank.city_id,
        name: bank.name,
        arabic_name: bank.t.find(t => t.lang === 'ar')?.name,
        details: bank.details,
        arabic_details: bank.t.find(t => t.lang === 'ar')?.details,
        benefits: bank.bank_benefits.map(benefit => ({
          bank_benefit: benefit.benefit,
          arabic_bank_benefit: benefit.t.find(t => t.lang === 'ar')?.benefit,
        })),
      }
    : undefined;

  const imageUpload = ({
    onSuccess,
    onProgress,
    file,
  }: UploadRequestOption) => {
    uploadBankLogo(
      {
        file,
        path: 'bank-logo',
        onProgress: onProgress,
      },
      {
        onSuccess: (response: IBankLogoUpload) => {
          if (onSuccess) onSuccess('ok');
          setUploadedLogo(response);
        },
      },
    );
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find((country: ICountry) => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [bank, form, countries]);

  const bankTabs: TabsProps['items'] = [
    {
      key: Language.ar,
      label: Language.ar,
      forceRender: true,
      children: (
        <>
          <Row gutter={24} wrap>
            <Col span={24}>
              <List
                name="arabic_bank_benefits"
                rules={[
                  {
                    validator: async (_, arabic_bank_benefits) => {
                      if (
                        !arabic_bank_benefits ||
                        arabic_bank_benefits.length < 1
                      ) {
                        return Promise.reject(
                          new Error('At least 1 arabic benefit is required'),
                        );
                      }
                      if (
                        arabic_bank_benefits.length !==
                        form.getFieldValue('bank_benefits').length
                      ) {
                        return Promise.reject(
                          new Error(
                            'English and arabic benefits must have the same length',
                          ),
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Item
                        label={index === 0 ? 'Arabic Benefits' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please add a benefit.',
                            },
                          ]}
                          noStyle
                        >
                          <Input placeholder={`Benefit ${index + 1}`} />
                        </Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add arabic benefit
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </List>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={initialValues}
      >
        <Row
          gutter={16}
          style={{
            paddingTop: '1rem',
          }}
        >
          <Col span={24}>
            <Modal
              open={openImage}
              title="Image Preview"
              onCancel={() => setOpenImage(false)}
              footer={null}
            >
              <Image
                style={{ width: '100%' }}
                src={bank?.logo}
                preview={false}
              />
            </Modal>

            <Col span={24}>
              <FileUpload
                label="Logo"
                maxNbFiles={1}
                name="logo"
                fileList={fileList}
                setFileList={setFileList}
                customRequest={imageUpload}
                requiredMessage="Please upload a logo for the bank"
              />
            </Col>
          </Col>
        </Row>

        <Row gutter={16} wrap>
          <Col span={12}>
            <Item
              label="Country"
              name="country_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the country of the bank',
                },
              ]}
            >
              <Select
                disabled={!countries || isLoadingCountries}
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                onChange={() => {
                  form.setFieldsValue({
                    city_id: undefined,
                  });
                }}
                loading={isLoadingCountries}
                size="large"
              >
                {countries?.map((country: ICountry) => {
                  return (
                    <Select.Option key={country.id} value={country.id}>
                      {country.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="City"
              name="city_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the city of the bank',
                },
              ]}
            >
              <Select
                disabled={!countryId}
                showSearch
                placeholder="Select a city"
                optionFilterProp="children"
                size="large"
              >
                {cityOptions?.map((city: Option) => {
                  return (
                    <Select.Option key={city.code} value={city.code}>
                      {city.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={12}>
            <Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please enter the name' }]}
            >
              <Input placeholder="English Name" size="large" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Arabic Name"
              name="arabic_name"
              rules={[{ required: true, message: 'Please enter the name' }]}
            >
              <Input placeholder="Arabic Name" size="large" />
            </Item>
          </Col>
        </Row>

        <Row gutter={24} wrap>
          <Col span={12}>
            <Item
              label="Description"
              name="details"
              rules={[
                { required: true, message: 'Please enter the description' },
              ]}
            >
              <TextArea placeholder="Description" autoSize={{ minRows: 3 }} />
            </Item>
          </Col>

          <Col span={12}>
            <Item
              label="Arabic Description"
              name="arabic_details"
              rules={[
                {
                  required: true,
                  message: 'Please enter the arabic description',
                },
              ]}
            >
              <TextArea
                placeholder="Arabic Description"
                autoSize={{ minRows: 3 }}
              />
            </Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={24} wrap>
          <Col span={24}>
            <List name="benefits">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Item
                      label={index === 0 ? 'Benefits' : ''}
                      required={true}
                      key={key}
                    >
                      <Row gutter={24} align={'top'} wrap>
                        <Col span={11}>
                          <Item
                            {...restField}
                            name={[name, 'bank_benefit']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an english benefit',
                              },
                            ]}
                          >
                            <Input
                              placeholder={`English Benefit ${index + 1}`}
                            />
                          </Item>
                        </Col>
                        <Col span={11}>
                          <Item
                            {...restField}
                            name={[name, 'arabic_bank_benefit']}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an arabic benefit',
                              },
                            ]}
                          >
                            <Input
                              placeholder={`Arabic Benefit ${index + 1}`}
                            />
                          </Item>
                        </Col>
                        <Col
                          span={2}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: '0.7%',
                          }}
                        >
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            style={{ fontSize: '18px' }}
                          />
                        </Col>
                      </Row>
                    </Item>
                  ))}
                  <Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add benefit
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Item>
                </>
              )}
            </List>
          </Col>
        </Row>

        <Row
          style={{
            paddingTop: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Space>
            <Button onClick={onClose} danger>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLogoUploading}
            >
              {isEditing ? 'Edit Bank' : 'Add Bank'}
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};
