import { Modal } from 'antd';
import { CarExteriorColorForm } from './CarExteriorColorForm';
import { IUseCarExteriorColor } from '../utils/apiUtils';

interface CarExteriorColorModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarExteriorColor?: IUseCarExteriorColor;
  setSelectedCarExteriorColor: React.Dispatch<React.SetStateAction<any>>;
}

export const CarExteriorColorModal = ({
  isOpen,
  setIsOpen,
  selectedCarExteriorColor,
  setSelectedCarExteriorColor,
}: CarExteriorColorModalProps) => {
  const isEdit = !!selectedCarExteriorColor;

  const handleClose = () => {
    try {
      setIsOpen(false);
      setSelectedCarExteriorColor(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Exterior Color"
      open={isOpen}
      onCancel={handleClose}
      width={1200}
      footer={null}
      key={selectedCarExteriorColor?.id}
    >
      <CarExteriorColorForm
        handleClose={handleClose}
        selectedCarExteriorColor={selectedCarExteriorColor}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setSelectedCarExteriorColor={setSelectedCarExteriorColor}
      />
    </Modal>
  );
};
