import api from '../../../api/apiIndex';

const seatMaterialEndpointv2 = 'v2/admin/resources/cars/seat-materials';
export interface IGetSeatMaterials {
  active?: boolean;
}

export interface ICarSeatMaterialPayload {
  name: string;
  arabic_name: string;
  engine_types: Array<number>;
}

const getCarSeatMaterials = async ({ active }: IGetSeatMaterials) => {
  try {
    const response = await api.get(seatMaterialEndpointv2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createCarSeatMaterial = async (data: ICarSeatMaterialPayload) => {
  try {
    const response = await api.post(seatMaterialEndpointv2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateCarSeatMaterial = async (
  id: number,
  data: ICarSeatMaterialPayload,
) => {
  try {
    const response = await api.put(`${seatMaterialEndpointv2}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteCarSeatMaterial = async (id: number) => {
  try {
    const response = await api.delete(`${seatMaterialEndpointv2}/${id}`);
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCarSeatMaterial = async (id: number) => {
  try {
    const response = await api.put(`${seatMaterialEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCarSeatMaterial = async (id: number) => {
  try {
    const response = await api.put(
      `${seatMaterialEndpointv2}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getCarSeatMaterials,
  createCarSeatMaterial,
  updateCarSeatMaterial,
  deleteCarSeatMaterial,
};
