import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IEngineSizePayload,
  activateEngineSize,
  createEngineSize,
  deactivateEngineSize,
  deleteEngineSize,
  getEngineSizes,
  updateEngineSize,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_ENGINE_SIZES_KEY = ['allEngineSizes'];

interface IUseEngineSizes {
  enabled: boolean;
  active?: boolean;
}

export const useEngineSizes = ({ enabled, active }: IUseEngineSizes) => {
  return useQuery({
    queryKey: ALL_ENGINE_SIZES_KEY,
    queryFn: () => getEngineSizes({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useUpdateEngineSizes = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEngineSizePayload }) =>
      updateEngineSize(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_SIZES_KEY);
    },
  });
};

export const useCreateEngineSizes = () => {
  return useMutation({
    mutationFn: (data: IEngineSizePayload) => createEngineSize(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_SIZES_KEY);
    },
  });
};

export const useDeleteEngineSizes = () => {
  return useMutation({
    mutationFn: (id: number) => deleteEngineSize(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_SIZES_KEY);
    },
  });
};

export const useActivateEngineSizes = () => {
  return useMutation({
    mutationFn: (id: number) => activateEngineSize(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_SIZES_KEY);
    },
  });
};

export const useDeactivateEngineSizes = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateEngineSize(id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_ENGINE_SIZES_KEY);
    },
  });
};
