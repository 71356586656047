import { useMutation, useQuery } from '@tanstack/react-query';
import {
  attachLeadEmailToBrand,
  detachLeadEmailFromBrand,
  getDealerLeadEmailsForUsedCars,
  getLeadEmailsWithBrands,
  registerDealerLeadEmailsForUsedCars,
  unregisterDealerLeadEmailFromUsedCars,
} from '../apis';
import { queryClient } from '../../../App';

interface IBrandLeadEmailPayload {
  email: string;
  brand_id: number;
}

const ALL_LEAD_EMAILS_BRANDS_KEY = ['All_LEAD_EMAILS_BRANDS'];

const ALL_DEALER_LEAD_EMAILS_USED_CARS_KEY = [
  'All_DEALER_LEAD_EMAILS_USED_CARS',
];

export const useGetLeadEmailsWithBrands = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_LEAD_EMAILS_BRANDS_KEY,
    queryFn: () => getLeadEmailsWithBrands(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useAttachLeadEmailToBrand = () => {
  return useMutation({
    mutationFn: ({ email, brand_id }: IBrandLeadEmailPayload) =>
      attachLeadEmailToBrand(email, brand_id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_LEAD_EMAILS_BRANDS_KEY);
    },
  });
};

export const useDetachLeadEmailFromBrand = () => {
  return useMutation({
    mutationFn: ({ email, brand_id }: IBrandLeadEmailPayload) =>
      detachLeadEmailFromBrand(email, brand_id),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_LEAD_EMAILS_BRANDS_KEY);
    },
  });
};

export const useGetDealerLeadEmailsForUsedCars = () => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_DEALER_LEAD_EMAILS_USED_CARS_KEY,
    queryFn: () => getDealerLeadEmailsForUsedCars(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useRegisterDealerLeadEmailsForUsedCars = () => {
  return useMutation({
    mutationFn: registerDealerLeadEmailsForUsedCars,
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_DEALER_LEAD_EMAILS_USED_CARS_KEY);
    },
  });
};

export const useUnregisterDealerLeadEmailsFromUsedCars = () => {
  return useMutation({
    mutationFn: unregisterDealerLeadEmailFromUsedCars,
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_DEALER_LEAD_EMAILS_USED_CARS_KEY);
    },
  });
};
