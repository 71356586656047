import { UserGroups } from '../../../../common/constants';
import BikeTypesLayout from '../../../../resources/bikes/bike_types/components/BikeTypesLayout';
import { RouteType } from '../../../routes/routesConfig';

const BikeType = () => {
  return <BikeTypesLayout />;
};

const bikeTypesRoute: RouteType = {
  element: <BikeType />,
  state: 'types',
  index: true,
  path: '/resources/bikes/types',
  sideBarProps: {
    displayText: 'Types',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeType, bikeTypesRoute };
