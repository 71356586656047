import { Radio } from 'antd';

interface IPeriodRadioProps {
  setPeriod: (value: 'day' | 'month' | 'year') => void;
}

export const PeriodRadio = ({ setPeriod }: IPeriodRadioProps) => {
  return (
    <Radio.Group
      defaultValue="day"
      buttonStyle="solid"
      onChange={e => setPeriod(e.target.value)}
    >
      <Radio.Button value="day">Day</Radio.Button>
      <Radio.Button value="month">Month</Radio.Button>
      <Radio.Button value="year">Year</Radio.Button>
    </Radio.Group>
  );
};
