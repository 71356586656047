import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  Image,
  Input,
  Menu,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import { DownOutlined, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import { Layout } from '../../../../components/Layout';
import {
  useGetUsedBikes,
  useDeleteUsedBike,
  USED_BIKES_QUERY_KEY,
} from '../hooks';
import { Popconfirm, message } from 'antd/lib';
import { queryClient } from '../../../../App';
import useAuth from '../../../../hooks/useAuth';
import { UserGroups } from '../../../../common/constants';
import {
  BikeStatus,
  filterFeaturedOptions,
  filterStatusOptions,
} from '../../constants';
import { IUsedBike } from '../../../../models/listings/bikes/UsedBike';
import { batchDeleteUsedBikes, batchUpdateUsedBikesStatuses } from '../apis';
import { IBikeModel } from '../../../../models/resources/bikes/BikeModel';
import fallbackLogo from '../../../../assets/fallback.webp';
import { FilterDropDown } from '../../../../components/filters/FilterDropDown';
import { RejectUsedBikesModal } from './';
import { AddEditUsedBikeModal } from './AddEditUsedBikeModal';

const searchOptions: { label: string; value: SearchOptionKeys }[] = [
  { label: 'Model', value: 'modelName' },
  { label: 'User Email', value: 'userEmail' },
  { label: 'Dealer Name', value: 'dealerName' },
];

const placeholderOptions: Record<SearchOptionKeys, string> = {
  modelName: 'Search by Model Name',
  userEmail: 'Search by User Email',
  dealerName: 'Search by Dealer Name',
};

const { Search } = Input;

type SearchOptionKeys = 'modelName' | 'userEmail' | 'dealerName';

const UsedBikesLayout = () => {
  //#region states
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBike, setCurrentBike] = useState<IUsedBike | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [featuredFilter, setFeaturedFilter] = useState<number>(2);
  const [selectedBikes, setSelectedBikes] = useState<Array<IUsedBike>>([]);

  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);

  //Pagination states
  const [usedBikesPerPage, setUsedBikesPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);

  // Search states
  const searchValue = useRef<string>('');
  const [searchSelectedOption, setSearchSelectedOption] =
    useState<SearchOptionKeys>('modelName');
  const [searchQueryParams, setSearchQueryParams] = useState<
    Record<string, string>
  >({});

  const [availableBulkActions, setAvailableBulkActions] = useState<Array<any>>(
    [],
  );
  const [addUsedBikeDisabled, setAddUsedBikeDisabled] =
    useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IUsedBike | null>(
    null,
  );

  //#endregion

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isOneAutocar = userGroup === UserGroups.OAC;

  const { data: usedBikesPaginatedData, isLoading: isFetchingUsedBikes } =
    useGetUsedBikes({
      searchQueryParams,
      page: currentPage,
      perPage: usedBikesPerPage,
    });

  const { mutateAsync: deleteUsedBike, isLoading: isDeleting } =
    useDeleteUsedBike();

  //#region Functions

  const _deleteSelectedBikes = async () => {
    if (selectedBikes.length === 0) return;
    try {
      await batchDeleteUsedBikes(selectedBikes.map(car => car.id));
      message.success('Used Bikes are deleted successfully');
      queryClient.invalidateQueries(USED_BIKES_QUERY_KEY);
    } catch (error) {
      message.error('Error deleting used bikes');
      console.error(error);
    }
  };

  const _updateSelectedBikesStatus = async (
    status: BikeStatus,
    reason?: string,
  ) => {
    if (selectedBikes.length === 0) return;
    try {
      await batchUpdateUsedBikesStatuses(
        selectedBikes.map(car => car.id),
        status,
        reason,
      );
      clearSelection();
      queryClient.invalidateQueries(USED_BIKES_QUERY_KEY);
      message.success(`Used bikes are set as ${status} successfully`);
    } catch (error) {
      console.error(error);
    }
  };

  //#endregion
  const onEditClick = (car: IUsedBike) => {
    setCurrentBike(car);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setUsedBikesPerPage(pageSize);
  };

  const openEmptyForm = () => {
    setCurrentBike(null);
    setIsEditing(false);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentBike(null);
  };

  const closeRejectForm = () => {
    setIsRejectFormOpen(false);
  };

  const handleSelectChange = (value: any) => {
    setSearchSelectedOption(value);
  };

  const clearSelection = () => {
    setSelectedBikes([]);
  };

  const handleDelete = (id: number) => {
    deleteUsedBike(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: USED_BIKES_QUERY_KEY,
        });
        setOpenDeleteDialog(null);
        message.success('Used bike deleted successfully');
      },
      onError: () => {
        message.error('Error deleting used bike');
      },
    });
  };

  const handleSelectStatus = (value: any) => {
    setSelectedStatus(value);
    if (value === '') {
      delete searchQueryParams.status;
    } else if ('status' in searchQueryParams) {
      searchQueryParams.status = value;
    } else {
      searchQueryParams.status = value;
    }
  };

  const handleSelectFeatured = (value: any) => {
    setFeaturedFilter(value);
    if (value === '') {
      delete searchQueryParams.is_featured;
    } else if ('is_featured' in searchQueryParams) {
      searchQueryParams.is_featured = value;
    } else {
      searchQueryParams.is_featured = value;
    }
  };

  const bulkActionSetAsApproved = {
    label: 'Set As Approved',
    icon: 'pi pi-times',
    command: async () => {
      try {
        await _updateSelectedBikesStatus(BikeStatus.approved);
      } catch (error) {
        console.error(error);
        message.error('Error approving bikes');
      }
    },
  };

  const bulkActionSetAsPending = {
    label: 'Set As Pending',
    icon: 'pi pi-check',
    command: async () => {
      try {
        await _updateSelectedBikesStatus(BikeStatus.pending);
        clearSelection();
      } catch (error) {
        console.error(error);
        message.error('Error setting bikes as pending');
      }
    },
  };

  const bulkActionSetAsRejected = {
    label: 'Set As Rejected',
    icon: 'pi pi-check',
    command: async () => {
      try {
        setIsRejectFormOpen(true);
      } catch (error) {
        console.error(error);
        message.error('Error rejecting bikes');
      }
    },
  };

  const canBulkSetAsApproved = (): boolean => {
    return selectedBikes.some(bike => bike.status !== 'approved');
  };

  const canBulkSetAsPending = (): boolean => {
    return selectedBikes.some(bike => bike.status !== 'pending');
  };

  const canBulkSetAsRejected = () => {
    return selectedBikes.some(bike => bike.status !== 'rejected');
  };

  const tableColumns: ColumnsType<IUsedBike> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      fixed: 'left',
    },
    {
      title: 'Model',
      dataIndex: 'bike_model',
      key: 'bike_model',
      render: (rowData): string => {
        return rowData?.name;
      },
    },
    {
      title: 'Trim',
      dataIndex: 'bike_trim',
      key: 'bike_trim',
      render: (rowData: any): string => {
        return rowData?.name ?? 'N/A';
      },
    },
    {
      title: 'Image',
      dataIndex: 'images',
      key: 'images',
      width: 150,
      align: 'center',
      render: rowData => {
        return (
          <Image
            src={rowData[0]}
            height={50}
            fallback={fallbackLogo}
            alt="bike image"
            style={{
              objectFit: 'cover',
            }}
          />
        );
      },
    },
    {
      title: 'Brand',
      dataIndex: 'bike_model',
      key: 'bike_model',
      render: (rowData: IBikeModel): string => {
        return rowData?.brand.name;
      },
    },

    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: 70,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Dealer',
      dataIndex: 'bike_dealer',
      key: 'bike_dealer',
      ellipsis: true,
      render: (rowData): string => {
        return rowData?.name || 'Private';
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (rowData): string => {
        return rowData?.toString() + ' QR';
      },
    },
    {
      title: 'Featured',
      dataIndex: 'is_featured',
      key: 'is_featured',
      align: 'center',
      width: '50',
      fixed: 'right',
      render: (rowData): JSX.Element => {
        return (
          <Tag color={rowData ? 'green' : 'red'}>{rowData ? 'Yes' : 'No'}</Tag>
        );
      },
      filters: filterFeaturedOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={featuredFilter}
            setFilter={handleSelectFeatured}
            options={filterFeaturedOptions}
            isLoading={isFetchingUsedBikes}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      align: 'center',
      render: (rowData: string): JSX.Element => {
        const status = filterStatusOptions.find(
          status =>
            (status.value as string).toLowerCase() === rowData.toLowerCase(),
        );
        return <Tag color={status?.color}>{status?.text}</Tag>;
      },
      filters: filterStatusOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={selectedStatus}
            setFilter={handleSelectStatus}
            options={filterStatusOptions}
            isLoading={isFetchingUsedBikes}
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              style={{ fontSize: '1rem', color: 'var(--primary)' }}
              onClick={() => {
                onEditClick(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Deleting this bike will delete all their related data."
              open={openDeleteDialog === rowData}
              onConfirm={() => handleDelete(rowData.id)}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                style={{ fontSize: '1rem', color: 'var(--error)' }}
                onClick={() => {
                  setOpenDeleteDialog(rowData);
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const expandableColumn: ColumnsType<IUsedBike> = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: rowData => {
        return (
          <Typography.Text>
            {rowData?.replaceAll('&nbsp;', ' ')}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
    },
    {
      title: 'Whatsapp Number',
      dataIndex: 'whatsapp_number',
      key: 'whatsapp_number',
      render: (rowData: string) => rowData ?? 'N/A',
    },
    {
      title: 'KM',
      dataIndex: 'mileage',
      key: 'mileage',
      render: (rowData): string => {
        return rowData ? rowData?.toString() + ' Km' : 'N/A';
      },
    },
  ];

  useEffect(() => {
    const temp = [];
    if (canBulkSetAsApproved()) temp.push(bulkActionSetAsApproved);
    if (canBulkSetAsPending()) temp.push(bulkActionSetAsPending);
    if (canBulkSetAsRejected()) temp.push(bulkActionSetAsRejected);

    setAvailableBulkActions(temp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBikes, addUsedBikeDisabled]);

  useEffect(() => {
    setAddUsedBikeDisabled(selectedBikes.length > 0);
  }, [selectedBikes]);

  //#endregion
  return (
    <Layout>
      <div className="flex column-gap-4 justify-content-between align-items-center mb-5">
        <div className="flex column-gap-4 justify-content-between align-items-center">
          <Space.Compact>
            <Search
              placeholder={placeholderOptions[searchSelectedOption]}
              loading={isFetchingUsedBikes}
              onSearch={value =>
                setSearchQueryParams({
                  [searchSelectedOption]: searchValue.current,
                })
              }
              onChange={e => {
                searchValue.current = e.target.value;
                if (searchValue.current === '') setSearchQueryParams({});
              }}
              style={{ width: 200 }}
            />
            <Select
              options={searchOptions}
              defaultValue={searchOptions[0].value}
              onChange={value => handleSelectChange(value)}
              style={{ width: 130 }}
            />
          </Space.Compact>
        </div>
        <Space>
          {isOneAutocar && (
            <Dropdown
              overlay={
                <Menu>
                  {availableBulkActions.map((action, index) => (
                    <Menu.Item key={index} onClick={action.command}>
                      {action.label}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              disabled={selectedBikes.length === 0}
            >
              <Button type="primary">
                Change Status
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
          <div>
            <Button
              type="primary"
              loading={isFetchingUsedBikes}
              disabled={selectedBikes.length === 0}
              onClick={_deleteSelectedBikes}
              danger
            >
              Delete
            </Button>
          </div>
          <div>
            <Button type="primary" onClick={openEmptyForm}>
              Add
            </Button>
          </div>
        </Space>
      </div>

      <div className=" flex flex-column gap-6 justify-content-start h-full">
        <Table
          dataSource={usedBikesPaginatedData?.data}
          pagination={{
            position: ['bottomCenter'],
            total: usedBikesPaginatedData?.meta?.total,
            pageSize: usedBikesPerPage,
            onChange: onPageChange,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
          rowKey="id"
          scroll={{ x: 1300, y: 660 }}
          columns={tableColumns}
          loading={isFetchingUsedBikes}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedBikes(selectedRows as IUsedBike[]);
            },
            selectedRowKeys: selectedBikes.map(car => car.id),
          }}
          expandable={{
            expandedRowRender: record => {
              return (
                <Table
                  dataSource={[record]}
                  pagination={false}
                  rowKey="id"
                  columns={expandableColumn}
                />
              );
            },
            rowExpandable: record => record.description !== '',
          }}
        />
      </div>

      <AddEditUsedBikeModal
        key={currentBike?.id}
        car={currentBike}
        isEditing={isEditing}
        onClose={closeForm}
        isVisible={isFormOpen}
        setCurrentBike={setCurrentBike}
        setIsFormOpen={setIsFormOpen}
      />

      {isRejectFormOpen && (
        <RejectUsedBikesModal
          updateStatusFunction={_updateSelectedBikesStatus}
          onClose={closeRejectForm}
          isVisible={isRejectFormOpen}
        />
      )}
    </Layout>
  );
};

export default UsedBikesLayout;
