import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateUsedBikeLead,
  IEditUsedBikeLead,
  createUsedBikeLead,
  deleteUsedBikeLead,
  editUsedBikeLead,
  getUsedBikeLeads,
  getUsedBikeLeadsDetails,
} from '../apis';

interface IUseLeadsDetails {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

export const useUsedBikeLeads = () => {
  return useQuery({
    queryKey: ['usedBikeLeads'],
    queryFn: () => getUsedBikeLeads(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useUsedBikeLeadsDetail = ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: IUseLeadsDetails) => {
  return useQuery({
    queryKey: ['usedBikeLeadsDetails', dealerSlug, brandSlug, modelSlug],
    queryFn: () =>
      getUsedBikeLeadsDetails({
        dealerSlug,
        brandSlug,
        modelSlug,
      }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateUsedBikeLead = () => {
  return useMutation({
    mutationFn: (usedBikeLead: ICreateUsedBikeLead) =>
      createUsedBikeLead(usedBikeLead),
  });
};

export const useEditUsedBikeLead = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditUsedBikeLead }) =>
      editUsedBikeLead({ id, data }),
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteUsedBikeLead = () => {
  return useMutation({
    mutationFn: (id: number) => deleteUsedBikeLead(id),
  });
};
