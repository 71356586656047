import {
  Form,
  Input,
  Select,
  Space,
  Radio,
  Button,
  Row,
  Col,
  DatePicker,
  Spin,
  Switch,
  Divider,
  Typography,
  InputNumber,
  UploadFile,
} from 'antd';
import { Dispatch, SetStateAction, useEffect } from 'react';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, FlexCentered } from '../../../../components/Flex';
import useAuth from '../../../../hooks/useAuth';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useState } from 'react';
import FileUpload from '../../../../components/inputs/FileUpload';
import {
  CustomUploadFileStatus,
  IBikeModelAddedImages,
} from './AddEditUsedBikeModal';
import { uploadImage } from '../../../../common/utils';
import { UploadRequestError } from 'rc-upload/lib/interface';
import { AxiosError } from 'axios';
import { IUsedBike } from '../../../../models/listings/bikes/UsedBike';
import { UserGroups } from '../../../../common/constants';
import { useGetAllBikeBrands } from '../../../../resources/bikes/bike_brands/hooks';
import { useGetBikeModels } from '../../../../resources/bikes/bike_models/hooks';
import { useGetBikeTrims } from '../../../../resources/bikes/bike_trims/hooks';
import { useGetBikeConditions } from '../../../../resources/bikes/conditions';
import { useGetBikeEngineType } from '../../../../resources/bikes/engine_types';
import { useGetBikeTransmissions } from '../../../../resources/bikes/transmission';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { useGetBikeEngineSize } from '../../../../resources/bikes/engine_sizes';
import { useGetBikeCylinders } from '../../../../resources/bikes/cylinders';
import { useGetBikeBatteryRange } from '../../../../resources/bikes/battery_range';
import { useGetBikeBatteryCapacities } from '../../../../resources/bikes/battery_capacity';
import { useGetBikeColors } from '../../../../resources/bikes/colors';
import { useBikeOptionalFeatures } from '../../../../resources/bikes/optional_features';
import { BikeBrand } from '../../../../models/resources/bikes/BikeBrand';
import { IBikeModel } from '../../../../models/resources/bikes/BikeModel';
import { BikeStatus } from '../../constants';
import { CustomizedRequiredMark } from '../../../../components/misc/RequiredMark';
import { IBikeTrim } from '../../../../models/resources/bikes/BikeTrim';
import { adminBikeStatuses, dealerStatuses } from '../apis';
import { IBikeOptionalFeature } from '../../../../models/resources/bikes/BikeOptionalFeatures';
import { RejectUsedBikesModal } from './RejectUsedBikesModal';
import { IEngineType } from '../../../../models/resources/CarEngineType';
import { useCountries } from '../../../../countries/hooks/useCountries';
import { ICity } from '../../../../models/countries/City';
import { Option } from '../../../../components/inputs/DropDown';
import { ICarDealer } from '../../../../models/dealers/cars';
import { useGetAllDealers } from '../../../../dealers/Automotive/hooks';

type AddEditUsedBikeFormProps = {
  bike: IUsedBike | null;
  isEditing: boolean;
  isLoading: boolean;
  onClose: () => void;
  addedImages: any[];
  removedImages: any[];
  rejectReason?: string;
  setRejectReason: (reason: string) => void;
  setAddedImages: Dispatch<SetStateAction<IBikeModelAddedImages[]>>;
  setRemovedImages: (urls: Array<Record<string, string>>) => void;
  onSubmit: (formData: any) => Promise<void>;
};

const { Text } = Typography;
const { Item } = Form;

export const AddEditUsedBikeForm = ({
  bike,
  addedImages,
  removedImages,
  isEditing,
  isLoading,
  rejectReason,
  onClose,
  onSubmit,
  setAddedImages,
  setRemovedImages,
  setRejectReason,
}: AddEditUsedBikeFormProps) => {
  const initialImages =
    bike?.images?.map((image: any) => {
      return {
        uid: image.id,
        name: image.split('/').pop(),
        status: 'old',
        url: image,
      };
    }) || [];

  const [fileList, setFileList] = useState<UploadFile[]>(
    initialImages as UploadFile[],
  );
  const [isRejectFormOpen, setIsRejectFormOpen] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const [form] = Form.useForm();
  const brandId = Form.useWatch('brand_id', form);
  const modelId = Form.useWatch('bike_model_id', form);
  const engineTypeId = Form.useWatch('bike_engine_type_id', form);
  const sellerType = Form.useWatch('seller_type', form);
  const user = useAuth();
  const isOneAutocar = user?.userInfo?.group === UserGroups.OAC;

  //Apis

  const { data: brands, isLoading: isLoadingBrands } = useGetAllBikeBrands({
    active: true,
  });

  const { data: models, isLoading: isLoadingModels } = useGetBikeModels({
    enabled: isEditing || !!brandId,
    brandId: brandId,
    active: true,
  });

  const { data: trims, isLoading: isLoadingTrims } = useGetBikeTrims({
    enabled: isEditing || !!modelId,
    model_id: modelId,
    active: true,
  });

  const { data: conditions, isLoading: isLoadingConditions } =
    useGetBikeConditions({
      enabled: true,
      active: true,
    });

  const { data: engineTypes, isLoading: isLoadingEngineTypes } =
    useGetBikeEngineType({
      enabled: true,
      active: true,
    });
  const { data: transmissions, isLoading: isLoadingTransmissions } =
    useGetBikeTransmissions({
      enabled:
        engineTypes?.find(
          (engineType: IBikeEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric' && engineTypeId !== undefined,
      active: true,
    });
  const { data: engineSizes, isLoading: isLoadingEngineSizes } =
    useGetBikeEngineSize({
      enabled:
        engineTypes?.find(
          (engineType: IBikeEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric' && engineTypeId !== undefined,
      active: true,
    });
  const { data: cylinders, isLoading: isLoadingCylinders } =
    useGetBikeCylinders({
      enabled:
        engineTypes?.find(
          (engineType: IBikeEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric' && engineTypeId !== undefined,
      active: true,
    });
  const { data: batteryRanges, isLoading: isLoadingBatteryRange } =
    useGetBikeBatteryRange({
      enabled:
        engineTypes?.find(
          (engineType: IBikeEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric',
      active: true,
    });
  const { data: batteryCapacities, isLoading: isLoadingBatteryCapacity } =
    useGetBikeBatteryCapacities({
      enabled:
        engineTypes?.find(
          (engineType: IBikeEngineType) => engineType.id === engineTypeId,
        )?.slug !== 'electric',
      active: true,
    });
  const { data: colors, isLoading: isLoadingColors } = useGetBikeColors({
    enabled: true,
    active: true,
  });
  const { data: optionalFeatures, isLoading: isLoadingOptionalFeatures } =
    useBikeOptionalFeatures({
      enabled: true,
      active: true,
    });
  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { data: dealers, isLoading: isLoadingDealers } = useGetAllDealers({
    enabled: true,
    active: true,
  });

  const handleDealerSelect = (dealerId: ICarDealer) => {
    const selectedDealer = dealers.find(
      (dealer: any) => dealer.id === dealerId,
    )?.whatsapp_number;
    const formattedNumber =
      selectedDealer && selectedDealer.startsWith('974')
        ? selectedDealer.slice(3)
        : selectedDealer;
    form.setFieldValue('whatsapp_number', formattedNumber);
  };

  const fuelTypeSelected =
    engineTypes?.find((type: IEngineType) => type.slug === 'electric')?.id !==
      engineTypeId && engineTypeId !== undefined;
  const electricTypeSelected =
    engineTypes?.find((type: IEngineType) => type.slug === 'electric')?.id ===
    engineTypeId;

  const countryId = Form.useWatch('country_id', form);

  const initialValues = bike
    ? {
        brand_id: brands?.find(
          (brand: BikeBrand) =>
            brand.id ===
            models?.find(
              (model: IBikeModel) => model.id === bike?.bike_model_id,
            )?.brand_id,
        )?.id,
        bike_model_id: models?.find(
          (model: IBikeModel) => model.id === bike.bike_model_id,
        )?.id,
        bike_type_id: bike.bike_type_id,
        bike_trim_id: bike.bike_trim_id,
        year: dayjs().year(bike.year),
        bike_condition_id: bike.bike_condition_id,
        is_featured: bike.is_featured,
        bike_engine_type_id: bike.bike_engine_type_id || undefined,
        bike_transmission_id: bike.bike_transmission_id || undefined,
        bike_engine_size_id: bike.bike_engine_size_id || undefined,
        bike_cylinder_id: bike.bike_cylinder_id || undefined,
        bike_battery_capacity_id: bike.bike_battery_capacity_id || undefined,
        bike_battery_range_id: bike.bike_battery_range_id || undefined,
        bike_color_id: bike.bike_color_id || undefined,
        optional_features_ids: bike.optional_features
          ?.filter((feature: any) => {
            const correspondingFeature = optionalFeatures?.find(
              (bikeFeature: any) => bikeFeature.id === feature.id,
            );
            return correspondingFeature
              ? correspondingFeature.is_active
              : false;
          })
          ?.map((feature: any) => feature.id),
        country_id: countries?.find(country =>
          country.cities?.find(city => city.id === bike.city_id),
        )?.id,
        city_id: bike.city_id,
        status: bike.status,
        mobile_number:
          bike.mobile_number && bike.mobile_number.startsWith('974')
            ? bike.mobile_number.slice(3)
            : bike.mobile_number,
        whatsapp_number:
          bike.whatsapp_number && bike.whatsapp_number.startsWith('974')
            ? bike.whatsapp_number.slice(3)
            : bike.whatsapp_number,
        price: bike.price,
        mileage: bike.mileage,
        seller_type: isOneAutocar
          ? bike.bike_dealer_id
            ? 'dealer'
            : 'private'
          : '',
        seller_id: isOneAutocar
          ? dealers?.find(
              (dealer: ICarDealer) => dealer.id === bike?.bike_dealer_id,
            )?.id || bike.user_id
          : '',
        description: bike.description,
        images: initialImages as UploadFile[],
      }
    : undefined;

  //Functions

  const closeRejectForm = () => {
    setIsRejectFormOpen(false);
  };

  const handleReject = async (status: BikeStatus, reason: string) => {
    setRejectReason(reason);
  };

  const imageUpload = async ({
    onSuccess: onImageSuccess,
    onProgress,
    file,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'used-bike-image',
        onProgress: onProgress,
      });
      if (onImageSuccess) onImageSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedImages(prevImages => [...prevImages, addedImage]);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      const addedImage = { ...(response as any), status: 'error', file };
      setAddedImages(prevImages => [...prevImages, addedImage]);
    } finally {
      setIsUploadingImage(false);
    }
  };

  const removeImage = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedImages([
        ...removedImages,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedImages(addedImages.filter(image => image.file.uid !== file.uid));
    }
  };

  const handleStatusChange = (value: any) => {
    if (value === BikeStatus.rejected) {
      setIsRejectFormOpen(true);
    }
  };

  const resetForm = () => {
    form.resetFields();
  };

  //UseEffects
  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [bike, form, brands, conditions, optionalFeatures]);

  useEffect(() => {
    if (!brandId) {
      form.resetFields();
    }
  }, [models]);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values: any) => {
          onSubmit(values).then(res => {
            if (res !== undefined) {
              resetForm();
              setFileList([]);
              setAddedImages([]);
              setRemovedImages([]);
            }
          });
        }}
        initialValues={initialValues}
        requiredMark={CustomizedRequiredMark}
      >
        {isOneAutocar && (
          <>
            <Row gutter={16} wrap>
              <Col span={6}>
                <Flex>
                  <Space wrap>
                    <Item
                      name="seller_type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select the seller type',
                        },
                      ]}
                      initialValue={'dealer'}
                    >
                      <Radio.Group
                        style={{
                          width: '100%',
                        }}
                      >
                        <Radio value="dealer">Dealer</Radio>
                        <Radio value="private">Private</Radio>
                      </Radio.Group>
                    </Item>
                  </Space>
                </Flex>
              </Col>
              <Col span={12}>
                <Item
                  name="seller_id"
                  rules={[
                    {
                      required: true,
                      message:
                        sellerType === 'dealer'
                          ? 'Select a dealer'
                          : 'Enter the seller id',
                    },
                  ]}
                >
                  {sellerType === 'dealer' ? (
                    <Select
                      showSearch
                      placeholder="Select a dealer"
                      optionFilterProp="children"
                      loading={isLoadingDealers}
                      disabled={isLoadingDealers}
                      onChange={handleDealerSelect}
                    >
                      {dealers?.map((dealerName: any) => (
                        <Select.Option
                          key={dealerName.id}
                          value={dealerName.id}
                        >
                          {dealerName.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Input placeholder="Seller Id" />
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Flex>
                  <Space wrap align="start">
                    <div
                      style={{
                        paddingTop: '5px',
                      }}
                    >
                      <Text>Featured</Text>
                    </div>
                    <Item name="is_featured" valuePropName="checked">
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        title="Featured"
                        checked={bike?.is_featured ?? false}
                      />
                    </Item>
                  </Space>
                </Flex>
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <Row gutter={16} wrap>
          <Col span={6}>
            <Item
              label="Brand"
              name="brand_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the brand of the bike',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a brand"
                optionFilterProp="children"
                loading={isLoadingBrands}
                disabled={isLoadingBrands}
                onChange={() => {
                  form.setFieldsValue({
                    bike_model_id: undefined,
                  });
                }}
              >
                {brands?.map((brand: BikeBrand) => (
                  <Select.Option key={brand.id} value={brand.id}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Model"
              name="bike_model_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the model of the bike',
                },
              ]}
              dependencies={['brand_id']}
            >
              <Select
                showSearch
                placeholder="Select a model"
                optionFilterProp="children"
                loading={isLoadingModels}
                disabled={isLoadingModels || !brandId}
                onChange={() => {
                  form.setFieldsValue({
                    bike_type_id: undefined,
                    bike_trim_id: undefined,
                  });
                }}
              >
                {models?.map((model: IBikeModel) => (
                  <Select.Option key={model.id} value={model.id}>
                    {model.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Type"
              name="bike_type_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the type of the bike',
                },
              ]}
              dependencies={['bike_model_id']}
            >
              <Select
                showSearch
                placeholder="Select a type"
                optionFilterProp="children"
                disabled={!modelId}
              >
                {models
                  ?.find((model: IBikeModel) => model.id === modelId)
                  ?.model_type.map((type: any) => {
                    return (
                      <Select.Option key={type.types.id} value={type.types.id}>
                        {type.types.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Trim"
              name="bike_trim_id"
              dependencies={['bike_model_id']}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select a trim"
                optionFilterProp="children"
                loading={isLoadingTrims}
                disabled={isLoadingTrims || !modelId}
              >
                {trims?.map((trim: IBikeTrim) => (
                  <Select.Option key={trim.id} value={trim.id}>
                    {trim.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={8}>
            <Item
              label="Condition"
              name="bike_condition_id"
              rules={[
                {
                  required: true,
                  message: 'Select Condition',
                },
              ]}
            >
              <Radio.Group
                disabled={isLoadingConditions}
                style={{
                  width: '100%',
                }}
              >
                {conditions ? (
                  conditions.map((condition: any) => (
                    <Radio key={condition.id} value={condition.id}>
                      {condition.name}
                    </Radio>
                  ))
                ) : (
                  <FlexCentered>
                    <Spin tip="Loading..." indicator={<LoadingOutlined />}>
                      <div
                        style={{
                          padding: '0 50px',
                          borderRadius: '4px',
                        }}
                      />
                    </Spin>
                  </FlexCentered>
                )}
              </Radio.Group>
            </Item>
          </Col>
          <Col span={4}>
            <Item
              label="Year"
              name="year"
              rules={[
                {
                  required: true,
                  message: 'Select the year of the bike',
                },
              ]}
            >
              <DatePicker
                placeholder="Select a year"
                picker="year"
                disabledDate={current => {
                  return current.year() > dayjs().year() + 1;
                }}
                style={{
                  width: '100%',
                }}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: 'Select Status',
                },
              ]}
              tooltip={rejectReason}
            >
              <Select
                showSearch
                placeholder="Select a status"
                optionFilterProp="children"
                onSelect={handleStatusChange}
              >
                {isOneAutocar
                  ? adminBikeStatuses
                      .filter(
                        status =>
                          status.value !== '' &&
                          status.value !== BikeStatus.draft &&
                          (!isEditing
                            ? status.value !== BikeStatus.rejected
                            : true),
                      )
                      .map((status: any) => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.label}
                        </Select.Option>
                      ))
                  : dealerStatuses.map((status: any) => (
                      <Select.Option key={status.value} value={status.value}>
                        {status.label}
                      </Select.Option>
                    ))}
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Color"
              name="bike_color_id"
              rules={[
                {
                  required: true,
                  message: 'Select Color',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a color"
                optionFilterProp="children"
                loading={isLoadingColors}
                disabled={isLoadingColors}
              >
                {colors?.map((color: any) => (
                  <Select.Option key={color.id} value={color.id}>
                    {color.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={8}>
            <Item
              label="Engine Type"
              name="bike_engine_type_id"
              rules={[
                {
                  required: true,
                  message: 'Select Engine Type',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select an engine type"
                optionFilterProp="children"
                loading={isLoadingEngineTypes}
                disabled={isLoadingEngineTypes}
              >
                {engineTypes?.map((engineType: any) => (
                  <Select.Option
                    key={engineType.id}
                    value={engineType.id}
                    id={engineType.id}
                  >
                    {engineType.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          {fuelTypeSelected && (
            <Col span={8}>
              <Item label="Transmission" name="bike_transmission_id">
                <Select
                  showSearch
                  placeholder="Select a transmission"
                  optionFilterProp="children"
                  loading={isLoadingTransmissions}
                  disabled={isLoadingTransmissions}
                >
                  {transmissions?.map((transmission: any) => (
                    <Select.Option
                      key={transmission.id}
                      value={transmission.id}
                    >
                      {transmission.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {electricTypeSelected && (
            <Col span={8}>
              <Item label="Battery Capacity" name="bike_battery_capacity_id">
                <Select
                  showSearch
                  placeholder="Select a battery capacity"
                  optionFilterProp="children"
                  loading={isLoadingBatteryCapacity}
                  disabled={isLoadingBatteryCapacity}
                >
                  {batteryCapacities?.map((capacity: any) => (
                    <Select.Option key={capacity.id} value={capacity.id}>
                      {capacity.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {electricTypeSelected && (
            <Col span={8}>
              <Item label="Battery Range" name="bike_battery_range_id">
                <Select
                  showSearch
                  placeholder="Select a battery range"
                  optionFilterProp="children"
                  loading={isLoadingBatteryRange}
                  disabled={isLoadingBatteryRange}
                >
                  {batteryRanges?.map((range: any) => (
                    <Select.Option key={range.id} value={range.id}>
                      {range.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {fuelTypeSelected && (
            <Col span={4}>
              <Item label="Engine Size" name="bike_engine_size_id">
                <Select
                  showSearch
                  placeholder="Select an engine size"
                  optionFilterProp="children"
                  loading={isLoadingEngineSizes}
                  disabled={isLoadingEngineSizes}
                >
                  {engineSizes?.map((engineSize: any) => (
                    <Select.Option key={engineSize.id} value={engineSize.id}>
                      {engineSize.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
          {fuelTypeSelected && (
            <Col span={4}>
              <Item label="Cylinders" name="bike_cylinder_id">
                <Select
                  showSearch
                  placeholder="Select a cylinder count"
                  optionFilterProp="children"
                  loading={isLoadingCylinders}
                  disabled={isLoadingCylinders}
                >
                  {cylinders?.map((cylinder: any) => (
                    <Select.Option key={cylinder.id} value={cylinder.id}>
                      {cylinder.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          )}
        </Row>
        <Row gutter={16} wrap>
          <Col span={24}>
            <Item label="Features" name="optional_features_ids">
              <Select
                showSearch
                placeholder="Select the bike features (Optional) / You can select multiple features"
                loading={isLoadingOptionalFeatures}
                disabled={isLoadingOptionalFeatures}
                mode="multiple"
                optionFilterProp="children"
                allowClear
              >
                {optionalFeatures?.map((feature: IBikeOptionalFeature) => (
                  <Select.Option key={feature.id} value={feature.id}>
                    {feature.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={12}>
            <Item
              label="Country"
              name="country_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the country of the banner',
                },
              ]}
            >
              <Select
                disabled={isLoadingCountries}
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                onChange={() => {
                  form.setFieldsValue({
                    city_id: undefined,
                  });
                }}
              >
                {countries?.map(country => {
                  return (
                    <Select.Option key={country.id} value={country.id}>
                      {country.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="City"
              name="city_id"
              rules={[
                {
                  required: true,
                  message: 'Please select the city of the banner',
                },
              ]}
            >
              <Select
                disabled={!countryId}
                showSearch
                placeholder="Select a city"
                optionFilterProp="children"
              >
                {cityOptions?.map((city: Option) => {
                  return (
                    <Select.Option key={city.code} value={city.code}>
                      {city.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap justify={'center'}>
          <Col span={6}>
            <Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Please enter the price of the bike',
                },
              ]}
            >
              <InputNumber
                placeholder="Price of the bike"
                min={0}
                addonAfter="QAR"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item
              label="Odometer"
              name="mileage"
              rules={[
                {
                  required: true,
                  message: 'Please enter the price of the bike',
                },
              ]}
            >
              <InputNumber
                placeholder="Odometer reading"
                min={0}
                addonAfter="KM"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Mobile Number" name="mobile_number">
              <InputNumber
                placeholder="Mobile number of the seller"
                addonBefore="+974"
                minLength={3}
                maxLength={17}
                min={0}
                controls={false}
              />
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Whatsapp Number" name="whatsapp_number">
              <InputNumber
                placeholder="Whatsapp number of the seller"
                addonBefore="+974"
                minLength={3}
                maxLength={17}
                min={0}
                controls={false}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={24}>
            <Item label="Description" name="description">
              <Input.TextArea
                placeholder="Description of the bike"
                rows={4}
                maxLength={600}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={16} wrap>
          <Col span={24}>
            <FileUpload
              label="Images"
              maxNbFiles={8}
              name="images"
              fileList={fileList}
              setFileList={setFileList}
              customRequest={imageUpload}
              onRemove={removeImage}
              requiredMessage="Please upload at least one image of the bike."
            />
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Space>
            <Button onClick={onClose} danger>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={
                isUploadingImage ||
                addedImages.filter(img => img.status !== 'done').length > 0
              }
            >
              {isEditing ? 'Edit' : 'Create'}
            </Button>
          </Space>
        </Row>
      </Form>
      {isRejectFormOpen && (
        <RejectUsedBikesModal
          updateStatusFunction={handleReject}
          onClose={closeRejectForm}
          isVisible={isRejectFormOpen}
          initialReason={rejectReason}
        />
      )}
    </>
  );
};
