import { Form, Row, Col, Input, Space, Button } from 'antd';
import { ICarType } from '../../../../models/resources/CarType';

const { Item } = Form;

type ICarTypeFormProps = {
  resourceType: ICarType | null;
  isEditing: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (type: ICarType) => void;
  isSubmitting: boolean;
};

const CarTypesEditAddDeleteForm = ({
  resourceType,
  isEditing,
  onClose,
  onSubmit,
  isSubmitting,
}: ICarTypeFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: resourceType?.name,
    arabic_name: resourceType?.t?.[0]?.name,
  };

  const resetForm = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a name' }]}
          >
            <Input placeholder="Name" size="large" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[{ required: true, message: 'Please enter an arabic name' }]}
          >
            <Input placeholder="Arabic Name" size="large" />
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button onClick={onClose} danger>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {isEditing ? 'Edit type' : 'Add type'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default CarTypesEditAddDeleteForm;
