import { useMutation, useQuery } from '@tanstack/react-query';
import { IBanner } from '../../../models/ads/Banner';
import {
  IBannerParams,
  UpdateBannerRequest,
  activateBanner,
  createBanner,
  deactivateBanner,
  deleteBanner,
  deleteManyBanners,
  getBanners,
  updateBanner,
} from '../bannersApi';
import { TUploadImage, uploadImage } from '../../../common/utils';

export const ALL_BANNERS_KEY = ['ALL_BANNERS'];

export const useGetBanners = ({ enabled, filter }: IBannerParams) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['ALL_BANNERS', filter],
    queryFn: (): Promise<IBanner[]> => getBanners({ filter }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBanner = () => {
  return useMutation({
    mutationFn: createBanner,
  });
};

export const useUpdateBanner = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateBannerRequest }) =>
      updateBanner(id, data),
  });
};

export const useDeleteBanner = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBanner(id),
  });
};

export const useDeleteManyBanners = () => {
  return useMutation({
    mutationFn: (ids: number[]) => deleteManyBanners(ids),
  });
};

export const useActivateBanner = () => {
  return useMutation({
    mutationFn: (id: number) => activateBanner(id),
  });
};

export const useDeactivateBanner = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBanner(id),
  });
};

export const useUploadBannerImage = () => {
  return useMutation({
    mutationFn: ({ file, path, extraBodyData, onProgress }: TUploadImage) =>
      uploadImage({ file, path, extraBodyData, onProgress }),
  });
};
