import { Modal, message } from 'antd';
import AddEditUsedCarLeadForm from './AddEditUsedCarLeadForm';
import { UsedCarLeadsDetail } from '../../../models/overview/UsedCarLead';
import { queryClient } from '../../../App';
import { useCreateUsedCarLead } from '../hooks/useUsedCarLeads';

interface UsedCarLeadModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  currentLead: UsedCarLeadsDetail | null;
  setCurrentLead?: (value: UsedCarLeadsDetail | null) => void;
}

const UsedCarLeadModal = ({
  isFormOpen,
  setIsFormOpen,
  currentLead,
  setCurrentLead,
}: UsedCarLeadModalProps) => {
  const { mutateAsync: createLead, isLoading: isCreating } =
    useCreateUsedCarLead();

  const closeForm = () => {
    setIsFormOpen(false);
    setCurrentLead && setCurrentLead(null);
  };

  const _create = async (lead: any) => {
    if (lead == null) return;
    try {
      const response = await createLead({
        name: lead.name,
        email: lead.email,
        mobile_number: `+974${lead.mobile_number?.toString()}`,
        used_car_id: lead.used_car_id,
        source: lead.source,
        contacted: lead.contacted,
        notes: lead.notes,
      });
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['usedCarLeads'],
      });
      queryClient.invalidateQueries({
        queryKey: ['usedCarLeadsDetails'],
      });
      message.success('Used car lead created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <>
      <Modal
        title={'Add Used Car Lead'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={closeForm}
        footer={null}
        width={1200}
        key={currentLead?.id}
      >
        <AddEditUsedCarLeadForm
          lead={currentLead}
          onSubmit={_create}
          isLoading={isCreating}
          onClose={closeForm}
        />
      </Modal>
    </>
  );
};

export default UsedCarLeadModal;
