import api from '../../../../api/apiIndex';

const bikeColorEndpoint = 'v2/admin/resources/bikes/colors';

export interface IGetBikeColors {
  active?: boolean;
}

export interface IBikeColorPayload {
  name: string;
  arabic_name: string;
}

export const getBikeColors = async ({ active }: IGetBikeColors) => {
  try {
    const response = await api.get(bikeColorEndpoint, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeColor = async (data: IBikeColorPayload) => {
  const response = await api.post(bikeColorEndpoint, data);
  return response.data;
};

export const updateBikeColor = async (id: number, data: IBikeColorPayload) => {
  const response = await api.put(`${bikeColorEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeColor = async (id: number) => {
  try {
    const response = await api.delete(`${bikeColorEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeColor = async (id: number) => {
  try {
    const response = await api.put(`${bikeColorEndpoint}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeColor = async (id: number) => {
  try {
    const response = await api.put(`${bikeColorEndpoint}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
