export const Flex = ({ children, ...props }) => {
  return (
    <div
      className={props.className}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: props.justify,
        alignItems: props.align,
        ...props.style,
      }}
    >
      {children}
    </div>
  );
};

export const FlexCentered = ({ children, ...props }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: props.justifyContent || 'center',
        alignItems: props.alignItems || 'center',
        ...props.style,
      }}
    >
      {children}
    </div>
  );
};

export const FlexColumn = ({ children, ...props }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: props.justifyContent,
        alignItems: props.alignItems,
        ...props.style,
      }}
    >
      {children}
    </div>
  );
};
