import api from '../../../api/apiIndex';
import { downloadFile } from '../../../helpers/helperFunctions';
import { LeadContacted } from '../../constants';

const usedCarLeadsEndpoint = '/v2/admin/inquiries/used-car-inquiries';

interface ILeadsDetailsProps {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

interface IExportUsedCarLeads {
  setIsExporting: (newValue: boolean) => void;
  from: string | null;
  to: string | null;
}

export interface ICreateUsedCarLead {
  name: string;
  email: string;
  mobile_number: string;
  used_car_id: number;
  source: string;
  contacted: LeadContacted;
  notes?: string;
}

export interface IEditUsedCarLead {
  name?: string;
  email?: string;
  mobile_number?: string;
  source?: string;
  contacted?: LeadContacted;
  notes?: string;
}

export const getUsedCarLeads = async () => {
  const response = await api.get(usedCarLeadsEndpoint);
  return response.data;
};

export const getUsedCarLeadsDetails = async ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: ILeadsDetailsProps) => {
  if (dealerSlug && brandSlug && modelSlug) {
    const response = await api.get(
      `${usedCarLeadsEndpoint}/${dealerSlug}/${brandSlug}/${modelSlug}`,
    );
    return response.data;
  } else if (dealerSlug && brandSlug) {
    const response = await api.get(
      `${usedCarLeadsEndpoint}/${dealerSlug}/${brandSlug}`,
    );
    return response.data;
  } else if (dealerSlug) {
    const response = await api.get(`${usedCarLeadsEndpoint}/${dealerSlug}`);
    return response.data;
  }
};

export const exportUsedCarLeads = async ({
  setIsExporting,
  from,
  to,
}: IExportUsedCarLeads) => {
  setIsExporting(true);
  try {
    const response = await api.get(
      `${usedCarLeadsEndpoint}/export?from=${from}&to=${to}`,
      {
        responseType: 'blob',
      },
    );
    downloadFile(response.data, 'Used_Car_Leads_Report');
  } catch (error: any) {
    console.error(error);
  } finally {
    setIsExporting(false);
  }
};

export const createUsedCarLead = async (lead: ICreateUsedCarLead) => {
  try {
    const response = await api.post(usedCarLeadsEndpoint, lead);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const editUsedCarLead = async ({
  id,
  data: lead,
}: {
  id: number;
  data: IEditUsedCarLead;
}) => {
  try {
    const response = await api.put(`${usedCarLeadsEndpoint}/${id}`, lead);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteUsedCarLead = async (id: number) => {
  const response = await api.delete(`${usedCarLeadsEndpoint}/${id}`);
  return response.data.message;
};
