import api from '../../api/apiIndex';
import { IReviewer } from '../../models/reviewers';

const reviewersEndpoint = 'v2/admin/reviewers';

export interface IGetReviewers {
  active?: boolean;
}

export interface ICreateReviewerPayload {
  name: string;
  autobiography: string;
  add_image: { id: number };
}

export interface IUpdateReviewerPayload {
  name?: string;
  autobiography?: string;
  add_image?: { id: number };
  remove_image?: { url: string };
}

export const getReviewers = async (
  data?: IGetReviewers,
): Promise<IReviewer[]> => {
  const response = await api.get(reviewersEndpoint, {
    params: { active: data?.active },
  });
  return response.data;
};

export const createReviewer = async (request: ICreateReviewerPayload) => {
  const response = await api.post(reviewersEndpoint, request);
  return response.data;
};

export const updateReviewer = async (
  id: number,
  data: IUpdateReviewerPayload,
) => {
  const response = await api.put(`${reviewersEndpoint}/${id}`, data);
  return response.data;
};

export const deleteReviewer = async (id: number) => {
  const response = await api.delete(`${reviewersEndpoint}/${id}`);
  return response.data;
};

export const activateReviewer = async (id: number) => {
  const response = await api.put(`${reviewersEndpoint}/activate/${id}`);
  return response.data;
};

export const deactivateReviewer = async (id: number) => {
  const response = await api.put(`${reviewersEndpoint}/deactivate/${id}`);
  return response.data;
};
