import api from '../../../../api/apiIndex';

const batteryRangeEndpointv2 = 'v2/admin/resources/cars/battery-ranges';

export interface IGetBatteryRanges {
  active?: boolean;
}

export interface IBatteryRangerPayload {
  name: string;
  arabic_name: string;
  status: number;
  engine_types: Array<number>;
}

const getBatteryRanges = async ({ active }: IGetBatteryRanges) => {
  try {
    const response = await api.get(batteryRangeEndpointv2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createBatteryRange = async (data: IBatteryRangerPayload) => {
  try {
    const response = await api.post(batteryRangeEndpointv2, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateBatterRange = async (id: number, data: IBatteryRangerPayload) => {
  try {
    const response = await api.put(`${batteryRangeEndpointv2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteBatteryRange = async (id: number) => {
  try {
    const response = await api.delete(`${batteryRangeEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBatteryRange = async (id: number) => {
  try {
    const response = await api.put(`${batteryRangeEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBatteryRange = async (id: number) => {
  try {
    const response = await api.put(
      `${batteryRangeEndpointv2}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getBatteryRanges,
  createBatteryRange,
  updateBatterRange,
  deleteBatteryRange,
};
