import { Modal, message } from 'antd';
import { RentalDealer } from '../../../models/dealers/rentals/RentalDealer';
import {
  ALL_Rental_DEALERS_NAMES_KEY,
  useCreateRentalDealers,
  useEditRentalDealers,
} from '../hooks';
import { ICreateRentalDealer, IEditRentalDealer } from '../apis';
import { AddEditRentalDealerForm } from './AddEditRentalDealerForm';
import { useState } from 'react';
import { queryClient } from '../../../App';

interface RentalDealerModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentRentalDealer: RentalDealer | null;
  setCurrentRentalDealer: (value: RentalDealer | null) => void;
}

export interface IRentalDealerLogoUpload {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export const RentalDealerModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentRentalDealer,
  setCurrentRentalDealer,
}: RentalDealerModalProps) => {
  const [uploadedLogo, setUploadedLogo] =
    useState<IRentalDealerLogoUpload | null>(null);

  const { mutateAsync: createRentalDealer, isLoading: isCreating } =
    useCreateRentalDealers();
  const { mutateAsync: updateRentalDealer, isLoading: isUpdating } =
    useEditRentalDealers();

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentRentalDealer(null);
  };

  const _create = async (rentalDealer: ICreateRentalDealer) => {
    if (rentalDealer == null) return;
    try {
      const response = await createRentalDealer({
        name: rentalDealer.name,
        arabic_name: rentalDealer.arabic_name,
        city_id: rentalDealer.city_id,
        add_logo: { id: uploadedLogo!.id },
      });
      setIsFormOpen(false);
      queryClient.invalidateQueries({
        queryKey: ALL_Rental_DEALERS_NAMES_KEY,
      });
      message.success('Rental Dealer created successfully');
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (rentalDealer: IEditRentalDealer) => {
    try {
      const response = await updateRentalDealer({
        id: currentRentalDealer!.id,
        data: {
          name: rentalDealer.name,
          arabic_name: rentalDealer.arabic_name,
          city_id: rentalDealer.city_id,
          add_logo:
            uploadedLogo !== null ? { id: uploadedLogo!.id } : undefined,
          remove_logo:
            uploadedLogo !== null
              ? { url: currentRentalDealer!.logo }
              : undefined,
        },
      });
      setIsFormOpen(false);
      setCurrentRentalDealer(null);
      queryClient.invalidateQueries({
        queryKey: ALL_Rental_DEALERS_NAMES_KEY,
      });
      message.success('Rental Dealer updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Rental Dealer' : 'Add Rental Dealer'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={closeForm}
        footer={null}
        width={1200}
        key={currentRentalDealer?.id}
      >
        <AddEditRentalDealerForm
          rental={currentRentalDealer}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={closeForm}
          setUploadedLogo={setUploadedLogo}
        />
      </Modal>
    </>
  );
};
