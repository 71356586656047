import Icon from '../icons/Icon';

type ImageEditOverlayProps = {
  onDelete: () => void;
  onEdit: () => void;
  justifyContent?: string;
  gap?: number;
};

const ImageEditOverlay = ({
  onDelete,
  onEdit,
  justifyContent = 'between',
  gap = 4,
}: ImageEditOverlayProps) => {
  return (
    <div
      className={`black-overlay flex justify-content-${justifyContent} align-items-start gap-${gap}`}
      style={{ padding: '15px' }}
    >
      <Icon
        color="#ffffff"
        iconSize="24px"
        icon="pi-pencil"
        onClick={onEdit}
        cursor
      />
      <Icon
        color="#ffffff"
        iconSize="24px"
        icon="pi-trash"
        onClick={onDelete}
        cursor
      />
    </div>
  );
};

export default ImageEditOverlay;
