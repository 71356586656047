import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FileUpload from '../../../../components/inputs/FileUpload';
import { IRamadanOffer } from '../../../../models/listings/cars/ramadan/ramadanOffer';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import { uploadImage } from '../../../../common/utils';
import { AxiosError } from 'axios';
import { useGetAllBrands } from '../../../../resources/cars/car_brands/hooks';
import { useGetAllModels } from '../../../../resources/cars/car_models/hooks';
import { CarBrand } from '../../../../models/resources/CarBrand';
import { ICarModel } from '../../../../models/resources';
import { useGetAllDealers } from '../../../../dealers/Automotive/hooks';
import { ICarDealer } from '../../../../models/dealers/cars';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  IRamadanOfferMobileCoverImageUpload,
  IRamadanOfferUploadedImages,
} from './RamadanModal';

type CustomUploadFileStatus = UploadFileStatus | 'old';

const { Item, List } = Form;
const { TextArea } = Input;

interface RamadanFormProps {
  selectedOffer: IRamadanOffer | null;
  isEditing: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  addedImages: any[];
  removedImages: any[];
  setAddedImages: Dispatch<SetStateAction<IRamadanOfferUploadedImages[]>>;
  setRemovedImages: Dispatch<SetStateAction<Array<Record<string, string>>>>;
  setAddedMobileCoverImages: Dispatch<
    SetStateAction<IRamadanOfferUploadedImages[]>
  >;
  setRemovedMobileCoverImages: Dispatch<
    SetStateAction<Array<Record<string, string>>>
  >;
}

export const RamadanForm = ({
  selectedOffer,
  isEditing,
  isLoading,
  onClose,
  onSubmit,
  addedImages,
  removedImages,
  setAddedImages,
  setRemovedImages,
  setAddedMobileCoverImages,
  setRemovedMobileCoverImages,
}: RamadanFormProps) => {
  const [form] = Form.useForm();
  const brandId = Form.useWatch('brand_id', form);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const { data: brands, isLoading: isLoadingBrands } = useGetAllBrands({
    active: true,
    type: 'new',
  });

  const { data: models, isLoading: isLoadingModels } = useGetAllModels({
    enabled: isEditing || !!brandId,
    brandId: brandId,
    active: true,
  });

  const { data: dealers, isLoading: isLoadingDealerNames } = useGetAllDealers({
    enabled: true,
    active: true,
  });

  const initialImagesEn = selectedOffer?.images
    ? Array.isArray(selectedOffer?.images)
      ? selectedOffer?.images
          .filter(img => img.lang === 'en')
          .map(image => {
            return {
              uid: image.url,
              status: 'old',
              url: image.url,
              id: image.url,
              name: image.url.split('/').pop(),
            };
          })
      : [
          {
            uid: selectedOffer?.images,
            status: 'old',
            url: selectedOffer.images,
            id: selectedOffer.images,
            name: selectedOffer.images,
          },
        ]
    : [];

  const initialImagesAr = selectedOffer?.images
    ? Array.isArray(selectedOffer?.images)
      ? selectedOffer?.images
          .filter(img => img.lang === 'ar')
          .map(image => {
            return {
              uid: image.url,
              status: 'old',
              url: image.url,
              id: image.url,
              name: image.url.split('/').pop(),
            };
          })
      : [
          {
            uid: selectedOffer?.images,
            status: 'old',
            url: selectedOffer.images,
            id: selectedOffer.images,
            name: selectedOffer.images,
          },
        ]
    : [];

  const initialMobileCoverImagesEn =
    selectedOffer?.ramadan_offer_mobile_cover_image
      ? selectedOffer?.ramadan_offer_mobile_cover_image
          .filter(img => img.lang === 'en')
          .map(image => {
            return {
              uid: image.name,
              status: 'old',
              url: image.name,
              id: image.name,
              name: image.name.split('/').pop(),
            };
          })
      : [];

  const initialMobileCoverImagesAr =
    selectedOffer?.ramadan_offer_mobile_cover_image
      ? selectedOffer?.ramadan_offer_mobile_cover_image
          .filter(img => img.lang === 'ar')
          .map(image => {
            return {
              uid: image.name,
              status: 'old',
              url: image.name,
              id: image.name,
              name: image.name.split('/').pop(),
            };
          })
      : [];

  const [fileListEn, setFileListEn] = useState<UploadFile[]>(
    initialImagesEn as UploadFile[],
  );

  const [fileListAr, setFileListAr] = useState<UploadFile[]>(
    initialImagesAr as UploadFile[],
  );

  const [mobileCoverImageFileListEn, setMobileCoverImageFileListEn] = useState<
    UploadFile[]
  >(initialMobileCoverImagesEn as UploadFile[]);

  const [mobileCoverImageFileListAr, setMobileCoverImageFileListAr] = useState<
    UploadFile[]
  >(initialMobileCoverImagesAr as UploadFile[]);

  const imageUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsImageUploading(true);
      const response = await uploadImage({
        file,
        path: 'ramadan-offer-image',
        onProgress: onProgress,
        extraBodyData: {
          lang: data?.lang,
        },
      });
      if (onSuccess) onSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedImages(prevImages => [...prevImages, addedImage]);
      setIsImageUploading(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsImageUploading(false);
    }
  };

  const removeImage = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedImages(prev => [
        ...prev,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedImages(addedImages.filter(image => image.file.uid !== file.uid));
    }
  };

  const mobileCoverImageUpload = async ({
    onSuccess,
    onProgress,
    file,
    data,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsImageUploading(true);
      const response = await uploadImage({
        file,
        path: 'ramadan-offer-mobile-cover-image',
        onProgress: onProgress,
        extraBodyData: {
          lang: data?.lang,
        },
      });
      if (onSuccess) onSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedMobileCoverImages(prev => [...prev, addedImage]);
      setIsImageUploading(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsImageUploading(false);
    }
  };

  const removeMobileCoverImage = (file: UploadFile) => {
    if (file.status === ('old' as CustomUploadFileStatus)) {
      setRemovedMobileCoverImages(prev => [
        ...prev,
        { url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}` },
      ]);
    } else {
      setAddedMobileCoverImages(
        addedImages.filter(image => image.file.uid !== file.uid),
      );
    }
  };

  const initialValues = selectedOffer
    ? {
        title: selectedOffer.title,
        arabic_title: selectedOffer.t[0]?.title,
        description: selectedOffer.description,
        arabic_description: selectedOffer.t[0]?.description,
        Benefits: selectedOffer.ramadan_offer_benefits.map(benefit => ({
          benefits: benefit.benefit,
          arabic_benefits: benefit.t.find(t => t.lang === 'ar')?.benefit,
        })),
        brand_id: selectedOffer.brand_id,
        model_ids: selectedOffer.models.map((model: ICarModel) => model.id),
        dealer_id: dealers?.find(
          (dealer: ICarDealer) => dealer.id === selectedOffer.dealer_id,
        )?.id,
        images_en: initialImagesEn,
        mobile_cover_image_en: initialMobileCoverImagesEn,
        images_ar: initialImagesEn,
        mobile_cover_image_ar: initialMobileCoverImagesEn,
      }
    : undefined;

  useEffect(() => {
    form.resetFields();
  }, [selectedOffer, form, brands, dealers]);

  useEffect(() => {
    if (!brandId) {
      form.resetFields();
    }
  }, [models]);

  const resetForm = () => {
    form.resetFields();
    setFileListEn([]);
    setFileListAr([]);
    setMobileCoverImageFileListEn([]);
    setMobileCoverImageFileListAr([]);
    setAddedImages([]);
    setRemovedImages([]);
    setAddedMobileCoverImages([]);
    setRemovedMobileCoverImages([]);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="ramadan_offer_form"
      scrollToFirstError
      onFinish={() => {
        onSubmit(form.getFieldsValue());
        resetForm();
      }}
      initialValues={initialValues}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Item
            name="dealer_id"
            label="Dealer"
            rules={[
              {
                required: true,
                message: 'Please select the dealer',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a dealer"
              optionFilterProp="children"
              loading={isLoadingDealerNames}
              disabled={isLoadingDealerNames}
            >
              {dealers?.map((dealerName: any) => (
                <Select.Option key={dealerName.id} value={dealerName.id}>
                  {dealerName.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please enter the title',
              },
            ]}
          >
            <Input placeholder="Enter the title" />
          </Item>
        </Col>
        <Col span={8}>
          <Item
            name="arabic_title"
            label="Arabic Title"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic title',
              },
            ]}
          >
            <Input placeholder="Enter the arabic title" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Brand"
            name="brand_id"
            rules={[
              {
                required: true,
                message: 'Please select the brand of the car',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a brand"
              optionFilterProp="children"
              loading={isLoadingBrands}
              disabled={isLoadingBrands}
              onChange={() => {
                form.setFieldsValue({
                  model_id: undefined,
                });
              }}
            >
              {brands?.map((brand: CarBrand) => (
                <Select.Option key={brand.id} value={brand.id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Model"
            name="model_ids"
            rules={[
              {
                required: true,
                message: 'Please select the model of the car',
              },
            ]}
            dependencies={['brand']}
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="Select a model"
              optionFilterProp="children"
              loading={isLoadingModels}
              disabled={isLoadingModels || !brandId}
            >
              {models?.map((model: any) => (
                <Select.Option key={model.id} value={model.id}>
                  {model.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Please enter the description',
              },
            ]}
          >
            <TextArea placeholder="Enter the description" rows={4} />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_description"
            label="Arabic Description"
            rules={[
              {
                required: true,
                message: 'Please enter the arabic description',
              },
            ]}
          >
            <TextArea placeholder="Enter the arabic description" rows={4} />
          </Item>
        </Col>
      </Row>

      <Row gutter={24} wrap>
        <Col span={24}>
          <List name="Benefits">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Item
                    label={index === 0 ? 'Benefits' : ''}
                    required={true}
                    key={key}
                  >
                    <Row gutter={24} align={'top'} wrap>
                      <Col span={11}>
                        <Item
                          {...restField}
                          name={[name, 'benefits']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an english benefit',
                            },
                          ]}
                        >
                          <Input placeholder={`English Benefit ${index + 1}`} />
                        </Item>
                      </Col>
                      <Col span={11}>
                        <Item
                          {...restField}
                          name={[name, 'arabic_benefits']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an arabic benefit',
                            },
                          ]}
                        >
                          <Input placeholder={`Arabic Benefit ${index + 1}`} />
                        </Item>
                      </Col>
                      <Col
                        span={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingTop: '0.7%',
                        }}
                      >
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{ fontSize: '18px' }}
                        />
                      </Col>
                    </Row>
                  </Item>
                ))}
                {fields.length < 5 && (
                  <Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add benefit
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Item>
                )}
              </>
            )}
          </List>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <FileUpload
            fileList={mobileCoverImageFileListEn}
            label="English Cover Image"
            maxNbFiles={1}
            name="mobile_cover_image_en"
            setFileList={setMobileCoverImageFileListEn}
            customRequest={props =>
              mobileCoverImageUpload({ ...props, data: { lang: 'en' } })
            }
            onRemove={removeMobileCoverImage}
            requiredMessage="Please upload an english cover image for the ramadan offer"
          />
        </Col>
        <Col>
          <FileUpload
            fileList={fileListEn}
            label="English Images"
            maxNbFiles={5}
            name="images_en"
            setFileList={setFileListEn}
            customRequest={props =>
              imageUpload({ ...props, data: { lang: 'en' } })
            }
            onRemove={removeImage}
            requiredMessage="Please upload english images"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <FileUpload
            fileList={mobileCoverImageFileListAr}
            label="Arabic Cover Image"
            maxNbFiles={1}
            name="mobile_cover_image_ar"
            setFileList={setMobileCoverImageFileListAr}
            customRequest={props =>
              mobileCoverImageUpload({ ...props, data: { lang: 'ar' } })
            }
            onRemove={removeMobileCoverImage}
            requiredMessage="Please upload an arabic cover image for the ramadan offer"
          />
        </Col>
        <Col>
          <FileUpload
            fileList={fileListAr}
            label="Arabic Images"
            maxNbFiles={5}
            name="images_ar"
            setFileList={setFileListAr}
            customRequest={props =>
              imageUpload({ ...props, data: { lang: 'ar' } })
            }
            onRemove={removeImage}
            requiredMessage="Please upload arabic images"
          />
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            danger
            onClick={() => {
              onClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isImageUploading}
          >
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
