import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ITransmissionPayload,
  activateTransmission,
  createTransmission,
  deactivateTransmission,
  deleteTransmission,
  getTransmissions,
  updateTransmission,
} from '../apis/carTransmissionApi';
import { queryClient } from '../../../../App';

export const ALL_TRANSMISSIONS_KEY = ['allTransmissions'];

interface IUseTransmissions {
  enabled: boolean;
  active?: boolean;
}

export const useTransmissions = ({ enabled, active }: IUseTransmissions) => {
  return useQuery({
    queryKey: ALL_TRANSMISSIONS_KEY,
    queryFn: () => getTransmissions({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateTransmission = () => {
  return useMutation({
    mutationFn: (data: ITransmissionPayload) => createTransmission(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_TRANSMISSIONS_KEY);
    },
  });
};

export const useUpdateTransmission = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ITransmissionPayload }) => {
      return updateTransmission(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_TRANSMISSIONS_KEY);
    },
  });
};

export const useDeleteTransmission = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteTransmission(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_TRANSMISSIONS_KEY);
    },
  });
};

export const useActivateTransmission = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return activateTransmission(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_TRANSMISSIONS_KEY);
    },
  });
};

export const useDeactivateTransmission = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deactivateTransmission(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_TRANSMISSIONS_KEY);
    },
  });
};
