import { UserGroups } from '../../../common/constants';
import { RentalLeadsDetailsLayout } from '../../../leads/rentals/components/RentalLeadsDetailsLayout';

const RentalLeadsDetails = () => {
  return <RentalLeadsDetailsLayout />;
};

const rentalLeadsDetailsRoute = {
  path: '/leads/rentals/:dealerSlug/:brandSlug?/:modelSlug?',
  index: true,
  element: <RentalLeadsDetails />,
  state: 'rentalLeadsDetails',
  sideBarProps: {
    displayText: 'Rental Leads Details',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Rental],
};

export { RentalLeadsDetails, rentalLeadsDetailsRoute };
