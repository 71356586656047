import { Button, Input, Popconfirm, Tag, message } from 'antd';
import { useEffect, useState } from 'react';
import {
  useActivateBikeBatteryCapacity,
  useDeactivateBikeBatteryCapacity,
  useDeleteBikeBatteryCapacity,
  useGetBikeBatteryCapacities,
} from './hooks';
import { useGetBikeEngineType } from '../engine_types';
import { IBikeBatteryCapacity } from '../../../models/resources/bikes/BikeBatterCapacity';
import { useSearch } from '../../../hooks/useSearch';
import { ColumnsType } from 'antd/es/table';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import { Layout } from '../../../components/Layout';
import { Table } from 'antd/lib';
import { BikeBatteryCapacitiesModal } from './components';

const { Search } = Input;

export const BikeBatteryCapacitiesLayout = () => {
  const {
    data: bikeBatteryCapacities,
    isLoading: isLoadingBikeBatteryCapacities,
  } = useGetBikeBatteryCapacities({ enabled: true });
  const { data: bikeEngineTypes } = useGetBikeEngineType({ enabled: true });

  const { mutateAsync: deleteBikeBatteryCapacity, isLoading: isDeleting } =
    useDeleteBikeBatteryCapacity();
  const { mutateAsync: activateBikeBatteryCapacity, isLoading: isActivating } =
    useActivateBikeBatteryCapacity();
  const {
    mutateAsync: deactivateBikeBatteryCapacity,
    isLoading: isDeactivating,
  } = useDeactivateBikeBatteryCapacity();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBikeBatteryCapacity, setCurrentBikeBatteryCapacity] =
    useState<IBikeBatteryCapacity | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBikeBatteryCapacity } = useSearch();
  const [filteredData, setFilteredData] = useState(bikeBatteryCapacities);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBikeBatteryCapacity | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<IBikeBatteryCapacity | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBikeBatteryCapacity(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Capacity deactivated successfully');
          },
        })
      : activateBikeBatteryCapacity(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Capacity activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteBikeBatteryCapacity(id, {
      onSuccess: () => {
        message.success('Battery Capacity deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (batteryCapacity: IBikeBatteryCapacity) => {
    setCurrentBikeBatteryCapacity(batteryCapacity);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentBikeBatteryCapacity(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchBikeBatteryCapacity(bikeBatteryCapacities, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchBikeBatteryCapacity(
      bikeBatteryCapacities,
      searchValue,
    );
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(bikeBatteryCapacities);
  }, [bikeBatteryCapacities]);

  const tableColumns: ColumnsType<IBikeBatteryCapacity> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${bikeBatteryCapacities?.map(
        (bikeBatteryCapacity: IBikeBatteryCapacity) =>
          bikeBatteryCapacity.t[0]?.name,
      )}`,
      key: `${bikeBatteryCapacities?.map(
        (bikeBatteryCapacity: IBikeBatteryCapacity) =>
          bikeBatteryCapacity.t[0]?.name,
      )}`,
      render: (text: any, record: IBikeBatteryCapacity) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IBikeBatteryCapacity) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: IBikeBatteryCapacity) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
            style={{ fontSize: '1rem', color: 'var(--primary)' }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this battery capacity will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this battery capacity.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Battery Capacities"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Battery Capacity
        </Button>
      </div>
      <Table
        loading={isLoadingBikeBatteryCapacities}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
      />
      <BikeBatteryCapacitiesModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        currentBikeBatteryCapacity={currentBikeBatteryCapacity}
        setCurrentBikeBatteryCapacity={setCurrentBikeBatteryCapacity}
        bikeEngineTypes={bikeEngineTypes}
      />
    </Layout>
  );
};
