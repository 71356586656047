import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Button, Image, Input, Popconfirm, Table, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Layout } from '../../../../components/Layout';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';
import { RamadanModal } from './RamadanModal';
import {
  useActivateRamadanOffer,
  useDeactivateRamadanOffer,
  useDeleteRamadanOffer,
  useGetRamadanOffers,
} from '../hooks';
import { IRamadanOffer } from '../../../../models/listings/cars/ramadan/ramadanOffer';
import { useSearch } from '../../../../hooks/useSearch';

const { Search } = Input;

export const RamadanLayout = () => {
  const [selectedOffer, setSelectedOffer] = useState<IRamadanOffer | null>(
    null,
  );
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [carsPerPage, setCarsPerPage] = useState(30);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchRamadanOffers } = useSearch();

  const [openToggleActivateDialog, setOpenToggleActivateDialog] = useState<
    number | null
  >(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<any | null>(null);

  const { data: offers, isLoading: isOffersLoading } = useGetRamadanOffers({});
  const [filteredData, setFilteredData] = useState(offers);

  const { mutateAsync: activateRamadanOffer, isLoading: isActivating } =
    useActivateRamadanOffer();

  const { mutateAsync: deactivateRamadanOffer, isLoading: isDeactivating } =
    useDeactivateRamadanOffer();

  const { mutateAsync: deleteOffer, isLoading: isDeleting } =
    useDeleteRamadanOffer();

  const handleDelete = (id: number) => {
    deleteOffer(id, {
      onSuccess: () => {
        message.success('Ramadan Offer deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateRamadanOffer(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Ramadan Offer deactivated successfully');
          },
        })
      : activateRamadanOffer(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Ramadan Offer activated successfully');
          },
        });
  };

  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setCarsPerPage(pageSize);
  };

  const onEditClick = (offer: any) => {
    setSelectedOffer(offer);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setSelectedOffer(null);
    setIsEditing(false);
    setIsFormOpen(true);
  };

  const onSearch = (value: string) => {
    if (offers) {
      setSearchValue(value);
      handleSearchRamadanOffers(offers, searchValue);
    }
  };

  useEffect(() => {
    if (!offers) return;
    const newData = handleSearchRamadanOffers(offers, searchValue);
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(offers);
  }, [offers]);

  const tableColumns: ColumnsType<IRamadanOffer> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Models',
      dataIndex: 'models',
      key: 'models',
      render: rowData => {
        return rowData?.map((model: any) => model.name).join(', ');
      },
      ellipsis: true,
    },
    {
      title: 'Cover Image',
      dataIndex: 'ramadan_offer_mobile_cover_image',
      key: 'ramadan_offer_mobile_cover_image',
      render: (rowData: IRamadanOffer['ramadan_offer_mobile_cover_image']) => {
        return (
          <Image
            src={rowData.find(image => image.lang === 'en')?.name}
            height={70}
            width={140}
            fallback="https://play-lh.googleusercontent.com/J-SpF7SizHC3yGtAIxl00_jq5q6hkZO1A2zE0efFSAjr_SNEW5N37aMgocinR75utQ=w240-h480-rw"
            style={{
              objectFit: 'cover',
            }}
          />
        );
      },
    },
    {
      title: 'Dealer',
      dataIndex: 'dealer',
      key: 'dealer',
      render: rowData => {
        return rowData?.name;
      },
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '10%',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: any) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 120,
      render: (_, rowData) => {
        return (
          <div className="flex gap-4">
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this ramadan offer.`}
              open={openToggleActivateDialog === rowData.id}
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              onConfirm={() => {
                handleToggleActivate(rowData?.id, rowData.is_active);
              }}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData.id);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData.id);
                  }}
                />
              )}
            </Popconfirm>
            <EditTwoTone
              onClick={() => {
                onEditClick(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Deleting this ramadan offer will delete all its related data."
              open={openDeleteDialog === rowData.id}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpenDeleteDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpenDeleteDialog(rowData.id);
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const expandableColumns: ColumnsType<IRamadanOffer> = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Benefits',
      dataIndex: `${offers?.map(offer =>
        offer.ramadan_offer_benefits.map(benefit => benefit.benefit),
      )}`,
      key: `${offers?.map(offer =>
        offer.ramadan_offer_benefits.map(benefit => benefit.benefit),
      )}`,
      render: (text: any, record: IRamadanOffer) => (
        <span>
          {record.ramadan_offer_benefits
            .map(benefit => benefit.benefit)
            .join(', ')}
        </span>
      ),
      ellipsis: true,
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search by title"
          onSearch={onSearch}
          onChange={e => setSearchValue(e.target.value)}
          style={{ width: 200 }}
        />
        <Button
          style={{ backgroundColor: 'var(--primary-color)' }}
          type="primary"
          onClick={openEmptyForm}
        >
          Add Ramadan Offer
        </Button>
      </div>
      <Table
        columns={tableColumns}
        scroll={{ x: 1300, y: 650 }}
        dataSource={filteredData}
        pagination={{
          pageSize: carsPerPage,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          position: ['bottomCenter'],
          onChange: onPageChange,
          current: currentPage,
        }}
        rowKey={record => record.id}
        loading={isOffersLoading}
        expandable={{
          expandedRowRender: record => (
            <Table
              columns={expandableColumns}
              dataSource={[record]}
              pagination={false}
              rowKey={record => record.id}
            />
          ),
        }}
      />
      <RamadanModal
        isVisible={isFormOpen}
        selectedOffer={selectedOffer}
        isEditing={isEditing}
        onClose={() => setIsFormOpen(false)}
        setIsFormOpen={setIsFormOpen}
        setSelectedOffer={setSelectedOffer}
      />
    </Layout>
  );
};
