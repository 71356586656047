import { Modal, message } from 'antd';
import { ICountry } from '../../../models/countries/Country';
import { useCreateCountry, useUpdateCountry } from '../hooks';
import { CountriesForm } from './CountriesForm';

interface CountriesModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentCountry: ICountry | null;
  setCurrentCountry: (value: ICountry | null) => void;
}
export const CountriesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  setIsEditing,
  currentCountry,
  setCurrentCountry,
}: CountriesModalProps) => {
  const { mutateAsync: createCountry, isLoading: isCreating } =
    useCreateCountry();
  const { mutateAsync: updateCountry, isLoading: isUpdating } =
    useUpdateCountry();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createCountry(data);
      setIsFormOpen(false);
      message.success('Country created successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateCountry({
        id: currentCountry!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentCountry(null);
      message.success('Country updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Country' : 'Add Country'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => {
          setIsFormOpen(false);
          setCurrentCountry(null);
          setIsEditing(false);
        }}
        footer={null}
        width={1200}
        key={currentCountry?.id}
      >
        <CountriesForm
          country={currentCountry}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => {
            setIsFormOpen(false);
            setCurrentCountry(null);
            setIsEditing(false);
          }}
          onSubmit={isEditing ? _edit : _create}
        />
      </Modal>
    </>
  );
};
