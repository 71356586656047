import { useState } from 'react';
import { Modal, message } from 'antd';
import { useCreateReviewer, useUpdateReviewer } from '../hooks';
import { IReviewer, IReviewerImage } from '../../models/reviewers';
import { AddEditReviewerForm } from './AddEditReviewerForm';

interface ReviewerModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentReviewer: IReviewer | null;
  setCurrentReviewer: (value: IReviewer | null) => void;
  closeForm: () => void;
}

export const ReviewerModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentReviewer,
  setCurrentReviewer,
  closeForm,
}: ReviewerModalProps) => {
  const [uploadedImage, setUploadedImage] = useState<IReviewerImage | null>(
    null,
  );

  const { mutateAsync: createReviewer, isLoading: isCreatingReviewer } =
    useCreateReviewer();

  const { mutateAsync: updateReviewer, isLoading: isUpdatingReviewer } =
    useUpdateReviewer();

  const _create = async (reviewer: any) => {
    if (reviewer == null) return;
    try {
      delete reviewer.image;
      reviewer['add_image'] = { id: uploadedImage!.id };
      const response = await createReviewer(reviewer);
      setIsFormOpen(false);
      setCurrentReviewer(null);
      message.success('Reviewer Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (currentReviewer == null) return;
    try {
      delete data.image;
      data['add_image'] =
        uploadedImage !== null ? { id: uploadedImage!?.id } : undefined;
      data['remove_image'] =
        uploadedImage !== null
          ? { url: currentReviewer!?.reviewer_images }
          : undefined;
      const response = await updateReviewer({
        id: currentReviewer.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentReviewer(null);
      message.success('Reviewer Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        className="crud-dialog"
        title={isEditing ? 'Edit Reviewer' : 'Create Reviewer'}
        open={isFormOpen}
        onCancel={closeForm}
        width={1200}
        footer={null}
        key={currentReviewer?.id}
        style={{ top: 20 }}
      >
        <AddEditReviewerForm
          isEditing={isEditing}
          onClose={() => setIsFormOpen(false)}
          reviewer={currentReviewer}
          onSubmit={isEditing ? _edit : _create}
          isLoading={isCreatingReviewer || isUpdatingReviewer}
          setUploadedImage={setUploadedImage}
        />
      </Modal>
    </>
  );
};
