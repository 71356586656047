import { UserGroups } from '../../../common/constants';
import { NewCarLeadsDetailsLayout } from '../../../leads/new_cars/components/NewCarLeadsDetailsLayout';

const NewCarLeadsDetails = () => {
  return <NewCarLeadsDetailsLayout />;
};

const newCarLeadsDetailsRoute = {
  path: '/leads/new-cars/:dealerSlug/:brandSlug?/:modelSlug?',
  index: true,
  element: <NewCarLeadsDetails />,
  state: 'newCarLeadsDetails',
  sideBarProps: {
    displayText: 'New Car Leads Details',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { NewCarLeadsDetails, newCarLeadsDetailsRoute };
