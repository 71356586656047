import { useState } from 'react';
import { Modal, message } from 'antd';
import { useCreateReview, useUpdateReview } from '../hooks';
import { IReviewsThumbnails, IReviewsVideos } from '../../models/reviews';
import { AddEditReviewForm } from './AddEditReviewForm';

interface ReviewModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentReview: any;
  setCurrentReview: (value: any) => void;
}

export const ReviewModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentReview,
  setCurrentReview,
}: ReviewModalProps) => {
  const [addedVideos, setAddedVideos] = useState<IReviewsVideos[]>([]);
  const [removedVideos, setRemovedVideos] = useState<
    Array<Record<string, string>>
  >([]);

  const [addedThumbnails, setAddedThumbnails] = useState<IReviewsThumbnails[]>(
    [],
  );

  const [removedThumbnails, setRemovedThumbnails] = useState<
    Array<Record<string, string>>
  >([]);

  const { mutateAsync: createReview, isLoading: isCreatingReview } =
    useCreateReview();

  const { mutateAsync: updateReview, isLoading: isUpdatingReview } =
    useUpdateReview();

  const _create = async (review: any) => {
    if (review == null) return;
    try {
      delete review.video;
      delete review.thumbnail;
      review['add_video'] = addedVideos.map(video => ({ id: video.id }))[0];
      review['add_thumbnail'] = addedThumbnails.map(thumbnail => ({
        id: thumbnail.id,
      }))[0];
      const response = await createReview(review);
      setIsFormOpen(false);
      setCurrentReview(null);
      message.success('Review Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (currentReview == null) return;
    try {
      delete data.video;
      delete data.thumbnail;
      delete data.reviewer_id;
      data['add_video'] = addedVideos.map(video => ({ id: video.id }))[0];
      data['remove_video'] = removedVideos[0];
      data['add_thumbnail'] = addedThumbnails.map(thumbnail => ({
        id: thumbnail.id,
      }))[0];
      data['remove_thumbnail'] = removedThumbnails[0];
      const response = await updateReview({
        id: currentReview.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentReview(null);
      message.success('Review Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        className="crud-dialog"
        title={isEditing ? 'Edit Review' : 'Create Review'}
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        width={1200}
        footer={null}
        key={currentReview?.id}
        style={{ top: 20 }}
      >
        <AddEditReviewForm
          isEditing={isEditing}
          onClose={() => setIsFormOpen(false)}
          review={currentReview}
          onSubmit={isEditing ? _edit : _create}
          isLoading={isCreatingReview || isUpdatingReview}
          addedVideos={addedVideos}
          removedVideos={removedVideos}
          setAddedVideos={setAddedVideos}
          setRemovedVideos={setRemovedVideos}
          addedThumbnails={addedThumbnails}
          removedThumbnails={removedThumbnails}
          setAddedThumbnails={setAddedThumbnails}
          setRemovedThumbnails={setRemovedThumbnails}
        />
      </Modal>
    </>
  );
};
