import { RamadanLayout } from '../../../listings/cars/ramadan_offers/components';
import { RouteType } from '../../routes/routesConfig';

const RamadanOffers = () => {
  return <RamadanLayout />;
};

const ramadanOffersRoute: RouteType = {
  element: <RamadanOffers />,
  state: 'ramadan-offers',
  index: true,
  path: '/listings/cars/ramadan-offers',
  sideBarProps: {
    displayText: 'Ramadan Offers',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { RamadanOffers, ramadanOffersRoute };
