import api from '../api/apiIndex';
import { BankingLead } from '../models/overview/BankingLead';

const getAutomotiveInfo = async () => {
  const response = await api.get('/analytics/dealers/table');
  return response;
};

const exportAutomotiveReport = async (
  from: string | null,
  to: string | null,
) => {
  const endpoint =
    from && to
      ? `/analytics/dealers/export?from=${from}&to=${to}`
      : '/analytics/dealers/export';
  return await api.get(endpoint, { responseType: 'blob' });
};

const getBankingInfo = async () => {
  const response = await api.get('/analytics/finances/table');
  const mappedLeads = response.data.data.map(
    (lead: any) => new BankingLead(lead),
  );
  return mappedLeads;
};

const exportBankingReport = async (from: string | null, to: string | null) => {
  const endpoint =
    from && to
      ? `/analytics/finances/export?from=${from}&to=${to}`
      : '/analytics/finances/export';

  return await api.get(endpoint, { responseType: 'blob' });
};

export {
  getAutomotiveInfo,
  exportAutomotiveReport,
  getBankingInfo,
  exportBankingReport,
};
