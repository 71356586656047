import { IBikeCylinders } from '../../../../models/resources/bikes/BikeCylinders';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { IBikeCylindersPayload } from '../apis';
import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';

interface IBikeCylindersAddEditFormProps {
  bikeCyliners: IBikeCylinders | null;
  bikeEngineTypes: IBikeEngineType[] | null;
  isEditing: boolean;
  onClose: () => void;
  onSubmit: (values: IBikeCylindersPayload) => Promise<void>;
  isLoading: boolean;
}

const { Item } = Form;

export const AddEditBikeCylindersFrom = ({
  bikeCyliners,
  isEditing,
  bikeEngineTypes,
  onClose,
  onSubmit,
  isLoading,
}: IBikeCylindersAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: bikeCyliners?.name,
    arabic_name: bikeCyliners?.t?.[0]?.name,
    bike_engine_types: bikeCyliners?.bike_cylinder_bike_engine_type?.map(
      engineType => engineType?.bike_engine_types?.id,
    ),
  };
  return (
    <Form
      form={form}
      onFinish={async (values: IBikeCylindersPayload) => {
        try {
          onSubmit(values);
        } catch (error) {
          console.error(error);
        }
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[{ required: true, message: 'Arabic Name is required' }]}
          >
            <Input placeholder="Arabic Name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="bike_engine_types"
            label="Engine Type"
            rules={[{ required: true, message: 'Please select engine size' }]}
          >
            <Checkbox.Group>
              {bikeEngineTypes?.map((engineType: IBikeEngineType) => (
                <Checkbox value={engineType?.id}>{engineType?.name}</Checkbox>
              ))}
            </Checkbox.Group>
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
