import { UserGroups } from '../../../../common/constants';
import { BikeEngineTypesLayout } from '../../../../resources/bikes/engine_types/BikeEngineTypesLayout';
import { RouteType } from '../../../routes/routesConfig';

const BikeEngineType = () => {
  return <BikeEngineTypesLayout />;
};

const bikeEngineTypeRoute: RouteType = {
  element: <BikeEngineType />,
  state: 'engine-type',
  index: true,
  path: '/resources/bikes/engine-type',
  sideBarProps: {
    displayText: 'Engine Type',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeEngineType, bikeEngineTypeRoute };
