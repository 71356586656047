import api from '../../../api/apiIndex';

export const leadEmailsEndpoint = 'v2/admin/lead-emails';

export interface IGetLeadEmails {
  registeredEmails: Array<{ email: string }>;
  unregisteredEmails: Array<{ email: string }>;
}

export const getLeadEmails = async (): Promise<IGetLeadEmails> => {
  const response = await api.get(leadEmailsEndpoint);
  return response.data;
};

export const createLeadEmail = async (email: string) => {
  try {
    const response = await api.post(leadEmailsEndpoint, {
      email,
    });
    return response.data;
  } catch (err) {}
};

export const deleteLeadEmail = async (email: string) => {
  try {
    const response = await api.delete(leadEmailsEndpoint, { data: { email } });
    return response.data;
  } catch (err) {}
};
