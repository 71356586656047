import { useEffect, useState } from 'react';
import {
  Image,
  List,
  Space,
  Spin,
  Input,
  Divider,
  message,
  Button,
  Popconfirm,
} from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { EmptyState } from '../../components/emptyState/EmptyState';
import { Flex, FlexCentered, FlexColumn } from '../../components/Flex';
import { useSearch } from '../../hooks/useSearch';
import fallBackLogo from '../../assets/fallback.webp';
import {
  ALL_BANKS_KEY,
  useActivateBank,
  useDeactivateBank,
  useDeleteBanks,
  useGetBanks,
} from '../hooks/useBanks';
import { useQueryClient } from '@tanstack/react-query';
import useAuth from '../../hooks/useAuth';
import { UserGroups } from '../../common/constants';
import ActiveIcon from '../../components/icons/ActiveIcon';
import InactiveIcon from '../../components/icons/InactiveIcon';
import { IBank } from '../../models/banks/Bank';
import { Layout } from '../../components/Layout';
import { BanksModal } from './BanksModal';

const { Search } = Input;

export const BanksLayout = () => {
  const [currentBank, setCurrentBank] = useState<IBank | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<IBank | null>(null);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBank | null>(null);

  // Search States
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBanks } = useSearch();

  const { data: banks, isLoading: isLoadingBanks } = useGetBanks({
    enabled: true,
  });

  const [filteredData, setFilteredData] = useState(
    banks ? (Array.isArray(banks) ? banks : [banks]) : [],
  );

  const { mutateAsync: deleteBank, isLoading: isDeleting } = useDeleteBanks();
  const { mutateAsync: activateBank, isLoading: isActivating } =
    useActivateBank();
  const { mutateAsync: deactivateBank, isLoading: isDeactivating } =
    useDeactivateBank();

  const queryClient = useQueryClient();

  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const isOneAutocar = userGroup === UserGroups.OAC;

  const openEmptyForm = () => {
    setIsFormOpen(true);
    setCurrentBank(null);
    setIsEditing(false);
  };

  const openEditForm = (bank: IBank) => {
    setCurrentBank(bank);
    setIsFormOpen(true);
    setIsEditing(true);
  };

  const handleDelete = (id: number) => {
    deleteBank(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ALL_BANKS_KEY,
        });
        message.success('Bank deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBank(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ALL_BANKS_KEY,
            });
            message.success('Bank deactivated successfully');
            setOpenToggleActivateDialog(null);
          },
        })
      : activateBank(id, {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ALL_BANKS_KEY,
            });
            message.success('Bank activated successfully');
            setOpenToggleActivateDialog(null);
          },
        });
  };

  useEffect(() => {
    const newData = handleSearchBanks(
      banks ? (Array.isArray(banks) ? banks : [banks]) : [],
      searchValue,
    );
    setFilteredData(newData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(banks ? (Array.isArray(banks) ? banks : [banks]) : []);
  }, [banks]);

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        {isOneAutocar && (
          <>
            <Search
              placeholder="Search Banks"
              onSearch={value => setSearchValue(value)}
              style={{ width: 230 }}
              onChange={e => setSearchValue(e.target.value)}
            />

            <div className="align-self-end" style={{ width: 'fit-content' }}>
              <Button
                style={{ backgroundColor: 'var(--primary-color)' }}
                type="primary"
                onClick={openEmptyForm}
              >
                Add
              </Button>
            </div>
          </>
        )}
      </div>
      {isLoadingBanks ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {filteredData && filteredData?.length > 0 ? (
            <List
              style={{
                padding: '0 1rem',
              }}
              grid={{
                gutter: 16,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 2,
                xxl: 3,
              }}
              dataSource={filteredData}
              renderItem={(bank: IBank) => (
                <List.Item
                  key={bank.id}
                  style={{ borderBottom: '1px solid #e8e8e8' }}
                >
                  <List.Item.Meta key={bank.id} />
                  <FlexCentered
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '2rem',
                    }}
                  >
                    <Flex style={{ alignItems: 'center' }}>
                      <Space size={'middle'}>
                        <Image
                          src={bank.logo}
                          width={120}
                          height={50}
                          preview={false}
                          style={{
                            objectFit: 'contain',
                          }}
                          fallback={fallBackLogo}
                          alt="fallback image"
                        />
                        <FlexColumn>{bank.name}</FlexColumn>
                      </Space>
                    </Flex>
                    <Space split={<Divider type="vertical" />}>
                      {isOneAutocar && (
                        <Popconfirm
                          title="Are you sure?"
                          description={`Clicking "Yes" will ${
                            bank.is_active ? 'deactivate' : 'activate'
                          } this bank.`}
                          open={openToggleActivateDialog === bank}
                          onConfirm={() =>
                            handleToggleActivate(bank.id, bank.is_active)
                          }
                          okButtonProps={{
                            loading: isActivating || isDeactivating,
                          }}
                          onCancel={() => setOpenToggleActivateDialog(null)}
                          okText="Yes"
                          placement="topRight"
                        >
                          {bank.is_active ? (
                            <InactiveIcon
                              onClick={() => {
                                setOpenToggleActivateDialog(bank);
                              }}
                            />
                          ) : (
                            <ActiveIcon
                              onClick={() => {
                                setOpenToggleActivateDialog(bank);
                              }}
                            />
                          )}
                        </Popconfirm>
                      )}
                      <EditTwoTone onClick={() => openEditForm(bank)} />
                      {isOneAutocar && (
                        <Popconfirm
                          title="Are you sure?"
                          description="Deleting this bank will delete all its related data."
                          open={openDeleteDialog === bank}
                          onConfirm={() => handleDelete(bank.id)}
                          okButtonProps={{ loading: isDeleting }}
                          onCancel={() => setOpenDeleteDialog(null)}
                          okText="Yes"
                          placement="topRight"
                        >
                          <DeleteTwoTone
                            style={{ fontSize: '1rem', color: 'var(--error)' }}
                            onClick={() => {
                              setOpenDeleteDialog(bank);
                            }}
                          />
                        </Popconfirm>
                      )}
                    </Space>
                  </FlexCentered>
                </List.Item>
              )}
            />
          ) : (
            <EmptyState type="banks" />
          )}
          {isFormOpen && (
            <BanksModal
              isFormOpen={isFormOpen}
              setIsFormOpen={setIsFormOpen}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              currentBank={currentBank}
              setCurrentBank={setCurrentBank}
            />
          )}
        </>
      )}
    </Layout>
  );
};
