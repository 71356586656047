import { rentalDealersRoute } from './RentalDealers';
import { autoDealers } from './AutoDealers';
import { UserGroups } from '../../../common/constants';

const allDealersRoute = {
  index: false,
  state: 'dealers',
  children: [autoDealers, rentalDealersRoute],
  sideBarProps: {
    displayText: 'Dealers',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive, UserGroups.Rental],
};

export { allDealersRoute };
