import { useEffect } from 'react';
import { RouteType } from '../../routes/routesConfig';
import { RentalsLayout } from '../../../listings/cars/rentals/components/RentalsLayout';

const Rentals = () => {
  return <RentalsLayout />;
};

const rentalsRoute: RouteType = {
  element: <Rentals />,
  state: 'rentals',
  index: true,
  path: '/listings/cars/rentals',
  sideBarProps: {
    displayText: 'Rentals',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR', 'Rental Dealer'],
};

export { Rentals, rentalsRoute };
