import { Modal } from 'antd';
import { CarSeatMaterialForm } from './CarSeatMaterialForm';
import { ICarSeatMaterial } from '../utils/apiUtils';

interface CarSeatMaterialModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarSeatMaterial?: ICarSeatMaterial;
  setSelectedCarSeatMaterial: React.Dispatch<React.SetStateAction<any>>;
}

export const CarSeatMaterialModal = ({
  isOpen,
  setIsOpen,
  selectedCarSeatMaterial,
  setSelectedCarSeatMaterial,
}: CarSeatMaterialModalProps) => {
  const isEdit = !!selectedCarSeatMaterial;

  const handleClose = () => {
    try {
      setIsOpen(false);
      setSelectedCarSeatMaterial(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title="Seat Material"
      open={isOpen}
      onCancel={handleClose}
      width={1200}
      footer={null}
      key={selectedCarSeatMaterial?.id}
      destroyOnClose={true}
    >
      <CarSeatMaterialForm
        handleClose={handleClose}
        selectedCarSeatMaterial={selectedCarSeatMaterial}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
        setSelectedCarSeatMaterial={setSelectedCarSeatMaterial}
      />
    </Modal>
  );
};
