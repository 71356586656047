import { Row, Col, Statistic } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTotalBrandViews } from '../apis/useAnalytics';
import dayjs from 'dayjs';

export const Statistics = () => {
  const { data: totalBrandViews, isLoading: isLoadingBrandViews } =
    useTotalBrandViews({
      type: 'page_view',
      enabled: true,
    });
  const { data: totalWeeklyBrandViews, isLoading: isLoadingWeeklyBrandViews } =
    useTotalBrandViews({
      type: 'page_view',
      enabled: true,
      from: dayjs().add(-7, 'd').format('YYYY-MM-DD'),
      to: dayjs().format('YYYY-MM-DD'),
    });
  return (
    <Row gutter={16} justify="center" align="middle">
      <Col span={6} style={{ textAlign: 'center' }}>
        <Statistic
          title="Total Brands Views"
          value={totalBrandViews}
          prefix={<EyeOutlined />}
          loading={isLoadingBrandViews}
        />
      </Col>
      <Col span={6} style={{ textAlign: 'center' }}>
        <Statistic
          title="Week's Brands Views"
          value={totalWeeklyBrandViews}
          prefix={<EyeOutlined />}
          loading={isLoadingWeeklyBrandViews}
        />
      </Col>
      {/* <Col span={12}>
        <StatCard
          title="Total Leads"
          value={24}
          valueStyle={{ color: 'black' }}
          tooltipTitle="Page Views for the last 30 days"
          tooltipPlacement="top"
        />
      </Col> */}
    </Row>
  );
};
