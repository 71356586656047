import api from '../../../api/apiIndex';
import { downloadFile } from '../../../helpers/helperFunctions';
import { LeadContacted } from '../../constants';

const newBikeLeadsEndpoint = '/v2/admin/inquiries/new-bike-inquiries';

interface ILeadsDetailsProps {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

interface IExportNewBikeLeads {
  setIsExporting: (newValue: boolean) => void;
  from: string | null;
  to: string | null;
}

export interface ICreateNewBikeLead {
  name: string;
  email: string;
  mobile_number: string;
  bike_dealer_id: number;
  bike_brand_id: number;
  bike_model_id: number;
  source: string;
  contacted: LeadContacted;
  notes?: string;
}

export interface IEditNewBikeLead {
  name?: string;
  email?: string;
  mobile_number?: string;
  source?: string;
  contacted?: LeadContacted;
  notes?: string;
}

export const getNewBikeLeads = async () => {
  const response = await api.get(newBikeLeadsEndpoint);
  return response.data;
};

export const getNewBikeLeadsDetails = async ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: ILeadsDetailsProps) => {
  if (dealerSlug && brandSlug && modelSlug) {
    const response = await api.get(
      `${newBikeLeadsEndpoint}/${dealerSlug}/${brandSlug}/${modelSlug}`,
    );
    return response.data;
  } else if (dealerSlug && brandSlug) {
    const response = await api.get(
      `${newBikeLeadsEndpoint}/${dealerSlug}/${brandSlug}`,
    );
    return response.data;
  } else if (dealerSlug) {
    const response = await api.get(`${newBikeLeadsEndpoint}/${dealerSlug}`);
    return response.data;
  }
};

export const exportNewBikeLeads = async ({
  setIsExporting,
  from,
  to,
}: IExportNewBikeLeads) => {
  setIsExporting(true);
  try {
    const response = await api.get(
      `${newBikeLeadsEndpoint}/export?from=${from}&to=${to}`,
      {
        responseType: 'blob',
      },
    );
    downloadFile(response.data, 'New_Bike_Leads_Report');
  } catch (error: any) {
    console.error(error);
  } finally {
    setIsExporting(false);
  }
};

export const createNewBikeLead = async (lead: ICreateNewBikeLead) => {
  const response = await api.post(newBikeLeadsEndpoint, lead);
  return response.data;
};

export const editNewBikeLead = async ({
  id,
  data: lead,
}: {
  id: number;
  data: IEditNewBikeLead;
}) => {
  const response = await api.put(`${newBikeLeadsEndpoint}/${id}`, lead);
  return response.data.data;
};

export const deleteNewBikeLead = async (id: number) => {
  const response = await api.delete(`${newBikeLeadsEndpoint}/${id}`);
  return response.data.message;
};
