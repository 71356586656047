import api from '../../api/apiIndex';
import { RcFile } from 'antd/es/upload';

const basePath = 'v2/image';

export type TImage = string | Blob | RcFile;

export type TUploadImage = {
  file: TImage;
  path: string;
  extraBodyData?: Record<string, unknown>;
  onProgress?: (progress: { percent: number }) => void;
};

export const uploadImage = async ({
  file,
  path,
  extraBodyData,
  onProgress,
}: TUploadImage) => {
  const response = await api.post(
    `${basePath}/${path}`,
    {
      image: file,
      ...extraBodyData,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: ({ total, loaded }) => {
        if (onProgress && total)
          onProgress({
            percent: (loaded / total) * 100,
          });
      },
    },
  );
  return response.data;
};
