import { useParams } from 'react-router-dom';
import NewCarsDealLayout from '../../../../listings/cars/new_cars/components/NewCarsDealLayout';

const ModelDeals = () => {
  const { modelSlug } = useParams();

  return <NewCarsDealLayout modelSlug={modelSlug!} />;
};

const modelDeals = {
  element: <ModelDeals />,
  state: 'string',
  index: false,
  path: '/listings/cars/new-cars/:brandSlug/:modelSlug',
  allowedGroups: ['ONE AUTOCAR', 'Automotive'],
};

export { ModelDeals, modelDeals };
