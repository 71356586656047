import { useQuery, useMutation } from '@tanstack/react-query';
import {
  ICreateRentalDealer,
  IEditRentalDealer,
  activateRentalDealer,
  createRentalDealers,
  deactivateRentalDealer,
  deleteRentalDealer,
  editRentalDealer,
  getRentalsDealers,
} from '../apis';
import { RentalDealer } from '../../../models/dealers/rentals/RentalDealer';
import { getRentalsDealersNames } from '../apis/getRentalDealersNames';
import { RentalDealerOption } from '../../../models/dealers/rentals/RentalDealerOption';
import { TImage, uploadImage } from '../../../common/utils/imageApi';

interface IUseGetRentalDealers {
  enabled: boolean;
  active?: boolean;
  type?: string;
}

interface IUseGetRentalDealersNames {
  enabled: boolean;
}

export const ALL_Rental_DEALERS_NAMES_KEY = ['ALL_Rental_DEALERS_NAMES'];

export const useGetRentalDealers = ({
  enabled,
  active,
  type,
}: IUseGetRentalDealers) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_Rental_DEALERS_NAMES_KEY,
    queryFn: (): Promise<RentalDealer[]> => getRentalsDealers({ active, type }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const ALL_Rental_DEALERS_KEY = ['ALL_Rental_DEALERS'];

export const useGetRentalDealersNames = ({
  enabled,
}: IUseGetRentalDealersNames) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_Rental_DEALERS_KEY,
    queryFn: (): Promise<RentalDealerOption[]> => getRentalsDealersNames(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateRentalDealers = () => {
  return useMutation({
    mutationFn: (rentalDealer: ICreateRentalDealer) =>
      createRentalDealers(rentalDealer),
  });
};

export const useEditRentalDealers = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditRentalDealer }) =>
      editRentalDealer({ id, data }),
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteRentalDealers = () => {
  return useMutation({
    mutationFn: (id: number) => deleteRentalDealer(id),
  });
};

export const useUploadRentalDealerImage = () => {
  return useMutation({
    mutationFn: ({
      file,
      path,
      onProgress,
    }: {
      file: TImage;
      path: string;
      onProgress: any;
    }) => uploadImage({ file, path, onProgress }),
  });
};

export const useActivateRentalDealer = () => {
  return useMutation({
    mutationFn: (id: number) => activateRentalDealer(id),
  });
};

export const useDeactivateRentalDealer = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateRentalDealer(id),
  });
};
