import { ReviewsLayout } from '../../../reviews/components';
import { RouteType } from '../../routes/routesConfig';

const Reviews = () => {
  return <ReviewsLayout />;
};
const ReviewsRoutes: RouteType = {
  path: '/reviews',
  index: true,
  element: <Reviews />,
  state: 'reviews',
  sideBarProps: {
    displayText: 'Reviews',
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Reviews, ReviewsRoutes };
