import { useQuery } from '@tanstack/react-query';
import { getActiveCountries } from '../countriesApi';
import { ICountry } from '../../models/countries/Country';

export const ALL_ACTIVE_COUNTRIES = ['allActiveCountries'];

interface IUseCountries {
  enabled: boolean;
}

export const useCountries = ({ enabled }: IUseCountries) => {
  return useQuery<ICountry[]>({
    queryKey: ALL_ACTIVE_COUNTRIES,
    queryFn: getActiveCountries,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};
