import exp from 'constants';
import api from '../../../../api/apiIndex';

const bikeBatteryRangeEndpoint = 'v2/admin/resources/bikes/battery-ranges';

export interface IGetBikeBatteryRanges {
  active?: boolean;
}

export interface IBikeBatteryRangePayload {
  name: string;
  arabic_name: string;
  status: number;
  engine_types: Array<number>;
}

export const getBikeBatteryRanges = async ({
  active,
}: IGetBikeBatteryRanges) => {
  try {
    const response = await api.get(bikeBatteryRangeEndpoint, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeBatteryRange = async (
  data: IBikeBatteryRangePayload,
) => {
  const response = await api.post(bikeBatteryRangeEndpoint, data);
  return response.data;
};

export const updateBikeBatteryRange = async (
  id: number,
  data: IBikeBatteryRangePayload,
) => {
  const response = await api.put(`${bikeBatteryRangeEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeBatteryRange = async (id: number) => {
  try {
    const response = await api.delete(`${bikeBatteryRangeEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeBatteryRange = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeBatteryRangeEndpoint}/activate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeBatteryRange = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeBatteryRangeEndpoint}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
