import { useState } from 'react';
import { SkeletonLoader } from '../../../../components/misc';
import { Layout } from '../../../../components/Layout';
import { ICarModel, INewCarDeal } from '../../../../models/resources';
import { useGetModelBySlug } from '../../../../resources/cars/car_models/hooks/useModels';
import { useGetDeals } from '../hooks/useDeals';
import { DealModal } from './DealModal';
import {
  DealsList,
  HeaderComponent,
  ModelDisplayComponent,
} from './NewCarsDealLayoutComponents';

type NewCarsDealLayoutProps = {
  modelSlug: string;
};
const NewCarsDealLayout = ({ modelSlug }: NewCarsDealLayoutProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { data: model, isLoading: isLoadingModel } =
    useGetModelBySlug(modelSlug);
  const { data: deals, isLoading: isLoadingDeals } = useGetDeals(model?.slug);

  const [displayedImage, setDisplayedImage] = useState<string>();
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditingDeal, setIsEditingDeal] = useState(false);
  const [currentDeal, setCurrentDeal] = useState<any>();

  const updateDisplayedImage = (image: string) => {
    setDisplayedImage(image);
    setCurrentImageIndex(model?.image.indexOf(image) || 0);
  };

  const openEmptyForm = () => {
    setIsFormOpen(true);
    setIsEditingDeal(false);
    setCurrentDeal(null);
  };

  const onEditClick = (deal: INewCarDeal) => {
    setCurrentDeal(deal);
    setIsEditingDeal(true);
    setIsFormOpen(true);
  };

  const onDeleteClick = (deal: INewCarDeal) => {
    setCurrentDeal(deal);
  };

  return (
    <Layout>
      {isLoadingModel ? (
        <SkeletonLoader />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '30px',
            padding: '10px',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '30px',
            }}
          >
            <HeaderComponent openEmptyForm={openEmptyForm} model={model} />
            <ModelDisplayComponent
              setCurrentImageIndex={setCurrentImageIndex}
              currentImageIndex={currentImageIndex}
              displayedImage={displayedImage}
              modelImages={model?.image?.map((image: ICarModel) => image) || []}
              onImageClick={updateDisplayedImage}
              model={model}
              selectedLanguage={selectedLanguage}
              onLanguageChange={e =>
                setSelectedLanguage(prev => e.value || prev)
              }
              isLoading={isLoadingModel}
            />
          </div>
          <DealsList
            deals={deals}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            loading={isLoadingDeals}
          />
          <DealModal
            model={model}
            currentDeal={currentDeal}
            isEditingDeal={isEditingDeal}
            isFormOpen={isFormOpen}
            setCurrentDeal={setCurrentDeal}
            setIsFormOpen={setIsFormOpen}
          />
        </div>
      )}
    </Layout>
  );
};

export default NewCarsDealLayout;
