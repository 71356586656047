import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICountryPayload,
  activateCountry,
  createCountry,
  deactivateCountry,
  deleteCountry,
  getCountries,
  updateCountry,
} from '../apis';
import { queryClient } from '../../../App';

const ALL_COUNTRIES_KEY = ['countries'];

interface IUseCountries {
  enabled: boolean;
  active?: boolean;
}

export const useCountries = ({ enabled, active }: IUseCountries) => {
  return useQuery({
    queryKey: ALL_COUNTRIES_KEY,
    queryFn: () => getCountries({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateCountry = () => {
  return useMutation({
    mutationFn: (data: ICountryPayload) => createCountry(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_COUNTRIES_KEY);
    },
  });
};

export const useUpdateCountry = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICountryPayload }) => {
      return updateCountry(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_COUNTRIES_KEY);
    },
  });
};

export const useDeleteCountry = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteCountry(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_COUNTRIES_KEY);
    },
  });
};

export const useActivateCountry = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return activateCountry(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_COUNTRIES_KEY);
    },
  });
};

export const useDeactivateCountry = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deactivateCountry(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_COUNTRIES_KEY);
    },
  });
};
