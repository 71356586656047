import { UserGroups } from '../../../common/constants';
import { NewBikeLeadsLayout } from '../../../leads/new_bikes';

const NewBikeLeads = () => {
  return <NewBikeLeadsLayout />;
};

const newBikeLeadsRoute = {
  path: '/leads/new-bikes',
  index: true,
  element: <NewBikeLeads />,
  state: 'newBikeLeads',
  sideBarProps: {
    displayText: 'New Bikes',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { NewBikeLeads, newBikeLeadsRoute };
