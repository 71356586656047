import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeTypePayload,
  activateBikeType,
  createBikeType,
  deactivateBikeType,
  deleteBikeType,
  getBikeTypes,
  updateBikeType,
} from '../apis/bikeTypesApi';
import { queryClient } from '../../../../App';

interface IUserBikeTypes {
  enabled: boolean;
  active?: boolean;
}

const BIKE_TYPE_QUERY_KEY = ['bike_types'];

export const useBikeTypes = ({ enabled, active }: IUserBikeTypes) => {
  return useQuery({
    queryKey: [...BIKE_TYPE_QUERY_KEY, { active }],
    queryFn: () => getBikeTypes({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeType = () => {
  return useMutation({
    mutationFn: (data: IBikeTypePayload) => createBikeType(data),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TYPE_QUERY_KEY);
    },
  });
};

export const useUpdateBikeType = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeTypePayload }) =>
      updateBikeType(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TYPE_QUERY_KEY);
    },
  });
};

export const useDeleteBikeType = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TYPE_QUERY_KEY);
    },
  });
};

export const useActivateBikeType = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TYPE_QUERY_KEY);
    },
  });
};

export const useDeactivateBikeType = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeType(id),
    onSuccess: () => {
      queryClient.invalidateQueries(BIKE_TYPE_QUERY_KEY);
    },
  });
};
