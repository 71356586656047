import { useState } from 'react';
import { Layout } from '../../../components/Layout';
import { ColumnsType } from 'antd/es/table';
import { Table, Button, DatePicker, Space } from 'antd';
import { useUsedCarLeads } from '../hooks/useUsedCarLeads';
import { useNavigate } from 'react-router-dom';
import { CloudDownloadOutlined, EyeTwoTone } from '@ant-design/icons';
import { exportUsedCarLeads } from '../utils/usedCarLeadsApi';
import dayjs from 'dayjs';
import { BrandLead } from '../../../models/overview/BrandLead';
import { ModelLead } from '../../../models/overview/ModelLead';
import { UsedCarLead } from '../../../models/overview/UsedCarLead';
import UsedCarLeadModal from './UsedCarLeadModal';

export const UsedCarLeadsLayout = () => {
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<string[]>(['', '']);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const navigate = useNavigate();

  const { RangePicker } = DatePicker;

  const tableColumns: ColumnsType<UsedCarLead> = [
    {
      title: 'Dealer',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Leads',
      dataIndex: 'inquiries_count',
      key: 'inquiries_count',
    },
    {
      title: 'Latest Lead Date',
      dataIndex: 'latest_inquiry_date',
      key: 'latest_inquiry_date',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        dayjs(a.latest_inquiry_date).valueOf() -
        dayjs(b.latest_inquiry_date).valueOf(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: 150,
      render: (_, rowData) => {
        return (
          <EyeTwoTone
            style={{ fontSize: '1rem' }}
            onClick={() => {
              navigate(`${rowData.slug}`);
            }}
          />
        );
      },
    },
  ];

  const getBrandsExpandableColumns = (dealerSlug: string) => {
    const brandsExpandableColumns: ColumnsType<BrandLead> = [
      {
        title: 'Brand',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Leads',
        dataIndex: 'inquiries_count',
        key: 'inquiries_count',
      },
      {
        title: 'Latest Lead Date',
        dataIndex: 'latest_inquiry_date',
        key: 'latest_inquiry_date',
        render: (rowData): string => {
          return dayjs(rowData).format('DD/MM/YY HH:mm A');
        },
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          dayjs(a.latest_inquiry_date).valueOf() -
          dayjs(b.latest_inquiry_date).valueOf(),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        width: 150,
        render: (_, rowData) => {
          return (
            <EyeTwoTone
              style={{ fontSize: '1rem' }}
              onClick={() => {
                navigate(`${dealerSlug}/${rowData.slug}`);
              }}
            />
          );
        },
      },
    ];

    return brandsExpandableColumns;
  };

  const getModelsExpandableColumns = (
    dealerSlug: string,
    brandSlug: string,
  ) => {
    const modelsExpandableColumns: ColumnsType<ModelLead> = [
      {
        title: 'Model',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Leads',
        dataIndex: 'inquiries_count',
        key: 'inquiries_count',
      },
      {
        title: 'Latest Lead Date',
        dataIndex: 'latest_inquiry_date',
        key: 'latest_inquiry_date',
        render: (rowData): string => {
          return dayjs(rowData).format('DD/MM/YY HH:mm A');
        },
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          dayjs(a.latest_inquiry_date).valueOf() -
          dayjs(b.latest_inquiry_date).valueOf(),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        width: 150,
        render: (_, rowData) => {
          return (
            <EyeTwoTone
              style={{ fontSize: '1rem' }}
              onClick={() => {
                navigate(`${dealerSlug}/${brandSlug}/${rowData.slug}`);
              }}
            />
          );
        },
      },
    ];
    return modelsExpandableColumns;
  };

  const { data: usedCarLeads, isFetching: isFetchingUsedCarLeads } =
    useUsedCarLeads();

  const openEmptyForm = () => {
    setIsFormOpen(true);
  };

  return (
    <>
      <Layout>
        <Space
          className="mb-5"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div>
            <Button type="primary" onClick={openEmptyForm}>
              Create
            </Button>
          </div>
          <Space>
            <RangePicker
              onChange={(dates, dateRange) => setDateRange(dateRange)}
            />
            <Button
              type="primary"
              onClick={() =>
                exportUsedCarLeads({
                  setIsExporting,
                  from: dateRange[0],
                  to: dateRange[1],
                })
              }
              loading={isExporting}
              icon={<CloudDownloadOutlined />}
            >
              Export
            </Button>
          </Space>
        </Space>
        <Table
          dataSource={usedCarLeads}
          pagination={false}
          rowKey="id"
          scroll={{ x: 1300, y: 650 }}
          columns={tableColumns}
          loading={isFetchingUsedCarLeads}
          expandable={{
            expandedRowRender: recordDealer => {
              return (
                <Table
                  dataSource={recordDealer.brands}
                  pagination={false}
                  rowKey="id"
                  columns={getBrandsExpandableColumns(recordDealer.slug)}
                  expandable={{
                    expandedRowRender: recordBrand => {
                      return (
                        <Table
                          dataSource={recordBrand.models}
                          pagination={false}
                          rowKey="id"
                          columns={getModelsExpandableColumns(
                            recordDealer.slug,
                            recordBrand.slug,
                          )}
                        />
                      );
                    },
                  }}
                />
              );
            },
          }}
        />
        {isFormOpen && (
          <UsedCarLeadModal
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            currentLead={null}
          />
        )}
      </Layout>
    </>
  );
};
