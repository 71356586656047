import UploadedImagePreview from '../../../../../components/misc/UploadedImagePreview';
import { ICarModel } from '../../../../../models/resources';
import { Button } from 'antd';

interface HeaderComponentProps {
  openEmptyForm: () => void;
  model?: ICarModel;
}

export const HeaderComponent = ({
  openEmptyForm,
  model,
}: HeaderComponentProps) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row-reverse',
      }}
    >
      <Button onClick={openEmptyForm} type="primary">
        Add
      </Button>
      <div style={{ height: '50px', width: '90px', objectFit: 'cover' }}>
        <UploadedImagePreview
          url={model?.brand?.original_logo}
          preview={false}
        />
      </div>
    </div>
  );
};
