import api from '../../../api/apiIndex';

const exteriorColorEndpointv2 = 'v2/admin/resources/cars/exterior-colors';

export interface IGetExteriorColors {
  active?: boolean;
}

export interface ICarExteriorColorPayload {
  name: string;
  arabic_name: string;
}

const getCarExteriorColors = async ({ active }: IGetExteriorColors) => {
  try {
    const response = await api.get(exteriorColorEndpointv2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createCarExteriorColor = async (data: ICarExteriorColorPayload) => {
  try {
    const response = await api.post(exteriorColorEndpointv2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateCarExteriorColor = async (
  id: number,
  data: ICarExteriorColorPayload,
) => {
  try {
    const response = await api.put(`${exteriorColorEndpointv2}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteCarExteriorColor = async (id: number) => {
  try {
    const response = await api.delete(`${exteriorColorEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCarExteriorColor = async (id: number) => {
  try {
    const response = await api.put(`${exteriorColorEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCarExteriorColor = async (id: number) => {
  try {
    const response = await api.put(
      `${exteriorColorEndpointv2}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getCarExteriorColors,
  createCarExteriorColor,
  updateCarExteriorColor,
  deleteCarExteriorColor,
};
