import CarExteriorColorsLayout from '../../../resources/cars/car_exterior_color/components/CarExteriorColorsLayout';
import { RouteType } from '../../routes/routesConfig';

const ExteriorColor = () => {
  return <CarExteriorColorsLayout />;
};

const exteriorColorRoute: RouteType = {
  element: <ExteriorColor />,
  state: 'exterior-color',
  index: true,
  path: '/resources/cars/exterior-color',
  sideBarProps: {
    displayText: 'Exterior Color',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { ExteriorColor, exteriorColorRoute };
