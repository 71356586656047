import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getUsedCarLeadsDetails,
  getUsedCarLeads,
  IEditUsedCarLead,
  editUsedCarLead,
  deleteUsedCarLead,
  ICreateUsedCarLead,
  createUsedCarLead,
} from '../utils/usedCarLeadsApi';

interface IUseLeadsDetails {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

export const useUsedCarLeads = () => {
  return useQuery({
    queryKey: ['usedCarLeads'],
    queryFn: () => getUsedCarLeads(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useUsedCarLeadsDetail = ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: IUseLeadsDetails) => {
  return useQuery({
    queryKey: ['usedCarLeadsDetails', dealerSlug, brandSlug, modelSlug],
    queryFn: () =>
      getUsedCarLeadsDetails({
        dealerSlug,
        brandSlug,
        modelSlug,
      }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useCreateUsedCarLead = () => {
  return useMutation({
    mutationFn: (usedCarLead: ICreateUsedCarLead) =>
      createUsedCarLead(usedCarLead),
  });
};

export const useEditUsedCarLead = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IEditUsedCarLead }) =>
      editUsedCarLead({ id, data }),
    onError: (error: any) => {
      console.log(error);
    },
  });
};

export const useDeleteUsedCarLead = () => {
  return useMutation({
    mutationFn: (id: number) => deleteUsedCarLead(id),
  });
};
