import { CitiesLayout } from '../../../resources/cities';
import { RouteType } from '../../routes/routesConfig';

export const City = () => {
  return <CitiesLayout />;
};

export const cityRoute: RouteType = {
  element: <City />,
  state: 'city',
  index: true,
  path: '/resources/cities',
  sideBarProps: {
    displayText: 'Cities',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};
