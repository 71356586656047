import api from '../../../../api/apiIndex';

const bikeCylindersEndpoint = 'v2/admin/resources/bikes/cylinder';

export interface IGetBikeCylinders {
  active?: boolean;
}

export type IBikeCylindersPayload = {
  name: string;
  arabic_name: string;
  status: number;
  bike_engine_types: Array<string>;
};

export const getBikeCylinders = async ({ active }: IGetBikeCylinders) => {
  try {
    const response = await api.get(bikeCylindersEndpoint, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeCylinder = async (data: IBikeCylindersPayload) => {
  const response = await api.post(bikeCylindersEndpoint, data);
  return response.data;
};

export const updateBikeCylinder = async (
  id: number,
  data: IBikeCylindersPayload,
) => {
  const response = await api.put(`${bikeCylindersEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeCylinder = async (id: number) => {
  try {
    const response = await api.delete(`${bikeCylindersEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeCylinder = async (id: number) => {
  try {
    const response = await api.put(`${bikeCylindersEndpoint}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeCylinder = async (id: number) => {
  try {
    const response = await api.put(`${bikeCylindersEndpoint}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
