import api from '../../../api/apiIndex';

const conditionEndpointv2 = 'v2/admin/resources/cars/conditions';

export interface ICarConditionPayload {
  name: string;
  arabic_name: string;
}

export interface IGetCarConditions {
  active?: boolean;
}

const getCarConditions = async ({ active }: IGetCarConditions) => {
  try {
    const response = await api.get(conditionEndpointv2, {
      params: {
        active,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createCarCondition = async (data: ICarConditionPayload) => {
  try {
    const response = await api.post(conditionEndpointv2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateCarCondition = async (id: number, data: ICarConditionPayload) => {
  try {
    const response = await api.put(`${conditionEndpointv2}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteCarCondition = async (id: number) => {
  try {
    const response = await api.delete(`${conditionEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCarCondition = async (id: number) => {
  try {
    const response = await api.put(`${conditionEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCarCondition = async (id: number) => {
  try {
    const response = await api.put(`${conditionEndpointv2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getCarConditions,
  createCarCondition,
  updateCarCondition,
  deleteCarCondition,
};
