import { UserGroups } from '../../../common/constants';
import { RamadanOfferLeadsLayout } from '../../../leads/ramadan_offers/components/RamadanOfferLeadsLayout';

const RamadanOfferLeads = () => {
  return <RamadanOfferLeadsLayout />;
};

const ramadanOfferLeadsRoute = {
  path: '/leads/ramadan-offers',
  index: true,
  element: <RamadanOfferLeads />,
  state: 'ramadanOfferLeads',
  sideBarProps: {
    displayText: 'Ramadan Offers',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { RamadanOfferLeads, ramadanOfferLeadsRoute };
