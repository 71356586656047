import { Card, Select, Space } from 'antd';
import {
  useCreateLeadEmail,
  useDeleteLeadEmail,
  useGetLeadEmails,
} from '../hooks';
import { useState } from 'react';
import { BrandLeadEmailLayout } from './BrandLeadEmailLayout';
import useAuth from '../../../hooks/useAuth';
import { UserGroups } from '../../../common/constants';
import { DealerLeadEmailsForUsedCarsLayout } from './DealerLeadEmailsUsedLayout';
import OACSubmittedLeadsEmailsLayouts from './OneAutocarSubmittedLeadsEmailsLayouts';

export const LeadEmailsLayout = () => {
  const { userInfo } = useAuth();

  const [search, setSearch] = useState<boolean>(false);
  const { data: leadEmails, isLoading: isFetching } = useGetLeadEmails({
    enabled: true,
  });

  const { mutateAsync: createLeadEmail, isLoading: isCreating } =
    useCreateLeadEmail();

  const { mutateAsync: deleteLeadEmail, isLoading: isDeleting } =
    useDeleteLeadEmail();

  const handleAllEmailNotificationsSelect = async (value: string) => {
    await createLeadEmail(value);
  };

  const handleAllEmailNotificationsDeselect = async (value: any) => {
    await deleteLeadEmail(value);
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="All Email Notifications">
          <Select
            mode="tags"
            style={{ width: '100%' }}
            onDeselect={handleAllEmailNotificationsDeselect}
            onSelect={handleAllEmailNotificationsSelect}
            tokenSeparators={[',']}
            onSearch={handleSearch}
            loading={isFetching || isCreating || isDeleting}
            disabled={isFetching || isCreating || isDeleting}
            options={leadEmails?.unregisteredEmails?.map(leadEmail => {
              return { value: leadEmail?.email };
            })}
            popupClassName={`select-popup-hide-selected-options ${
              !search &&
              leadEmails?.unregisteredEmails?.length === 0 &&
              `select-popup-hide`
            }`}
            value={leadEmails?.registeredEmails?.map(lead => lead.email)}
          />
        </Card>

        {userInfo?.group === UserGroups.OAC && (
          <OACSubmittedLeadsEmailsLayouts />
        )}

        {userInfo?.group === UserGroups.Automotive && (
          <>
            <DealerLeadEmailsForUsedCarsLayout />
            <BrandLeadEmailLayout />
          </>
        )}
      </Space>
    </>
  );
};
