import { useQuery } from '@tanstack/react-query';
import { getBannerResources } from '../bannerResourcesApi';
import { IBannerResource } from '../../../models/ads/BannerResource';

interface IUseGetBanners {
  enabled: boolean;
}

export const ALL_BANNER_RESOURCES_KEY = ['ALL_BANNER_RESOURCES'];

export const useGetBannerResources = ({ enabled }: IUseGetBanners) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_BANNER_RESOURCES_KEY,
    queryFn: (): Promise<IBannerResource[]> => getBannerResources(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};
