import api from '../../../api/apiIndex';
import { downloadFile } from '../../../helpers/helperFunctions';
import { LeadContacted } from '../../constants';

const usedBikeLeadsEndpoint = '/v2/admin/inquiries/used-bike-inquiries';

interface ILeadsDetailsProps {
  dealerSlug: string | undefined;
  brandSlug?: string | undefined;
  modelSlug?: string | undefined;
}

interface IExportUsedBikeLeads {
  setIsExporting: (newValue: boolean) => void;
  from: string | null;
  to: string | null;
}

export interface ICreateUsedBikeLead {
  name: string;
  email: string;
  mobile_number: string;
  used_bike_id: number;
  source: string;
  contacted: LeadContacted;
  notes?: string;
}

export interface IEditUsedBikeLead {
  name?: string;
  email?: string;
  mobile_number?: string;
  source?: string;
  contacted?: LeadContacted;
  notes?: string;
}

export const getUsedBikeLeads = async () => {
  try {
    const response = await api.get(usedBikeLeadsEndpoint);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getUsedBikeLeadsDetails = async ({
  dealerSlug,
  brandSlug,
  modelSlug,
}: ILeadsDetailsProps) => {
  if (dealerSlug && brandSlug && modelSlug) {
    const response = await api.get(
      `${usedBikeLeadsEndpoint}/${dealerSlug}/${brandSlug}/${modelSlug}`,
    );
    return response.data;
  } else if (dealerSlug && brandSlug) {
    const response = await api.get(
      `${usedBikeLeadsEndpoint}/${dealerSlug}/${brandSlug}`,
    );
    return response.data;
  } else if (dealerSlug) {
    const response = await api.get(`${usedBikeLeadsEndpoint}/${dealerSlug}`);
    return response.data;
  }
};

export const exportUsedBikeLeads = async ({
  setIsExporting,
  from,
  to,
}: IExportUsedBikeLeads) => {
  setIsExporting(true);
  try {
    const response = await api.get(
      `${usedBikeLeadsEndpoint}/export?from=${from}&to=${to}`,
      {
        responseType: 'blob',
      },
    );
    downloadFile(response.data, 'Used_Bike_Leads_Report');
  } catch (error: any) {
    console.error(error);
  } finally {
    setIsExporting(false);
  }
};

export const createUsedBikeLead = async (lead: ICreateUsedBikeLead) => {
  const response = await api.post(usedBikeLeadsEndpoint, lead);
  return response.data.data;
};

export const editUsedBikeLead = async ({
  id,
  data: lead,
}: {
  id: number;
  data: IEditUsedBikeLead;
}) => {
  const response = await api.put(`${usedBikeLeadsEndpoint}/${id}`, lead);
  return response.data.data;
};

export const deleteUsedBikeLead = async (id: number) => {
  try {
    const response = await api.delete(`${usedBikeLeadsEndpoint}/${id}`);
    return response.data.message;
  } catch (error) {
    console.error(error);
  }
};
