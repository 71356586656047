import { Breadcrumb, Layout } from 'antd';
import type { TimeRangePickerProps } from 'antd';
import { Statistics } from './components/Statistics';
import dayjs, { Dayjs } from 'dayjs';
import { useAdsBannerMetrics, useBrandViews } from './apis/useAnalytics';
import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { BrandsAnalyticsLayout } from './BrandsAnalyticsChart';
import { AdsBannerAnalyticsLayout } from './AdsBannersChart';
import { useBrandNames } from '../resources/cars/car_brands/hooks';

const { Content } = Layout;

const rangePresets: TimeRangePickerProps['presets'] = [
  { label: 'Last Week', value: [dayjs().add(-7, 'd'), dayjs()] },
  { label: 'Last 2 Weeks', value: [dayjs().add(-14, 'd'), dayjs()] },
  { label: 'Last Month', value: [dayjs().add(-30, 'd'), dayjs()] },
  { label: 'Last Quarter', value: [dayjs().add(-90, 'd'), dayjs()] },
  { label: 'Last Year', value: [dayjs().add(-365, 'd'), dayjs()] },
];

export const AnalyticsLayout = () => {
  const [selectedBrand, setSelectedBrand] = useState<number | undefined>();
  const user = useAuth();
  const [range, setRange] = useState([
    dayjs().add(-7, 'd').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ]);
  const [adsRange, setAdsRange] = useState([
    dayjs().add(-7, 'd').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ]);
  const [event, setEvent] = useState<'clicks' | 'impressions'>('impressions');
  const [advertiser, setAdvertiser] = useState<string | undefined>();

  const [timePeriod, setTimePeriod] = useState<'day' | 'month' | 'year'>('day');
  const [timePeriodAds, setTimePeriodAds] = useState<'day' | 'month' | 'year'>(
    'day',
  );
  const { data, isFetching } = useBrandViews({
    from: range[0],
    to: range[1],
    per: timePeriod,
    id: selectedBrand as number,
    enabled: !!selectedBrand,
  });

  const iatToDate = (iat: number) => {
    const date = dayjs.unix(iat).format('YYYY-MM-DD HH:mm:ss');
    return date;
  };
  const expToDate = (exp: number) => {
    const date = dayjs.unix(exp).format('YYYY-MM-DD HH:mm:ss');
    return date;
  };

  const { data: brandNames, isLoading: isLoadingBrandNames } = useBrandNames({
    enabled: true,
  });
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    if (dates) {
      if (dateStrings[0] === dateStrings[1]) {
        dateStrings[0] = dayjs(dateStrings[0])
          .add(-1, 'd')
          .format('YYYY-MM-DD');
      }
      setRange(dateStrings);
    }
  };

  const onAdsRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    if (dates) {
      if (dateStrings[0] === dateStrings[1]) {
        dateStrings[0] = dayjs(dateStrings[0])
          .add(-1, 'd')
          .format('YYYY-MM-DD');
      }
      setAdsRange(dateStrings);
    }
  };

  const { data: adsBannerData, isFetching: isFetchingAdsBanner } =
    useAdsBannerMetrics({
      from: adsRange[0],
      to: adsRange[1],
      per: timePeriodAds,
      event: event,
      enabled: !!advertiser && !!event,
    });

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 0' }}
        items={[
          {
            key: 'home',
            href: '/',
            title: 'Home',
          },
          { title: 'Analytics' },
        ]}
      />
      <Content
        style={{
          padding: 24,
          background: '#fff',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 2px 5px',
          borderRadius: '5px',
        }}
      >
        <Statistics />
      </Content>
      <BrandsAnalyticsLayout
        periodTime={timePeriod}
        data={data}
        isFetching={isFetching}
        selectedBrand={selectedBrand}
        setSelectedBrand={setSelectedBrand}
        range={range}
        setRange={setRange}
        setTimePeriod={setTimePeriod}
        brandNames={brandNames}
        isLoadingBrandNames={isLoadingBrandNames}
        onRangeChange={onRangeChange}
        rangePresets={rangePresets}
      />
      {/* {isSuperAdmin && (
        <AdsBannerAnalyticsLayout
          periodTime={timePeriodAds}
          data={adsBannerData}
          isFetching={isFetchingAdsBanner}
          setTimePeriod={setTimePeriodAds}
          advertiser={advertiser}
          rangePresets={rangePresets}
          onRangeChange={onAdsRangeChange}
          setEvent={setEvent}
          setAdvertiser={setAdvertiser}
          setSelectedEvent={setEvent}
          selectedEvent={event}
        />
      )} */}
    </>
  );
};
