import { useEffect } from 'react';
import { Form, Input, Select, Space, Button, Row, Col } from 'antd';
import { ICarModel, ICarModelT } from '../../../../models/resources';
import { useGetAllBrands } from '../../car_brands/hooks';
import { CarBrand } from '../../../../models/resources/CarBrand';
import { useGetAllModels } from '../../car_models/hooks';
import { ICarTrim } from '../../../../models/resources/CarTrim';

type AddEditTrimFormProps = {
  trim: ICarTrim | null;
  isEditing: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit: (model: ICarModel) => void;
};

const { Item } = Form;

const AddEditTrimForm = ({
  trim,
  isEditing,
  isLoading,
  onClose,
  onSubmit,
}: AddEditTrimFormProps) => {
  const [form] = Form.useForm();
  const brandId = Form.useWatch('brand_id', form);

  const { data: brands, isLoading: isLoadingBrands } = useGetAllBrands({
    active: true,
  });

  const { data: models, isLoading: isLoadingModels } = useGetAllModels({
    enabled: isEditing || !!brandId,
    brandId: brandId,
    active: true,
  });

  const initialValues = trim
    ? {
        name: trim?.name,
        arabic_name: trim?.t.find(t => t.lang === 'ar')?.name,
        brand_id: trim?.model?.brand?.id,
        model_id: trim?.model_id,
      }
    : undefined;

  const resetForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [form, trim]);

  return (
    <Form
      form={form}
      onFinish={(values: any) => {
        onSubmit(values);
        resetForm();
      }}
      layout="vertical"
      initialValues={initialValues}
      name="modelForm"
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter Name' }]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Arabic Name"
            name="arabic_name"
            rules={[
              { required: true, message: 'Please enter an Arabic Name  ' },
            ]}
          >
            <Input placeholder=" Arabic Name " />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Brand"
            name="brand_id"
            rules={[
              {
                required: true,
                message: 'Please select the brand',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a brand"
              optionFilterProp="children"
              loading={isLoadingBrands}
              disabled={isLoadingBrands}
              onChange={() => {
                form.setFieldsValue({
                  model_id: undefined,
                });
              }}
            >
              {brands?.map((brand: CarBrand) => (
                <Select.Option key={brand.id} value={brand.id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Model"
            name="model_id"
            rules={[
              {
                required: true,
                message: 'Please select the model of the car',
              },
            ]}
            dependencies={['brand']}
          >
            <Select
              showSearch
              placeholder="Select a model"
              optionFilterProp="children"
              loading={isLoadingModels}
              disabled={isLoadingModels || !brandId}
              onChange={() => {
                form.setFieldsValue({
                  type_id: undefined,
                });
              }}
            >
              {models?.map((model: any) => (
                <Select.Option key={model.id} value={model.id}>
                  {model.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>

      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              resetForm();
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit Model' : 'Add Model'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default AddEditTrimForm;
