import { Modal, message } from 'antd';
import { IBikeBatteryCapacity } from '../../../../models/resources/bikes/BikeBatterCapacity';
import {
  useCreateBikeBatteryCapacity,
  useUpdateBikeBatteryCapacity,
} from '../hooks';
import { IBikeBatteryCapacityPayload } from '../apis';
import { BikeBatteryCapacitiesAddEditForm } from './BikeBatteryCapacitiesAddEditForm';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';

interface BikeBatteryRangeModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentBikeBatteryCapacity: IBikeBatteryCapacity | null;
  setCurrentBikeBatteryCapacity: (value: IBikeBatteryCapacity | null) => void;
  bikeEngineTypes: IBikeEngineType[] | null;
}

export const BikeBatteryCapacitiesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBikeBatteryCapacity,
  setCurrentBikeBatteryCapacity,
  bikeEngineTypes,
}: BikeBatteryRangeModalProps) => {
  const { mutateAsync: createBikeBatteryCapacity, isLoading: isCreating } =
    useCreateBikeBatteryCapacity();
  const { mutateAsync: updateBikeBatteryCapacity, isLoading: isUpdating } =
    useUpdateBikeBatteryCapacity();

  const _create = async (data: IBikeBatteryCapacityPayload) => {
    if (data == null) return;
    const response = await createBikeBatteryCapacity(data);
    setIsFormOpen(false);
    setCurrentBikeBatteryCapacity(null);
    message.success('Battery Capacity Created Successfully');
    return response;
  };

  const _edit = async (data: IBikeBatteryCapacityPayload) => {
    if (data == null) return;
    const response = await updateBikeBatteryCapacity({
      id: currentBikeBatteryCapacity!.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentBikeBatteryCapacity(null);
    message.success('Battery Capacity Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Battery Capacity' : 'Add Battery Capacity'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeBatteryCapacity?.id}
        destroyOnClose
      >
        <BikeBatteryCapacitiesAddEditForm
          bikeBatteryCapacity={currentBikeBatteryCapacity}
          bikeEngineTypes={bikeEngineTypes}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
        />
      </Modal>
    </>
  );
};
