import api from '../../../../api/apiIndex';

const bikeModelsEndpoint = '/v2/admin/resources/bikes/models';

export interface IBikeModelPayload {
  name: string;
  arabic_name: string;
  brand_id: number;
  description: string;
  arabic_description: string;
  types: number[];
  add_images?: Array<{ id: number }>;
  remove_images?: Array<{ url: string }>;
}

export interface IGetBikeModels {
  dealerId?: number;
  brandId?: number;
  active?: boolean;
  type?: string;
}

const getBikeModels = async ({
  dealerId,
  brandId,
  active,
  type,
}: IGetBikeModels) => {
  try {
    const response = await api.get(`${bikeModelsEndpoint}`, {
      params: {
        dealer: dealerId,
        brand: brandId,
        active,
        type,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createBikeModel = async (data: IBikeModelPayload) => {
  const response = await api.post(bikeModelsEndpoint, data);

  return response.data;
};

const updateBikeModel = async (id: number, data: IBikeModelPayload) => {
  const response = await api.put(`${bikeModelsEndpoint}/${id}`, data);

  return response.data;
};

const deleteBikeModel = async (id: number) => {
  try {
    const response = await api.delete(`${bikeModelsEndpoint}/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeModel = async (id: number) => {
  try {
    const response = await api.put(`${bikeModelsEndpoint}/activate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeModel = async (id: number) => {
  try {
    const response = await api.put(`${bikeModelsEndpoint}/deactivate/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getBikeModelBySlug = async (slug: string) => {
  try {
    const response = await api.get(`${bikeModelsEndpoint}/${slug}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getBikeModels,
  getBikeModelBySlug,
  createBikeModel,
  updateBikeModel,
  deleteBikeModel,
};
