import CarFeaturesLayout from '../../../resources/cars/car_features/components/CarOptionalFeaturesLayout';
import { RouteType } from '../../routes/routesConfig';

const Features = () => {
  return <CarFeaturesLayout />;
};

const featuresRoute: RouteType = {
  element: <Features />,
  state: 'features',
  index: true,
  path: '/resources/cars/features',
  sideBarProps: {
    displayText: 'Features',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Features, featuresRoute };
