import api from '../../../api/apiIndex';

const featuresEndpointv2 = 'v2/admin/resources/cars/optional-features';

export interface IGetOptionalFeatures {
  active?: boolean;
}

export enum triStateOptions {
  'Two State' = 'TwoState',
  'Three State' = 'ThreeState',
}

export interface ICarOptionalFeaturesPayload {
  name: string;
  arabic_name: string;
  button_state: string;
  website_button_state: string;
  engine_types: Array<number>;
}

const getCarOptionalFeatures = async ({ active }: IGetOptionalFeatures) => {
  try {
    const response = await api.get(featuresEndpointv2, { params: { active } });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createCarOptionalFeature = async (data: ICarOptionalFeaturesPayload) => {
  try {
    const response = await api.post(featuresEndpointv2, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateCarOptionalFeature = async (
  id: number,
  data: ICarOptionalFeaturesPayload,
) => {
  try {
    const response = await api.put(`${featuresEndpointv2}/${id}`, data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteCarOptionalFeature = async (id: number) => {
  try {
    const response = await api.delete(`${featuresEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateCarOptionalFeature = async (id: number) => {
  try {
    const response = await api.put(`${featuresEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateCarOptionalFeature = async (id: number) => {
  try {
    const response = await api.put(`${featuresEndpointv2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getCarOptionalFeatures,
  createCarOptionalFeature,
  updateCarOptionalFeature,
  deleteCarOptionalFeature,
};
