import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import {
  createOACSubmittedLeadsEmail,
  deleteOACSubmittedLeadsEmail,
  getOACSubmittedLeadsEmails,
} from '../apis';

interface IUseGetOACSubmittedLeadsEmails {
  enabled: boolean;
}

const All_OAC_SUBMITTED_LEADS_EMAILS = ['All_OAC_SUBMITTED_LEADS_EMAILS'];

export const useGetOACSubmittedLeadsEmails = ({
  enabled,
}: IUseGetOACSubmittedLeadsEmails) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: All_OAC_SUBMITTED_LEADS_EMAILS,
    queryFn: () => getOACSubmittedLeadsEmails(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateOACSubmittedLeadsEmail = () => {
  return useMutation({
    mutationFn: createOACSubmittedLeadsEmail,
    onSuccess: () => {
      queryClient.invalidateQueries(All_OAC_SUBMITTED_LEADS_EMAILS);
    },
  });
};

export const useDeleteOACSubmittedLeadsEmail = () => {
  return useMutation({
    mutationFn: deleteOACSubmittedLeadsEmail,
    onSuccess: () => {
      queryClient.invalidateQueries(All_OAC_SUBMITTED_LEADS_EMAILS);
    },
  });
};
