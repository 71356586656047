import { Button, Checkbox, Form, Input } from 'antd';
import { useLoginForm } from '../hooks/useLoginForm';
import { TLoginMutation } from '../hooks/useLogin';
import { CheckOutlined, LoginOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

export interface ILoginFields extends TLoginMutation {
  remember: boolean;
}

const REDIRECT_TIMEOUT = 500;

const LoginForm = () => {
  const { handleSubmit, isLoading, isSuccess, error } = useLoginForm();

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.location.href = '/';
      }, REDIRECT_TIMEOUT);
    }
  }, [isSuccess]);

  return (
    <div className="form flex flex-column pt-3 pb-3 pr-4 pl-4 row-gap-1 flex-grow-1">
      <div className="flex align-items-center justify-content-between">
        <div>
          <h3>Login</h3>
          <p>Welcome Back, please login to your account.</p>
        </div>
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item<ILoginFields>
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<ILoginFields>
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item<ILoginFields> name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item className="flex align-items-center justify-content-end">
          <div className="flex align-items-center gap-2">
            {error && <div style={{ color: 'red' }}>{error}</div>}
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{
                backgroundColor: isSuccess ? '#52c41a' : undefined,
              }}
              icon={isSuccess ? <CheckOutlined /> : <LoginOutlined />}
              size="large"
            >
              {isSuccess ? 'Success' : 'Login'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginForm;
