import { UserGroups } from '../../../../common/constants';
import BikeTrimsLayout from '../../../../resources/bikes/bike_trims/components/BikeTrimsLayout';
import { RouteType } from '../../../routes/routesConfig';

const BikeTrims = () => {
  return <BikeTrimsLayout />;
};

const bikeTrimsRoute: RouteType = {
  element: <BikeTrims />,
  state: 'trims',
  index: true,
  path: '/resources/bikes/trims',
  sideBarProps: {
    displayText: 'Trims',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeTrims, bikeTrimsRoute };
