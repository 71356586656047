import api from '../../../../api/apiIndex';

const bikeEngineSizeEndPointV2 = '/v2/admin/resources/bikes/engine-size';

export interface IGetBikeEngineSizes {
  active?: boolean;
}

export interface IBikeEngineSizePayload {
  name: string;
  arabic_name: string;
  bike_engine_types: number[];
}

export const getBikeEngineSizes = async ({ active }: IGetBikeEngineSizes) => {
  try {
    const response = await api.get(bikeEngineSizeEndPointV2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeEngineSize = async (data: IBikeEngineSizePayload) => {
  const response = await api.post(bikeEngineSizeEndPointV2, data);
  return response.data;
};

export const updateBikeEngineSize = async (
  id: number,
  data: IBikeEngineSizePayload,
) => {
  const response = await api.put(`${bikeEngineSizeEndPointV2}/${id}`, data);
  return response.data;
};

export const deleteBikeEngineSize = async (id: number) => {
  try {
    const response = await api.delete(`${bikeEngineSizeEndPointV2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeEngineSize = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeEngineSizeEndPointV2}/activate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeEngineSize = async (id: number) => {
  try {
    const response = await api.put(
      `${bikeEngineSizeEndPointV2}/deactivate/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
