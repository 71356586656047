import { CTAButton } from '../../components/buttons/CTAButton';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import ReloadTableButton from './ReloadTableButton';

type DataTableHeaderProps = {
  title: string;
  hasExport?: boolean;
  onExport?: () => void;
  isExporting?: boolean;
  activeDateRange: any;
  setActiveDateRange: (range: any) => void;
  onReload: () => void;
};

const DataTableHeader = (props: DataTableHeaderProps) => {
  return (
    <div className="flex justify-content-between">
      <h1>{props.title}</h1>
      {props.hasExport && props.onExport ? (
        <div className="flex column-gap-3 align-items-center">
          <ReloadTableButton onClick={props.onReload} />
          <Calendar
            className="w-full"
            showButtonBar
            value={props.activeDateRange}
            onChange={(e: CalendarChangeEvent) =>
              props.setActiveDateRange(e.value)
            }
            selectionMode="range"
            readOnlyInput
            placeholder="Please select date range."
            showIcon={true}
            dateFormat="yy-mm-dd"
          />
          <div style={{ width: 'fit-content' }}>
            <CTAButton
              text="Export"
              onClick={props.onExport}
              isLoading={props.isExporting || false}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default DataTableHeader;
