import { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Table, Button, Popconfirm, message, Tag } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  CAR_CONDITIONS_QUERY_KEY,
  useActivateCarCondition,
  useCarCondition,
  useDeactivateCarCondition,
  useDeleteCarCondition,
} from '../hooks/useCarCondition';
import { useQueryClient } from '@tanstack/react-query';
import { CarConditionModal } from './CarConditionModal';
import { Layout } from '../../../../components/Layout';
import { ICarCondition } from '../utils/apiUtils';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';

const CarConditionsLayout = () => {
  const [open, setOpen] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCarCondition, setSelectedCarCondition] = useState<
    ICarCondition | undefined
  >(undefined);

  const queryClient = useQueryClient();

  const { data: carConditions, isLoading: isLoadingCarConditions } =
    useCarCondition({ enabled: true });

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarCondition | null>(null);

  const { mutateAsync: deleteCarCondition, isLoading: isDeleting } =
    useDeleteCarCondition();

  const { mutateAsync: activateCarCondition, isLoading: isActivating } =
    useActivateCarCondition();

  const { mutateAsync: deactivateCarCondition, isLoading: isDeactivating } =
    useDeactivateCarCondition();

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateCarCondition(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Car Condition deactivated successfully');
          },
        })
      : activateCarCondition(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Car Condition activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteCarCondition(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: CAR_CONDITIONS_QUERY_KEY,
        });
        setSelectedCarCondition(undefined);
        message.success('Car Condition deleted successfully');
        setOpen(null);
      },
    });
  };

  const tableColumns: ColumnsType<ICarCondition> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (rowData): string => {
        return rowData ? rowData?.toString() : 'N/A';
      },
    },
    {
      title: 'Arabic Name',
      dataIndex: `${carConditions?.map(
        (conition: ICarCondition) => conition.t[0]?.name,
      )}`,
      key: `${carConditions?.map(
        (conition: ICarCondition) => conition.t[0]?.name,
      )}`,
      render: (text: any, record: ICarCondition) => {
        return record.t[0]?.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarCondition) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              onClick={() => {
                setSelectedCarCondition(rowData);
                handleOpenModal();
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Are you sure you want to delete this Car Condition?"
              open={open === rowData.id}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpen(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                onClick={() => {
                  setOpen(rowData.id);
                  setSelectedCarCondition(rowData);
                }}
              />
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              description={`Clicking "Yes" will ${
                rowData.is_active ? 'deactivate' : 'activate'
              } this car condition.`}
              open={openToggleActivateDialog === rowData}
              onConfirm={() =>
                handleToggleActivate(rowData.id, rowData.is_active)
              }
              okButtonProps={{
                loading: isActivating || isDeactivating,
              }}
              onCancel={() => setOpenToggleActivateDialog(null)}
              okText="Yes"
              placement="topRight"
            >
              {rowData.is_active ? (
                <InactiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              ) : (
                <ActiveIcon
                  onClick={() => {
                    setOpenToggleActivateDialog(rowData);
                  }}
                />
              )}
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={handleOpenModal}>
          Add
        </Button>
      </div>
      <Table
        dataSource={carConditions}
        rowKey="id"
        scroll={{ x: 1300, y: 720 }}
        columns={tableColumns}
        loading={isLoadingCarConditions}
        pagination={false}
      />
      <CarConditionModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedCarCondition={selectedCarCondition}
        setSelectedCarCondition={setSelectedCarCondition}
      />
    </Layout>
  );
};

export default CarConditionsLayout;
