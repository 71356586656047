import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeCylindersPayload,
  activateBikeCylinder,
  createBikeCylinder,
  deactivateBikeCylinder,
  deleteBikeCylinder,
  getBikeCylinders,
  updateBikeCylinder,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_BIKE_CYLINDERS_KEY = ['allBikeCylinders'];

interface IUseBikeCylinders {
  enabled: boolean;
  active?: boolean;
}

export const useGetBikeCylinders = ({ enabled, active }: IUseBikeCylinders) => {
  return useQuery({
    queryKey: ALL_BIKE_CYLINDERS_KEY,
    queryFn: () => getBikeCylinders({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeCylinder = () => {
  return useMutation({
    mutationFn: (data: IBikeCylindersPayload) => createBikeCylinder(data),
    mutationKey: ['createBikeCylinder'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_CYLINDERS_KEY);
    },
  });
};

export const useUpdateBikeCylinder = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBikeCylindersPayload }) =>
      updateBikeCylinder(id, data),
    mutationKey: ['updateBikeCylinder'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_CYLINDERS_KEY);
    },
  });
};

export const useDeleteBikeCylinder = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeCylinder(id),
    mutationKey: ['deleteBikeCylinder'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_CYLINDERS_KEY);
    },
  });
};

export const useActivateBikeCylinder = () => {
  return useMutation({
    mutationFn: (id: number) => activateBikeCylinder(id),
    mutationKey: ['activateBikeCylinder'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_CYLINDERS_KEY);
    },
  });
};

export const useDeactivateBikeCylinder = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateBikeCylinder(id),
    mutationKey: ['deactivateBikeCylinder'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_CYLINDERS_KEY);
    },
  });
};
