/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { RouteType } from '../../../routes/routesConfig';
import NewCar from '../../../../listings/cars/new_cars/components/NewCars';

const NewCars = () => {
  return <NewCar />;
};

const newCarsRoute: RouteType = {
  element: <NewCars />,
  state: 'new_cars',
  index: true,
  path: '/listings/cars/new-cars',
  sideBarProps: {
    displayText: 'New Cars',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR', 'Automotive'],
};

export { NewCars, newCarsRoute };
