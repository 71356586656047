import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBannerPayload,
  createBanner,
  deleteBanner,
  getBanners,
  updateBanner,
} from '../apis';
import { queryClient } from '../../../App';

interface IUseGetBanners {
  enabled: boolean;
}

const ALL_BANNERS_KEY = ['ALL_BANNERS'];

export const useGetBanners = ({ enabled }: IUseGetBanners) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ALL_BANNERS_KEY,
    queryFn: () => getBanners(),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBanner = () => {
  return useMutation({
    mutationFn: (data: IBannerPayload) => createBanner(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BANNERS_KEY);
    },
  });
};

export const useUpdateBanner = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBannerPayload }) => {
      return updateBanner(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BANNERS_KEY);
    },
  });
};

export const useDeleteBanner = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteBanner(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BANNERS_KEY);
    },
  });
};
