import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../../App';
import {
  UpdateBikeRequest,
  createUsedBike,
  deleteUsedBike,
  getUsedBikes,
  getUsedBikesByDealer,
  updateUsedBike,
} from '../apis';

export interface IUseGetUsedBikes {
  searchQueryParams: Record<string, string>;
  page?: number;
  perPage?: number;
}

export interface IUseGetUsedBikesByDealer {
  enabled: boolean;
  dealer_id: number | null | undefined;
}

export const USED_BIKES_QUERY_KEY = ['usedBikes'];
export const USED_BIKES_BY_DEALER_QUERY_KEY = ['usedBikesByDealer'];

export const useGetUsedBikes = ({
  searchQueryParams,
  page,
  perPage,
}: IUseGetUsedBikes) => {
  return useQuery({
    queryKey: [...USED_BIKES_QUERY_KEY, searchQueryParams, page, perPage],
    queryFn: () =>
      getUsedBikes({
        searchQueryParams,
        page,
        perPage,
      }),
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: true,
  });
};

export const useGetUsedBikesByDealer = ({
  enabled,
  dealer_id,
}: IUseGetUsedBikesByDealer) => {
  return useQuery({
    queryKey: [...USED_BIKES_BY_DEALER_QUERY_KEY, dealer_id],
    queryFn: () => getUsedBikesByDealer(dealer_id!),
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled,
  });
};

export const useCreateUsedBike = () => {
  return useMutation({
    mutationFn: createUsedBike,
    onSuccess: () => {
      queryClient.invalidateQueries(USED_BIKES_QUERY_KEY);
    },
  });
};

export const useUpdateUsedBike = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: UpdateBikeRequest }) =>
      updateUsedBike(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(USED_BIKES_QUERY_KEY);
    },
  });
};

export const useDeleteUsedBike = () => {
  return useMutation({
    mutationFn: (id: number) => deleteUsedBike(id),
    onSuccess: () => {
      queryClient.invalidateQueries(USED_BIKES_QUERY_KEY);
    },
  });
};
