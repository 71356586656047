import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBikeBatteryRangePayload,
  activateBikeBatteryRange,
  createBikeBatteryRange,
  deactivateBikeBatteryRange,
  deleteBikeBatteryRange,
  getBikeBatteryRanges,
  updateBikeBatteryRange,
} from '../apis';
import { queryClient } from '../../../../App';

export const ALL_BIKE_BATTERY_RANGES = ['allBikeBatteryRanges'];

interface IUseBikeBatteryRange {
  enabled: boolean;
  active?: boolean;
}

export const useGetBikeBatteryRange = ({
  enabled,
  active,
}: IUseBikeBatteryRange) => {
  return useQuery({
    queryKey: ALL_BIKE_BATTERY_RANGES,
    queryFn: () => getBikeBatteryRanges({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBikeBatteryRange = () => {
  return useMutation({
    mutationFn: (data: IBikeBatteryRangePayload) =>
      createBikeBatteryRange(data),
    mutationKey: ['createBikeBatteryRange'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_RANGES);
    },
  });
};

export const useUpdateBikeBatteryRange = () => {
  return useMutation({
    mutationFn: ({
      id,
      data,
    }: {
      id: number;
      data: IBikeBatteryRangePayload;
    }) => updateBikeBatteryRange(id, data),
    mutationKey: ['updateBikeBatteryRange'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_RANGES);
    },
  });
};

export const useDeleteBikeBatteryRange = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBikeBatteryRange(id),
    mutationKey: ['deleteBikeBatteryRange'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_RANGES);
    },
  });
};

export const useActivateBikeBatteryRange = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return activateBikeBatteryRange(id);
    },
    mutationKey: ['activateBikeBatteryRange'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_RANGES);
    },
  });
};

export const useDeactivateBikeBatteryRange = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deactivateBikeBatteryRange(id);
    },
    mutationKey: ['deactivateBikeBatteryRange'],
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BIKE_BATTERY_RANGES);
    },
  });
};
