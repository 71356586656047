import { useRef, useState } from 'react';
import { Image, Input, Select, Space, Table, Button, Popconfirm } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import { IRentalCar } from '../../../../models/listings/cars/rentals/RentalCar';
import { useDeleteRental, useRentalCars } from '../hooks/useRentalCars';
import { RentalModal } from './RentalModal';
import { Layout } from '../../../../components/Layout';
import { Flex } from '../../../../components/Flex';
import { queryClient } from '../../../../App';

const options: { label: string; value: OptionKeys }[] = [
  { label: 'Model Name', value: 'modelName' },
  { label: 'Dealer Name', value: 'dealerName' },
];

const placeholderOptions: Record<OptionKeys, string> = {
  modelName: 'Search by Model Name',
  dealerName: 'Search by Dealer Name',
};

const { Search } = Input;

type OptionKeys = 'modelName' | 'dealerName';

export const RentalsLayout = () => {
  //Modal State
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState<IRentalCar | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  //Pagination states
  const [carsPerPage, setCarsPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);

  // Search states
  const searchValue = useRef<string>('');
  const [searchSelectedOption, setSearchSelectedOptions] =
    useState<OptionKeys>('modelName');
  const [searchQueryParams, setSearchQueryParams] = useState<
    Record<string, string>
  >({});

  //State for Popconfirm
  const [open, setOpen] = useState<number | null>(null);
  ////#endregion

  const { mutateAsync: deleteRental, isLoading: isDeleting } =
    useDeleteRental();
  //#region Functions
  const { data: rentalCarsData, isFetching: isFetchingRentalCars } =
    useRentalCars({
      page: currentPage,
      perPage: carsPerPage,
      searchQueryParams,
    });

  const onPageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setCarsPerPage(pageSize);
  };

  const handleSelectChange = (value: OptionKeys) => {
    setSearchSelectedOptions(value);
  };

  const handleDelete = (id: number) => {
    deleteRental(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['rentalCars'],
        });
        setOpen(null);
      },
    });
  };

  const onEditClick = (car: IRentalCar) => {
    setSelectedCar(car);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setSelectedCar(null);
    setIsEditing(false);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setSelectedCar(null);
  };

  const tableColumns: ColumnsType<IRentalCar> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      fixed: 'left',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      render: (rowData: any): string => {
        return rowData?.name;
      },
    },
    {
      title: 'Image',
      dataIndex: 'cover',
      key: 'cover',
      width: 150,
      align: 'center',
      render: rowData => {
        return (
          <Image
            src={rowData}
            height={50}
            fallback="https://play-lh.googleusercontent.com/J-SpF7SizHC3yGtAIxl00_jq5q6hkZO1A2zE0efFSAjr_SNEW5N37aMgocinR75utQ=w240-h480-rw"
            alt="car image"
            style={{
              objectFit: 'cover',
            }}
          />
        );
      },
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: 70,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (rowData): string => {
        return dayjs(rowData).format('DD/MM/YY HH:mm A');
      },
      width: 120,
      ellipsis: true,
    },
    {
      title: 'Dealer',
      dataIndex: 'rental_dealer',
      key: 'rental_dealer',
      ellipsis: true,
      render: (rowData): string => {
        return rowData?.name || 'Private';
      },
    },
    {
      title: 'Price Per Day',
      dataIndex: 'price_per_day',
      align: 'center',
      key: 'price_per_day',
      width: 150,
      render: rowData => {
        return (
          <span>
            {rowData?.toString()}
            <span
              style={{
                fontSize: '0.7rem',
                marginLeft: '0.2rem',
              }}
            >
              QAR
            </span>
          </span>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: 150,
      render: (_, rowData) => {
        return (
          <div className="flex justify-content-around">
            <EditTwoTone
              style={{ fontSize: '1rem', color: 'var(--primary)' }}
              onClick={() => {
                onEditClick(rowData);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              description="Deleting this rental will delete all its related data."
              open={open === rowData.id}
              onConfirm={() => {
                handleDelete(rowData.id);
              }}
              okButtonProps={{ loading: isDeleting }}
              onCancel={() => setOpen(null)}
              okText="Yes"
              placement="topRight"
            >
              <DeleteTwoTone
                style={{ fontSize: '1rem', color: 'var(--error)' }}
                onClick={() => {
                  setOpen(rowData.id);
                }}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const expandableColumn: ColumnsType<IRentalCar> = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: rowData => {
        return (
          <Typography.Text>
            {rowData ? rowData?.replaceAll('&nbsp;', ' ') : 'N/A'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Arabic Description',
      dataIndex: 't',
      key: 't',
      render: rowData => {
        const arabicDescription = rowData?.find(
          (item: any) => item.lang === 'ar',
        ).description;
        return (
          <Typography.Text>
            {arabicDescription
              ? arabicDescription?.replaceAll('&nbsp;', ' ')
              : 'N/A'}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Small Baggage Size',
      dataIndex: 'small_baggage_size',
      key: 'small_baggage_size',
      width: 200,
    },
    {
      title: 'Large Baggage Size',
      dataIndex: 'large_baggage_size',
      key: 'large_baggage_size',
      width: 200,
    },
    {
      title: 'Price Per Week',
      dataIndex: 'price_per_week',
      key: 'price_per_week',
      render: rowData => {
        return rowData ? (
          <span>
            {rowData?.toString()}
            <span
              style={{
                fontSize: '0.7rem',
                marginLeft: '0.2rem',
              }}
            >
              QAR
            </span>
          </span>
        ) : (
          'N/A'
        );
      },
    },
    {
      title: 'Price Per Month',
      dataIndex: 'price_per_month',
      key: 'price_per_month',
      render: rowData => {
        return rowData ? (
          <span>
            {rowData?.toString()}
            <span
              style={{
                fontSize: '0.7rem',
                marginLeft: '0.2rem',
              }}
            >
              QAR
            </span>
          </span>
        ) : (
          'N/A'
        );
      },
    },
    {
      title: 'Price Per Year',
      dataIndex: 'price_per_year',
      key: 'price_per_year',
      render: rowData => {
        return rowData ? (
          <span>
            {rowData?.toString()}
            <span
              style={{
                fontSize: '0.7rem',
                marginLeft: '0.2rem',
              }}
            >
              QAR
            </span>
          </span>
        ) : (
          'N/A'
        );
      },
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      render: (rowData): string => {
        return rowData ? rowData?.toString() : 'N/A';
      },
    },
    {
      title: 'Whatsapp Number',
      dataIndex: 'whatsapp_number',
      key: 'whatsapp_number',
      render: (rowData): string => {
        return rowData ? rowData?.toString() : 'N/A';
      },
    },
  ];
  //#endregion

  return (
    <>
      <Layout>
        <Flex justify="space-between" align="center" className="mb-4">
          <Space.Compact>
            <Search
              placeholder={placeholderOptions[searchSelectedOption]}
              loading={isFetchingRentalCars}
              onSearch={value =>
                setSearchQueryParams({
                  [searchSelectedOption]: searchValue.current,
                })
              }
              style={{ width: 200 }}
              onChange={e => {
                searchValue.current = e.target.value;
                if (searchValue.current === '') setSearchQueryParams({});
              }}
            />
            <Select
              options={options}
              defaultValue={options[0].value}
              onChange={value => handleSelectChange(value)}
              style={{ width: 150 }}
            />
          </Space.Compact>
          <div
            className="flex column-gap-2 align-items-center"
            style={{ alignSelf: 'end' }}
          >
            <Button
              style={{ backgroundColor: 'var(--primary-color)' }}
              type="primary"
              onClick={openEmptyForm}
            >
              Add
            </Button>
          </div>
        </Flex>
        <Table
          dataSource={rentalCarsData?.data}
          pagination={{
            position: ['bottomCenter'],
            total: rentalCarsData?.meta?.total,
            pageSize: carsPerPage,
            onChange: onPageChange,
            current: currentPage,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
          rowKey="id"
          scroll={{ x: 1300, y: 650 }}
          columns={tableColumns}
          loading={isFetchingRentalCars}
          expandable={{
            expandedRowRender: record => {
              return (
                <Table
                  dataSource={[record]}
                  pagination={false}
                  rowKey="id"
                  columns={expandableColumn}
                />
              );
            },
            rowExpandable: record => record.description !== '',
          }}
        />
      </Layout>
      {isFormOpen && (
        <RentalModal
          selectedCar={selectedCar}
          isEditing={isEditing}
          isVisible={isFormOpen}
          onClose={closeForm}
          setSelectedCar={setSelectedCar}
          setIsFormOpen={setIsFormOpen}
        />
      )}
    </>
  );
};
