import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IBatteryRangerPayload,
  activateBatteryRange,
  createBatteryRange,
  deactivateBatteryRange,
  deleteBatteryRange,
  getBatteryRanges,
  updateBatterRange,
} from '../apis/batteryRangeApi';
import { queryClient } from '../../../../App';

export const ALL_BATTERY_RANGES = ['allBatteryRanges'];

interface IUseBatteryRange {
  enabled: boolean;
  active?: boolean;
}

export const useBatteryRange = ({ enabled, active }: IUseBatteryRange) => {
  return useQuery({
    queryKey: ALL_BATTERY_RANGES,
    queryFn: () => getBatteryRanges({ active }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
  });
};

export const useCreateBatteryRange = () => {
  return useMutation({
    mutationFn: (data: IBatteryRangerPayload) => createBatteryRange(data),
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_BATTERY_RANGES);
    },
  });
};

export const useUpdateBatteryRange = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IBatteryRangerPayload }) =>
      updateBatterRange(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['allBatteryRanges']);
    },
  });
};

export const useDeleteBatteryRange = () => {
  return useMutation({
    mutationFn: (id: number) => deleteBatteryRange(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['allBatteryRanges']);
    },
  });
};

export const useActivateBatteryRange = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return activateBatteryRange(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['allBatteryRanges']);
    },
  });
};

export const useDeactivateBatteryRange = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deactivateBatteryRange(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['allBatteryRanges']);
    },
  });
};
