import { useEffect, useState } from 'react';
import { Input, Button, Table, Tag, Image, Popconfirm, message } from 'antd';
import { useSearch } from '../../../../hooks/useSearch';
import { ICarModel } from '../../../../models/resources';
import { Layout } from '../../../../components/Layout';
import {
  useActivateModel,
  useDeactivateModel,
  useDeleteModel,
  useGetAllModels,
} from '../hooks';
import { CarBrand } from '../../../../models/resources/CarBrand';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import { FilterDropDown } from '../../../../listings/cars/used_cars/components/used_car_form/FilterDropDown';
import { IFilters } from '../../../../listings/cars/used_cars/carsApi';
import { ICarTypes } from '../../../../models/resources/CarType';
import { CheckBoxFilterDropDown } from './CheckBoxFilterDropDown';
import { ColumnsType } from 'antd/es/table';
import { ModelModal } from './ModelModal';

const { Search } = Input;

const ModelsLayout = () => {
  const { data: models, isLoading: isModelLoading } = useGetAllModels({
    enabled: true,
    brandId: undefined,
  });

  const uniqueBrandIds = new Set();

  const filterBrandOptions: IFilters[] = [
    ...(models || [])
      .map((model: ICarModel) => {
        if (!uniqueBrandIds.has(model.brand.id)) {
          uniqueBrandIds.add(model.brand.id);
          return {
            text: model.brand.name,
            value: model.brand.id,
            isDefault: false,
          };
        }
        return null;
      })
      .filter(Boolean),
  ];

  const filterStatusOptions: IFilters[] = [
    { text: 'All', value: '', isDefault: true },
    { text: 'Active', value: 'true' },
    { text: 'Inactive', value: 'false' },
  ];

  const uniqueTypeIds = new Set();

  const filterTypeOptions: IFilters[] = [
    ...(models || [])
      .flatMap((model: ICarModel) => model.model_type || [])
      .filter((type: ICarTypes) => {
        const typeId = type.types.id;
        if (!uniqueTypeIds.has(typeId)) {
          uniqueTypeIds.add(typeId);
          return true;
        }
        return false;
      })
      .map((type: ICarTypes) => ({
        text: type.types.name,
        value: type.types.id,
        isDefault: false,
      })),
  ];

  const [selectedBrandFilters, setSelectedBrandFilters] = useState<any[]>(['']);
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<any[]>(['']);

  const { mutateAsync: deleteModel, isLoading: isDeletingModel } =
    useDeleteModel();
  const { mutateAsync: activeModel, isLoading: isActive } = useActivateModel();
  const { mutateAsync: deactiveModel, isLoading: isDeactive } =
    useDeactivateModel();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentModel, setCurrentModel] = useState<ICarModel | null>(null);

  // Search States
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchCarModel } = useSearch();
  const [filteredData, setFilteredData] = useState(models);

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarModel | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ICarModel | null>(
    null,
  );

  const handleSelectBrand = (values: any) => {
    setSelectedBrandFilters(values);

    setFilteredData((prevModels: any) => {
      let newData = prevModels;

      // Check if "Clear" option is selected
      if (values?.length === 0 || (values?.length === 1 && values[0] === '')) {
        return models || [];
      }

      // Filter models based on selected brands
      newData = models?.filter((model: ICarModel) =>
        values?.length === 1
          ? values[0] === model.brand.id
          : values?.some((brandId: number) => brandId === model.brand.id),
      );

      return newData || [];
    });
  };

  const handleSelectType = (value: any) => {
    setTypeFilter(value);

    setFilteredData((prevModels: any) => {
      let newData = prevModels;

      // Check if "Clear" option is selected
      if (value?.length === 0 || (value?.length === 1 && value[0] === '')) {
        return models || [];
      }

      // Filter models based on selected brands
      newData = models?.filter((model: ICarModel) =>
        value?.length === 1
          ? value[0] === model.model_type?.[0]?.types.id
          : value?.some(
              (typeId: number) => typeId === model.model_type?.[0]?.types.id,
            ),
      );

      return newData || [];
    });
  };

  const handleSelectStatus = (value: any) => {
    setStatusFilter(value);
    if (value === '') {
      setFilteredData(models);
    } else {
      const newData = models?.filter(
        (model: ICarModel) => model.is_active.toString() === value,
      );
      setFilteredData(newData);
    }
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactiveModel(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Model Deactivated Successfully');
          },
        })
      : activeModel(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Model Activated Successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteModel(id, {
      onSuccess: () => {
        setOpenDeleteDialog(null);
        message.success('Model Deleted Successfully');
      },
    });
  };

  const onEditButtonPress = (model: ICarModel) => {
    setCurrentModel(model);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setIsEditing(false);
    setCurrentModel(null);
  };

  const openEmptyForm = () => {
    setCurrentModel(null);
    setIsFormOpen(true);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchCarModel(models, value);
  };

  useEffect(() => {
    if (!models) return;
    const newData = handleSearchCarModel(models, searchValue);
    setFilteredData(newData);
  }, [searchValue, models]);

  useEffect(() => {
    setFilteredData(models);
  }, [models]);

  const tableColumns: ColumnsType<ICarModel> = [
    {
      title: 'Model',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Image',
      dataIndex: 'cover',
      key: 'cover',
      width: '20%',
      render: (image: string) => (
        <Image
          src={image}
          alt="model"
          fallback="https://play-lh.googleusercontent.com/J-SpF7SizHC3yGtAIxl00_jq5q6hkZO1A2zE0efFSAjr_SNEW5N37aMgocinR75utQ=w240-h480-rw"
          style={{ width: '80px', height: '70px' }}
        />
      ),
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: '20%',
      render: (brand: CarBrand) => brand.name,
      filters: filterBrandOptions,
      filterDropdown: () => {
        return (
          <CheckBoxFilterDropDown
            filter={selectedBrandFilters}
            setFilter={handleSelectBrand}
            options={filterBrandOptions}
            isLoading={isModelLoading}
          />
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'model_type',
      key: 'model_type',
      width: '20%',
      render: (text: any, record: ICarModel) => {
        const carType = record.model_type?.map((type: any) => type.types.name);

        if (!carType) {
          return <Tag color="red">No Type</Tag>;
        }

        const carTypeString = carType.join(', ');

        return (
          <div
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {carTypeString}
          </div>
        );
      },
      filters: filterTypeOptions,
      filterDropdown: () => {
        return (
          <CheckBoxFilterDropDown
            filter={typeFilter}
            setFilter={handleSelectType}
            options={filterTypeOptions}
            isLoading={isModelLoading}
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarModel) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
      filters: filterStatusOptions,
      filterDropdown: () => {
        return (
          <FilterDropDown
            filter={statusFilter}
            setFilter={handleSelectStatus}
            options={filterStatusOptions}
            isLoading={isModelLoading}
          />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICarModel) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this model will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{ loading: isDeletingModel }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone
              onClick={() => {
                setOpenDeleteDialog(record);
              }}
            />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this model.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActive || isDeactive,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Models"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          New Model
        </Button>
      </div>
      <Table
        loading={models?.length === 0 || isModelLoading}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={
          models.length > 50
            ? {
                defaultPageSize: 50,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                position: ['bottomCenter'],
              }
            : false
        }
        scroll={{ x: 1300, y: 720 }}
        rowKey={record => record.id}
      />
      <ModelModal
        currentModel={currentModel}
        isEditing={isEditing}
        isFormOpen={isFormOpen}
        onClose={closeForm}
        setCurrentModel={setCurrentModel}
        setIsFormOpen={setIsFormOpen}
      />
    </Layout>
  );
};

export default ModelsLayout;
