import { useEffect, useState } from 'react';
import { ICarDealer } from '../../../models/dealers/cars/CarDealer';
import { CarBrand } from '../../../models/resources/CarBrand';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Button,
  UploadFile,
  InputNumber,
} from 'antd';
import { CustomizedRequiredMark } from '../../../components/misc/RequiredMark';
import FileUpload from '../../../components/inputs/FileUpload';
import { useCountries } from '../../../countries/hooks/useCountries';
import { Option } from '../../../components/inputs/DropDown';
import { ICity } from '../../../models/countries/City';
import { uploadImage } from '../../../common/utils';
import { AxiosError } from 'axios';
import type {
  UploadRequestError,
  UploadRequestOption,
} from 'rc-upload/lib/interface';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { BikeBrand } from '../../../models/resources/bikes/BikeBrand';

type CustomUploadFileStatus = UploadFileStatus | 'old';

type AddEditDealerFormProps = {
  dealer: ICarDealer;
  brands: CarBrand[] | null;
  bikeBrands: BikeBrand[] | null;
  onSubmit: (value: any) => void;
  isLoading: boolean;
  isEditing: boolean;
  onClose: () => void;
  addedLogo: any;
  removedLogo: any;
  setRemovedLogo: (url: Record<string, string>) => void;
  setAddedLogo: (logo: string) => void;
};

const AddEditDealerForm = ({
  dealer,
  brands,
  bikeBrands,
  onSubmit,
  isLoading,
  onClose,
  isEditing,
  addedLogo,
  setRemovedLogo,
  setAddedLogo,
}: AddEditDealerFormProps) => {
  const { Item } = Form;
  const [form] = Form.useForm();

  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const countryId = Form.useWatch('country_id', form);

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const [selectedBrands, setSelectedBrands] = useState<number[]>(
    dealer?.brand_dealer?.map(brand => brand.brands.id) || [],
  );

  const [bikeSelectedBrands, setBikeSelectedBrands] = useState<number[]>(
    dealer?.bike_brand_dealer?.map(brand => brand.id) || [],
  );

  const brandOptions = brands?.map(brand => {
    return { name: brand.name, id: brand.id };
  });

  const bikeBrandOptions = bikeBrands?.map(brand => {
    return { name: brand.name, id: brand.id };
  });

  const initialImages = dealer?.logo
    ? [
        {
          uid: `${dealer?.logo_id}`,
          name: dealer.logo.split('/').pop(),
          status: 'old',
          url: dealer?.logo,
        },
      ]
    : [];

  const initialValues = {
    name: dealer?.name,
    arabic_name: dealer?.t[0].name,
    country_id: countries?.find(country =>
      country.cities?.find(city => city?.id === dealer?.city_id),
    )?.id,
    city_id: dealer?.city_id,
    logo: initialImages,
    brand_dealer: dealer?.brand_dealer?.map(brand => brand.brands.id),
    bike_brand_dealer: dealer?.bike_brand_dealer?.map(brand => brand.brands.id),
  };

  console.log(initialValues);

  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>(
    initialImages as UploadFile[],
  );

  const imageUpload = async ({
    onSuccess,
    onProgress,
    file,
    onError,
  }: UploadRequestOption) => {
    try {
      setIsUploadingImage(true);
      const response = await uploadImage({
        file,
        path: 'dealer-logo',
        onProgress: onProgress,
      });
      if (onSuccess) onSuccess('ok');
      const addedImage = { ...response, status: 'done', file };
      setAddedLogo(addedImage.id);
      setIsUploadingImage(false);
    } catch (error) {
      const errorResponse = error as AxiosError<UploadRequestError>;
      const response = errorResponse.response?.data;
      if (onError && response) onError(response);
      setIsUploadingImage(false);
    }
  };

  const removeLogo = (file: UploadFile) => {
    if (file?.status === ('old' as CustomUploadFileStatus)) {
      setRemovedLogo({
        url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${file.name}`,
      });
    } else {
      setAddedLogo(addedLogo);
    }
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [dealer, form, countries]);

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={CustomizedRequiredMark}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input placeholder="Please enter name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[{ required: true, message: 'Please enter arabic name' }]}
          >
            <Input placeholder="Please enter arabic name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: 'Please select the country of the dealer',
              },
            ]}
          >
            <Select
              disabled={!countries || isLoadingCountries}
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={() => {
                form.setFieldsValue({
                  city_id: undefined,
                });
              }}
              size="large"
            >
              {countries?.map(country => {
                return (
                  <Select.Option key={country?.id} value={country?.id}>
                    {country?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="City"
            name="city_id"
            rules={[
              {
                required: true,
                message: 'Please select the city of the dealer',
              },
            ]}
          >
            <Select
              disabled={!countryId || isLoadingCountries}
              showSearch
              placeholder="Select a city"
              optionFilterProp="children"
              size="large"
            >
              {cityOptions?.map(city => {
                return (
                  <Select.Option key={city.code} value={city.code}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item name="brand_dealer" label="Brands">
            <Select
              mode="multiple"
              placeholder="Please Select Brands."
              value={selectedBrands}
              onChange={(value: any) => {
                setSelectedBrands(value);
              }}
              style={{ width: '100%' }}
              options={brandOptions?.map(brand => {
                return { label: brand.name, value: brand.id };
              })}
            />
          </Item>
        </Col>
        <Col span={24}>
          <Item name="bike_brand_dealer" label="Bike Brands">
            <Select
              mode="multiple"
              placeholder="Please Select Bike Brands."
              value={bikeSelectedBrands}
              onChange={(value: any) => {
                setBikeSelectedBrands(value);
              }}
              style={{ width: '100%' }}
              options={bikeBrandOptions?.map(brand => {
                return { label: brand.name, value: brand.id };
              })}
            />
          </Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        wrap
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '1rem',
          width: '100%',
          marginLeft: '45%',
        }}
      >
        <Col span={24}>
          <FileUpload
            fileList={fileList}
            label="Logo"
            maxNbFiles={1}
            name="logo"
            setFileList={setFileList}
            customRequest={props => imageUpload({ ...props })}
            onRemove={removeLogo}
            requiredMessage="Please upload a logo for the dealer"
          />
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={isUploadingImage}
          >
            {isEditing ? 'Edit Dealer' : 'Add Dealer'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};

export default AddEditDealerForm;
