import axios from 'axios';

export const checkIfApiTokenExists = (): string | null => {
  return localStorage.getItem('accessToken');
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.defaults.headers.common.Accept = 'application/json';

api.defaults.headers.common['Content-Type'] = 'application/json';

export default api;

export const getNewToken = async (refreshToken: string) => {
  const tokenUrl = '/v2/auth/refresh-token';

  const response = await api.post(tokenUrl, {
    refresh_token: refreshToken,
  });

  return response.data.access_token;
};
