import EngineSizeLayout from '../../../resources/cars/engine_sizes/EngineSizesLayout';
import { RouteType } from '../../routes/routesConfig';

const EngineSize = () => {
  return <EngineSizeLayout />;
};

const engineSizeRoute: RouteType = {
  element: <EngineSize />,
  state: 'engine-size',
  index: true,
  path: '/resources/cars/engine-size',
  sideBarProps: {
    displayText: 'Engine Size',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { EngineSize, engineSizeRoute };
