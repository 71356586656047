import { Button } from 'primereact/button';
import { ReactNode } from 'react';
import Loader from '../misc/Loader';

type CTAButtonProps = {
  className?: string;
  text?: string;
  fontColor?: string;
  fontSize?: string;
  backgroundColor?: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  isLoading?: boolean;
  onClick: (e?: any) => void;
  iconButton?: boolean;
  height?: string;
  isDisabled?: boolean;
};

const CTAButton = ({
  isDisabled = false,
  height,
  text,
  className = '',
  fontColor = '#fff',
  fontSize = '24px',
  backgroundColor,
  leadingIcon,
  trailingIcon,
  isLoading = false,
  onClick,
  iconButton = false,
}: CTAButtonProps) => {
  return (
    <Button
      text={iconButton}
      rounded={iconButton}
      onClick={onClick}
      className={`flex custom-button ${className} justify-content-evenly align-items-center ${
        iconButton && 'icon-button'
      } ${isLoading && 'disabled-button'}`}
      disabled={isLoading || isDisabled}
      style={{
        ...(!iconButton && {
          width: '100%',
          height: height || '50px',
        }),
        background: backgroundColor,
        border: 0,
        fontSize: fontSize,
        color: iconButton ? '#000 !important' : fontColor,
      }}
    >
      {isLoading && <Loader />}
      {!isLoading && leadingIcon}
      {!isLoading && text}
      {!isLoading && trailingIcon}
    </Button>
  );
};

export { CTAButton };
