import { useState } from 'react';
import AddEditModelForm from './AddEditModelForm';
import { Modal, message } from 'antd';
import { ICarModel, ICarModelImage } from '../../../../models/resources';
import { useCreateModel, useEditModel } from '../hooks';

interface ModelModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  onClose: () => void;
  isEditing: boolean;
  currentModel: ICarModel | null;
  setCurrentModel: (value: ICarModel | null) => void;
}

export const ModelModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentModel,
  setCurrentModel,
  onClose,
}: ModelModalProps) => {
  const [addedImages, setAddedImages] = useState<ICarModelImage[]>([]);
  const [removedImages, setRemovedImages] = useState<
    Array<Record<string, string>>
  >([]);

  const { mutateAsync: createModel, isLoading: isCreating } = useCreateModel();
  const { mutateAsync: updateModel, isLoading: isUpdating } = useEditModel();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      delete data.image;
      delete data.cover;
      data['add_images'] = addedImages.map(image => ({ id: image.id }));
      const response = await createModel(data);
      setIsFormOpen(false);
      setCurrentModel(null);
      message.success('Model Created Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (currentModel == null) return;
    try {
      delete data.image;
      delete data.cover;
      data['add_images'] = addedImages.map(image => ({ id: image.id }));
      data['remove_images'] = removedImages;
      const response = await updateModel({
        id: currentModel.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentModel(null);
      message.success('Model Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Model' : 'Add Model'}
        onCancel={onClose}
        open={isFormOpen}
        footer={null}
        width={1200}
        style={{ top: 20 }}
        key={currentModel?.id}
      >
        <AddEditModelForm
          isEditing={isEditing}
          model={currentModel}
          onClose={onClose}
          addedImages={addedImages}
          onSubmit={isEditing ? _edit : _create}
          removedImages={removedImages}
          setAddedImages={setAddedImages}
          setRemovedImages={setRemovedImages}
          isLoading={isCreating || isUpdating}
        />
      </Modal>
    </>
  );
};
