import { useMutation, useQuery } from '@tanstack/react-query';
import {
  EditUserRequest,
  activateUser,
  createUser,
  deactivateUser,
  deleteUser,
  getAllUsers,
  updateUser,
} from '../apis';
import { queryClient } from '../../App';
import { getAllBanks } from '../../banks/utils';
import { getAllDealers } from '../../dealers/Automotive/apis';

interface IUseGetAllUsers {
  group?: string | null;
  searchQueryParams: Record<string, string>;
  perPage?: number;
  page?: number;
}

export const useGetAllUsers = ({
  group,
  searchQueryParams,
  perPage,
  page,
}: IUseGetAllUsers) => {
  return useQuery({
    queryKey: ['users', group, searchQueryParams, perPage, page],
    queryFn: () =>
      getAllUsers({
        group,
        searchQueryParams,
        perPage,
        page,
      }),
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: true,
  });
};

export const useGetAllBanks = (isActive?: boolean) => {
  return useQuery({
    queryKey: ['banks', isActive],
    queryFn: () => getAllBanks(isActive),
    refetchOnWindowFocus: false,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: true,
  });
};

export const useCreateUser = () => {
  return useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export const useEditUser = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: EditUserRequest }) =>
      updateUser(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export const useDeleteUser = () => {
  return useMutation({
    mutationFn: (id: number) => deleteUser(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
  });
};

export const useActivateUser = () => {
  return useMutation({
    mutationFn: (id: number) => activateUser(id),
  });
};

export const useDeactivateUser = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateUser(id),
  });
};
