import BatteryCapacitiesLayout from '../../../resources/cars/car_battery_capacity/BatteryCapacitiesLayout';
import { RouteType } from '../../routes/routesConfig';

const BatteryCapacity = () => {
  return <BatteryCapacitiesLayout />;
};

const batteryCapacityRoute: RouteType = {
  element: <BatteryCapacity />,
  state: 'battery-capacity',
  index: true,
  path: '/resources/cars/batter-capacity',
  sideBarProps: {
    displayText: 'Battery Capacity',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { BatteryCapacity, batteryCapacityRoute };
