import api from '../../../../api/apiIndex';

const engineSizeEndpointv2 = 'v2/admin/resources/cars/engine-size';

export interface IGetEngineSizes {
  active?: boolean;
}

export interface IEngineSizePayload {
  name: string;
  arabic_name: string;
}

const getEngineSizes = async ({ active }: IGetEngineSizes) => {
  try {
    const response = await api.get(engineSizeEndpointv2, {
      params: { active },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const createEngineSize = async (data: IEngineSizePayload) => {
  try {
    const response = await api.post(engineSizeEndpointv2, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const updateEngineSize = async (id: number, data: IEngineSizePayload) => {
  try {
    const response = await api.put(`${engineSizeEndpointv2}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const deleteEngineSize = async (id: number) => {
  try {
    const response = await api.delete(`${engineSizeEndpointv2}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateEngineSize = async (id: number) => {
  try {
    const response = await api.put(`${engineSizeEndpointv2}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateEngineSize = async (id: number) => {
  try {
    const response = await api.put(`${engineSizeEndpointv2}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getEngineSizes, createEngineSize, updateEngineSize, deleteEngineSize };
