import { UserGroups } from '../../../common/constants';
import { UsedCarLeadsDetailsLayout } from '../../../leads/used_cars/components/UsedCarLeadsDetailsLayout';

const UsedCarLeadsDetails = () => {
  return <UsedCarLeadsDetailsLayout />;
};

const usedCarLeadsDetailsRoute = {
  path: '/leads/used-cars/:dealerSlug/:brandSlug?/:modelSlug?',
  index: true,
  element: <UsedCarLeadsDetails />,
  state: 'newCarLeadsDetails',
  sideBarProps: {
    displayText: 'Used Car Leads Details',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { UsedCarLeadsDetails, usedCarLeadsDetailsRoute };
