import { ImageGallery, ModelDescription } from './ModelDisplayComponents';
import { ICarModel } from '../../../../../models/resources';
import { Tabs } from 'antd';

interface ModelDisplayComponentProps {
  displayedImage: any;
  modelImages: string[];
  onImageClick: (image: string) => void;
  model?: ICarModel;
  selectedLanguage: string;
  onLanguageChange: (e: { value: string }) => void;
  isLoading: boolean;
  currentImageIndex: number;
  setCurrentImageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const ModelDisplayComponent = ({
  onImageClick,
  model,
  selectedLanguage,
  onLanguageChange,
  displayedImage,
  currentImageIndex,
  setCurrentImageIndex,
}: ModelDisplayComponentProps) => {
  return (
    <div
      className="flex"
      style={{ width: '100%', alignSelf: 'center', gap: 50 }}
    >
      <ImageGallery
        displayedImage={displayedImage || model?.cover}
        modelImages={
          model?.image instanceof Array
            ? model.image
            : model?.image
            ? [model.image]
            : []
        }
        onImageClick={(image: string | number) => {
          if (typeof image === 'number') {
            setCurrentImageIndex(image);
          } else {
            onImageClick(image);
          }
        }}
        currentImageIndex={currentImageIndex}
      />
      <div
        className="p-4"
        style={{
          height: '400px',
          borderRadius: '0 15px 15px 0',
          border: '1px solid #eaeaea',
          width: '100%',
        }}
      >
        <div className="flex justify-content-between" style={{ gap: 10 }}>
          <h2>{model?.name}</h2>
          <Tabs
            onChange={e => {
              onLanguageChange({ value: e });
            }}
            type="line"
            style={{ width: '150px' }}
            items={[
              { key: 'English', label: 'English' },
              { key: 'Arabic', label: 'Arabic' },
            ]}
          />
        </div>
        <div style={{ width: '100%' }}>
          <ModelDescription
            selectedLanguage={selectedLanguage}
            englishDescription={model?.description || ''}
            arabicDescription={model?.t?.[0]?.description || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default ModelDisplayComponent;
