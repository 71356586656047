import api from '../../api/apiIndex';

type TType = 'page_view' | 'button_click' | 'inquiry_start' | 'page_scroll';
type TSource = 'website' | 'mobile';
type TEvent = 'clicks' | 'impressions';

export interface IGetBrandsViews {
  from: string;
  to: string;
  per: string;
  id: number;
}

export interface IGetTotalBrandViews {
  from?: string;
  to?: string;
  type: TType;
  source?: TSource;
}

export interface IGetAdsBannerMetrics {
  from: string;
  to: string;
  per: string;
  event: TEvent;
  advertiser?: string;
}

export interface IImpression {
  banner_id: number;
  views: number;
  date: string;
}

export interface IClick {
  banner_id: number;
  views: number;
  date: string;
}

export interface IGetAdsBannerMetricsResponse {
  id: number;
  name: string;
  impressions: IImpression[];
  clicks: IClick[];
  Section_Name: string;
  Placement: string;
  Content_Type: string;
  Advertiser: string;
  Is_Image_Upload: boolean;
  En_Url: string;
  Ar_Url: string;
  CTA: string;
  CTA_Ar: string;
  Order: number;
  country_id: number;
  city_id: number;
  From_Date: string;
  To_Date: string;
  metrics_count: number;
  impressions_count: number;
  clicks_count: number;
}

const analyticsEndpoint = '/analytics';

export const getViewsByBrands = async ({
  from,
  to,
  per,
  id,
}: IGetBrandsViews) => {
  try {
    const response = await api.get(
      analyticsEndpoint +
        '/brands/views?per=' +
        per +
        '&from=' +
        from +
        '&to=' +
        to +
        '&brand_id=' +
        id +
        '',
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getTotalBrandMetrics = async ({
  type,
  source,
  from,
  to,
}: IGetTotalBrandViews) => {
  try {
    let url = analyticsEndpoint + '/brands/total?type=' + type;
    if (from && to) {
      url += '&from=' + from + '&to=' + to;
    }
    if (source) {
      url += '&source=' + source;
    }
    const response = await api.get(url);
    const data = response.data.data;

    return data ? data : 0;
  } catch (error) {
    throw error;
  }
};

export const getAdsBannerMetrics = async ({
  per,
  event,
  to,
  from,
}: IGetAdsBannerMetrics): Promise<IGetAdsBannerMetricsResponse[]> => {
  try {
    let endpoint = `${analyticsEndpoint}/banners/views`;
    if (per && event) {
      endpoint += `?per=${per}&event=${event}`;
    }
    if (from && to) {
      endpoint += `&from=${from}&to=${to}`;
    }
    const response = await api.get(endpoint);
    const data = response.data.data;
    return data ? data : [];
  } catch (error) {
    throw error;
  }
};

export const getAdvertisers = async () => {
  try {
    const response = await api.get(`/banners`);
    const filterDuplicateAdvertisers = response.data.data.filter(
      (item: any, index: number, self: any) =>
        index === self.findIndex((t: any) => t.Advertiser === item.Advertiser),
    );
    return filterDuplicateAdvertisers;
  } catch (error) {
    throw error;
  }
};
