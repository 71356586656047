import { Button, Input, Popconfirm, Tag, message } from 'antd';
import {
  useActivateBikeBatteryRange,
  useDeactivateBikeBatteryRange,
  useDeleteBikeBatteryRange,
  useGetBikeBatteryRange,
} from './hooks';
import { useGetBikeEngineType } from '../engine_types';
import { IBikeBatteryRange } from '../../../models/resources/bikes/BikeBatteryRange';
import { useEffect, useState } from 'react';
import { useSearch } from '../../../hooks/useSearch';
import { ColumnsType } from 'antd/es/table';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import { Layout } from '../../../components/Layout';
import { Table } from 'antd/lib';
import { BikeBatteryRangesModal } from './components';

const { Search } = Input;

export const BikeBatteryRangesLayout = () => {
  const { data: bikeBatteryRanges, isLoading: isLoadingBikeBatteryRanges } =
    useGetBikeBatteryRange({ enabled: true });
  const { data: bikeEngineTypes } = useGetBikeEngineType({ enabled: true });

  const { mutateAsync: deleteBikeBatteryRange, isLoading: isDeleting } =
    useDeleteBikeBatteryRange();
  const { mutateAsync: activateBikeBatteryRange, isLoading: isActivating } =
    useActivateBikeBatteryRange();
  const { mutateAsync: deactivateBikeBatteryRange, isLoading: isDeactivating } =
    useDeactivateBikeBatteryRange();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentBikeBatteryRange, setCurrentBikeBatteryRange] =
    useState<IBikeBatteryRange | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBikeBatteryRange } = useSearch();
  const [filteredData, setFilteredData] = useState(bikeBatteryRanges);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBikeBatteryRange | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<IBikeBatteryRange | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBikeBatteryRange(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Range deactivated successfully');
          },
        })
      : activateBikeBatteryRange(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Range activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteBikeBatteryRange(id, {
      onSuccess: () => {
        message.success('Battery Range deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (batteryRange: IBikeBatteryRange) => {
    setCurrentBikeBatteryRange(batteryRange);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentBikeBatteryRange(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchBikeBatteryRange(bikeBatteryRanges, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchBikeBatteryRange(
      bikeBatteryRanges,
      searchValue,
    );
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(bikeBatteryRanges);
  }, [bikeBatteryRanges]);

  const tableColumns: ColumnsType<IBikeBatteryRange> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${bikeBatteryRanges?.map(
        (bikeBatteryRange: IBikeBatteryRange) => bikeBatteryRange.t[0]?.name,
      )}`,
      key: `${bikeBatteryRanges?.map(
        (bikeBatteryRange: IBikeBatteryRange) => bikeBatteryRange.t[0]?.name,
      )}`,
      render: (text: any, record: IBikeBatteryRange) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IBikeBatteryRange) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: IBikeBatteryRange) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
            style={{ fontSize: '1rem', color: 'var(--primary)' }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this battery range will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this battery range.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Battery Ranges"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Battery Range
        </Button>
      </div>
      <Table
        loading={isLoadingBikeBatteryRanges}
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
      />
      <BikeBatteryRangesModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        currentBikeBatteryRange={currentBikeBatteryRange}
        setCurrentBikeBatteryRange={setCurrentBikeBatteryRange}
        bikeEngineTypes={bikeEngineTypes}
      />
    </Layout>
  );
};
