import { useEffect, useState } from 'react';
import { ICarBatteryRange } from '../../../models/resources/CarBatteryRange';
import { Input, Tag, Button, Table, Popconfirm, message } from 'antd';
import { useSearch } from '../../../hooks/useSearch';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import {
  useActivateBatteryRange,
  useBatteryRange,
  useDeactivateBatteryRange,
  useDeleteBatteryRange,
} from './hooks';
import { useEngineTypes } from '../engine_types/hooks';
import { BatteryRangeModal } from './components';
import { Layout } from '../../../components/Layout';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';

const { Search } = Input;

const BatteryRangesLayout = () => {
  const { data: batteryRanges, isLoading: isBatteryRangesLoading } =
    useBatteryRange({
      enabled: true,
    });
  const { data: engineTypes } = useEngineTypes({
    enabled: true,
  });

  const { mutateAsync: deleteBatteryRange, isLoading: isDeleting } =
    useDeleteBatteryRange();
  const { mutateAsync: activateBatteryRange, isLoading: isActivating } =
    useActivateBatteryRange();
  const { mutateAsync: deactivateBatteryRange, isLoading: isDeactivating } =
    useDeactivateBatteryRange();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [currentBatteryRange, setCurrentBatteryRange] =
    useState<ICarBatteryRange | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBatteryRange } = useSearch();
  const [filteredData, setFilteredData] = useState(batteryRanges);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICarBatteryRange | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<ICarBatteryRange | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBatteryRange(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Range deactivated successfully');
          },
        })
      : activateBatteryRange(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Battery Range activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteBatteryRange(id, {
      onSuccess: () => {
        message.success('Battery Range deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (batteryRange: ICarBatteryRange) => {
    setCurrentBatteryRange(batteryRange);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentBatteryRange(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchBatteryRange(batteryRanges, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchBatteryRange(batteryRanges, searchValue);
    setFilteredData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(batteryRanges);
  }, [batteryRanges]);

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: ICarBatteryRange, b: ICarBatteryRange) =>
        a.name.localeCompare(b.name),
    },
    {
      title: 'Arabic Name',
      dataIndex: `${batteryRanges?.map(
        (batteryRange: ICarBatteryRange) => batteryRange.t[0]?.name,
      )}`,
      key: `${batteryRanges?.map(
        (batteryRange: ICarBatteryRange) => batteryRange.t[0]?.name,
      )}`,
      sorter: (a: ICarBatteryRange, b: ICarBatteryRange) =>
        a.arabic_name.localeCompare(b.arabic_name),
      render: (text: any, record: ICarBatteryRange) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICarBatteryRange) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICarBatteryRange) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
            style={{ fontSize: '1rem', color: 'var(--primary)' }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this battery range will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this battery range.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Battery Ranges"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Battery Range
        </Button>
      </div>

      <Table
        loading={batteryRanges?.length === 0 || isBatteryRangesLoading}
        dataSource={filteredData}
        columns={tableColumns as any}
        pagination={false}
        rowKey={record => record.id}
        scroll={{ x: 1300, y: 720 }}
      />
      <BatteryRangeModal
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        isEditing={isEditing}
        currentBatteryRange={currentBatteryRange}
        setCurrentBatteryRange={setCurrentBatteryRange}
        engineTypes={engineTypes}
      />
    </Layout>
  );
};

export default BatteryRangesLayout;
