import api from '../../api/apiIndex';
import { IBannerResource } from '../../models/ads/BannerResource';

export const bannerResourcesEndpoint = 'v2/admin/banner-resources';

export interface IBannersPayload {
  country_id: number;
  name: string;
  arabic_name: string;
  logo: string;
  city_id: number;
}

export const getBannerResources = async (): Promise<IBannerResource[]> => {
  const response = await api.get(bannerResourcesEndpoint);
  return response.data;
};
