import { Button, Input, Popconfirm, Tag, message } from 'antd';
import {
  useActivateBikeEngineType,
  useDeactivateBikeEngineType,
  useDeleteBikeEngineType,
  useGetBikeEngineType,
} from './hooks';
import { useEffect, useState } from 'react';
import { IBikeEngineType } from '../../../models/resources/bikes/BikeEngineType';
import { useSearch } from '../../../hooks/useSearch';
import { ColumnsType } from 'antd/es/table';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import InactiveIcon from '../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../components/icons/ActiveIcon';
import { Layout } from '../../../components/Layout';
import { Table } from 'antd/lib';
import { BikeEngineTypeModal } from './components';

const { Search } = Input;

export const BikeEngineTypesLayout = () => {
  const { data: bikeEngineTypes, isLoading: bikeEngineTypeLoading } =
    useGetBikeEngineType({
      enabled: true,
    });

  const { mutateAsync: deleteBikeEngineType, isLoading: isDeleting } =
    useDeleteBikeEngineType();
  const { mutateAsync: activateBikeEngineType, isLoading: isActivating } =
    useActivateBikeEngineType();
  const { mutateAsync: deactivateBikeEngineType, isLoading: isDeactivating } =
    useDeactivateBikeEngineType();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [currentBikeEngineType, setCurrentBikeEngineType] =
    useState<IBikeEngineType | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { handleSearchBikeEngineType } = useSearch();
  const [filteredData, setFilteredData] = useState(bikeEngineTypes);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<IBikeEngineType | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] =
    useState<IBikeEngineType | null>(null);

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateBikeEngineType(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Engine Type deactivated successfully');
          },
        })
      : activateBikeEngineType(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Engine Type activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteBikeEngineType(id, {
      onSuccess: () => {
        message.success('Engine Type deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (record: IBikeEngineType) => {
    setCurrentBikeEngineType(record);
    setIsFormOpen(true);
    setIsEditing(true);
  };

  const openEmptyForm = () => {
    setCurrentBikeEngineType(null);
    setIsFormOpen(true);
    setIsEditing(false);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    handleSearchBikeEngineType(bikeEngineTypes, searchValue);
  };

  useEffect(() => {
    const newData = handleSearchBikeEngineType(bikeEngineTypes, searchValue);
    setFilteredData(newData);
  }, [searchValue]);

  useEffect(() => {
    setFilteredData(bikeEngineTypes);
  }, [bikeEngineTypes]);

  const tableColumns: ColumnsType<IBikeEngineType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${bikeEngineTypes?.map(
        (type: IBikeEngineType) => type.t[0]?.name,
      )}`,
      key: `${bikeEngineTypes?.map(
        (type: IBikeEngineType) => type.t[0]?.name,
      )}`,
      render: (text: any, record: IBikeEngineType) => (
        <div>{record.t?.[0]?.name}</div>
      ),
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (text: any, record: IBikeEngineType) => (
        <div className="flex flex-column">
          <span>
            {record.label ? record.label : <Tag color="red">Not Found</Tag>}
          </span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: IBikeEngineType) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: IBikeEngineType) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this engine type will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this engine type.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-between w-full mb-5">
        <Search
          placeholder="Search Engine Types"
          onSearch={onSearch}
          style={{ width: 200 }}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button type="primary" onClick={openEmptyForm}>
          Add Engine Type
        </Button>
      </div>
      <Table
        loading={bikeEngineTypeLoading}
        dataSource={filteredData}
        columns={tableColumns}
        rowKey={record => record.id}
        pagination={false}
        scroll={{ x: 1300, y: 720 }}
      />
      <BikeEngineTypeModal
        currentBikeEngineType={currentBikeEngineType}
        isEditing={isEditing}
        isFormOpen={isFormOpen}
        isLoading={bikeEngineTypeLoading}
        setCurrentBikeEngineType={setCurrentBikeEngineType}
        setIsEditing={setIsEditing}
        setIsFormOpen={setIsFormOpen}
        key={currentBikeEngineType?.id}
      />
    </Layout>
  );
};
