import { Form, Row, Col, Input, Button, Checkbox, Space } from 'antd';
import { IEngineType } from '../../../../models/resources/CarEngineType';
import { ICylinders } from '../../../../models/resources/CarCylinder';

interface CarCylindersAddEditFormProps {
  resource: ICylinders | null;
  isCreating: boolean;
  isUpdating: boolean;
  isEditing: boolean;
  engineTypes: IEngineType[] | null;
  onClose: () => void;
  onSubmit: (values: any) => void;
  isLoading: boolean;
}

const { Item } = Form;
export const CarCylindersAddEditForm = ({
  isCreating,
  isUpdating,
  isEditing,
  engineTypes,
  onClose,
  resource,
  onSubmit,
}: CarCylindersAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: resource?.name,
    arabic_name: resource?.t?.[0]?.name,
    engine_types: resource?.cylinder_engine_type?.map(
      (engineType: any) => engineType?.engine_types?.id,
    ),
  };

  const resetForm = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input placeholder="Name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[{ required: true, message: 'Arabic Name is required' }]}
          >
            <Input placeholder="Arabic Name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="engine_types"
            label="Engine Size Type"
            rules={[{ required: true, message: 'Please select engine size' }]}
          >
            <Checkbox.Group>
              {engineTypes?.map((engineType: IEngineType) => (
                <Checkbox value={engineType?.id}>{engineType?.name}</Checkbox>
              ))}
            </Checkbox.Group>
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              resetForm();
            }}
            danger
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isCreating || isUpdating}
          >
            {isEditing ? 'Edit Cylinder' : 'Add Cylinder'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
