import { IBikeModel } from '../../../../models/resources/bikes/BikeModel';
import { INewBike } from '../../../../models/listings/bikes/newBikes';
import { useCreateNewBikeDeal, useUpdateNewBikeDeal } from '../hooks';
import { Modal, message } from 'antd';
import { INewBikeDealPayload } from '../apis';
import { AddEditNewBikeDealForm } from './AddEditNewBikeDealForm';

interface NewBikeDealModalProps {
  model: IBikeModel;
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditingDeal: boolean;
  currentDeal: INewBike;
  setCurrentDeal: (value: INewBike | null) => void;
}

export const NewBikeDealModal = ({
  currentDeal,
  isEditingDeal,
  isFormOpen,
  model,
  setCurrentDeal,
  setIsFormOpen,
}: NewBikeDealModalProps) => {
  const { mutateAsync: createDeal, isLoading: isCreatingDeal } =
    useCreateNewBikeDeal();
  const { mutateAsync: updateDeal, isLoading: isUpdatingDeal } =
    useUpdateNewBikeDeal();

  const _create = async (data: INewBikeDealPayload) => {
    if (!data) {
      return;
    }
    data.price = data.price === null ? null : Number(data.price);
    data.call_to_action =
      data.call_to_action === null ? null : data.call_to_action;
    data.arabic_call_to_action =
      data.arabic_call_to_action === null ? null : data.arabic_call_to_action;
    const response = await createDeal(data);
    setIsFormOpen(false);
    setCurrentDeal(null);
    message.success('Deal Created Successfully');
    return response;
  };

  const _edit = async (data: INewBikeDealPayload) => {
    data.price = data.price === null ? null : Number(data.price);
    data.call_to_action =
      data.call_to_action === null ? null : data.call_to_action;
    data.arabic_call_to_action =
      data.arabic_call_to_action === null ? null : data.arabic_call_to_action;
    const response = await updateDeal({
      id: currentDeal!.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentDeal(null);
    message.success('Deal Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        title={isEditingDeal ? 'Edit Deal' : 'Add Deal'}
        width={1200}
        destroyOnClose
        style={{ top: 20 }}
      >
        <AddEditNewBikeDealForm
          model={model}
          onClose={() => setIsFormOpen(false)}
          deal={currentDeal}
          isEditing={isEditingDeal}
          onSubmit={isEditingDeal ? _edit : _create}
          isLoading={isCreatingDeal || isUpdatingDeal}
        />
      </Modal>
    </>
  );
};
