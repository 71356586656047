import { Modal, message } from 'antd';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { IBikeOptionalFeature } from '../../../../models/resources/bikes/BikeOptionalFeatures';
import { IBikesOptionalFeaturesPayload } from '../apis';
import {
  useCreateBikeOptionalFeature,
  useUpdateBikeOptionalFeature,
} from '../hooks';
import { AddEditBikeOptionalFeatureForm } from './AddEditBikeOptionalFeatureForm';

interface IBikeOptionalFeatureModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedBikeOptionalFeature?: IBikeOptionalFeature | null;
  setSelectedBikeOptionalFeature: (value: IBikeOptionalFeature | null) => void;
  bikeEngineTypes: IBikeEngineType[] | null;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

export const BikeOptionalFeatureModal = ({
  isOpen,
  bikeEngineTypes,
  isEditing,
  setIsOpen,
  setSelectedBikeOptionalFeature,
  selectedBikeOptionalFeature,
}: IBikeOptionalFeatureModalProps) => {
  const { mutateAsync: createBikeOptionalFeature, isLoading: isCreating } =
    useCreateBikeOptionalFeature();
  const { mutateAsync: updateBikeOptionalFeature, isLoading: isUpdating } =
    useUpdateBikeOptionalFeature();

  const _create = async (data: IBikesOptionalFeaturesPayload) => {
    if (data == null) return;
    const response = await createBikeOptionalFeature(data);
    setIsOpen(false);
    setSelectedBikeOptionalFeature(null);
    message.success('Optional Feature Created Successfully');
    return response;
  };

  const _edit = async (data: IBikesOptionalFeaturesPayload) => {
    const response = await updateBikeOptionalFeature({
      id: selectedBikeOptionalFeature!.id,
      data,
    });
    setIsOpen(false);
    setSelectedBikeOptionalFeature(null);
    message.success('Optional Feature Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Optional Feature' : 'Add Optional Feature'}
        className="crud-dialog"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={1200}
        key={selectedBikeOptionalFeature?.id}
        destroyOnClose
      >
        <AddEditBikeOptionalFeatureForm
          bikeOptionalFeature={selectedBikeOptionalFeature}
          onClose={() => setIsOpen(false)}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          bikeEngineTypes={bikeEngineTypes}
          key={selectedBikeOptionalFeature?.id}
        />
      </Modal>
    </>
  );
};
