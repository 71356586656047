import { Modal, message } from 'antd';
import { ICarDrivetrain } from '../../../../models/resources/CarDrivetrain';
import { DriveTrainAddEditForm } from './DriveTrainAddEditForm';
import { useCreateDriveTrain, useUpdateDriveTrain } from '../hooks';

interface DriveTrainModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentDrivetrain: ICarDrivetrain | null;
  setCurrentDrivetrain: (value: ICarDrivetrain | null) => void;
  engineTypes: any;
}

export const DriveTrainModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentDrivetrain,
  setCurrentDrivetrain,
  engineTypes,
}: DriveTrainModalProps) => {
  const { mutateAsync: createDrivetrain, isLoading: isCreating } =
    useCreateDriveTrain();
  const { mutateAsync: updateDrivetrain, isLoading: isUpdating } =
    useUpdateDriveTrain();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createDrivetrain(data);
      setIsFormOpen(false);
      message.success('Drivetrain created successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const _edit = async (data: any) => {
    if (data == null) return;
    try {
      const response = await updateDrivetrain({
        id: currentDrivetrain!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentDrivetrain(null);
      message.success('Drivetrain updated successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit DriveTrain' : 'Add DriveTrain'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentDrivetrain?.id}
      >
        <DriveTrainAddEditForm
          resource={currentDrivetrain}
          onClose={() => setIsFormOpen(false)}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          engineTypes={engineTypes}
        />
      </Modal>
    </>
  );
};
