import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import appRoutes from '../../routes/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

type MenuItem = Required<MenuProps>['items'][number] & {
  disabled?: boolean;
};

const selectedKeys = (pathname: string) => {
  if (pathname === '/') {
    return ['/overview'];
  } else if (pathname.includes('/listings/cars/new-cars')) {
    return ['/listings/cars/new-cars'];
  } else if (pathname.includes('/leads/rentals')) {
    return ['/leads/rentals'];
  }
  return [pathname];
};

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAuth();
  const userGroup = user.userInfo?.group;
  const menuItems: MenuItem[] = appRoutes
    .map(route => ({
      key: route.path ? route.path : route.state,
      label: route.sideBarProps?.displayText,
      icon: route.sideBarProps?.icon,
      disabled: route.disabled,
      allowed: userGroup ? !route.allowedGroups?.includes(userGroup) : false,
      ...(route.children && {
        children: route.children
          .map(child => ({
            key: child.path ? child.path : child.state,
            label: child.sideBarProps?.displayText,
            icon: child.sideBarProps?.icon,
            disabled: child.disabled,
            allowed: userGroup
              ? !child.allowedGroups?.includes(userGroup)
              : false,
            children: child.children
              ?.map(grandChild => ({
                key: grandChild.path ? grandChild.path : grandChild.state,
                label: grandChild.sideBarProps?.displayText,
                icon: grandChild.sideBarProps?.icon,
                disabled: grandChild.disabled,
                allowed: userGroup
                  ? !grandChild.allowedGroups?.includes(userGroup)
                  : false,
              }))
              .filter(item => !item.allowed),
          }))
          .filter(item => !item.allowed),
      }),
    }))
    .filter(item => !item.allowed);

  const defaultOpenKeys = location.pathname
    .split('/')
    .slice(1, window.location.pathname.split('/').length - 1)
    .map((_, index, arr) => `${arr.slice(0, index + 1).join('.')}`);

  return (
    <Menu
      mode="inline"
      items={menuItems}
      selectedKeys={selectedKeys(location.pathname)}
      onClick={e => {
        navigate(e.key);
      }}
      style={{
        overflowY: 'scroll',
        borderRight: 'none',
      }}
      defaultOpenKeys={defaultOpenKeys}
    />
  );
};

export default SideBar;
