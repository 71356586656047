import { useEffect } from 'react';
import CarCylindersLayout from '../../../resources/cars/car_cylinders/CarCylindersLayout';
import { RouteType } from '../../routes/routesConfig';

const Cylinders = () => {
  return <CarCylindersLayout />;
};

const cylindersRoute: RouteType = {
  element: <Cylinders />,
  state: 'cylinders',
  index: true,
  path: '/resources/cars/cylinders',
  sideBarProps: {
    displayText: 'Cylinders',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Cylinders, cylindersRoute };
