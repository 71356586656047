import { Modal, message } from 'antd';
import { IBikeBatteryRange } from '../../../../models/resources/bikes/BikeBatteryRange';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { IBikeBatteryRangePayload } from '../apis';
import { useCreateBikeBatteryRange, useUpdateBikeBatteryRange } from '../hooks';
import { BikeBatteryRangesAddEditForm } from './BikeBatteryRangesAddEditForm';

interface BikeBatteryRangeModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentBikeBatteryRange: IBikeBatteryRange | null;
  setCurrentBikeBatteryRange: (value: IBikeBatteryRange | null) => void;
  bikeEngineTypes: IBikeEngineType[] | null;
}

export const BikeBatteryRangesModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBikeBatteryRange,
  setCurrentBikeBatteryRange,
  bikeEngineTypes,
}: BikeBatteryRangeModalProps) => {
  const { mutateAsync: createBikeBatteryRange, isLoading: isCreating } =
    useCreateBikeBatteryRange();
  const { mutateAsync: updateBikeBatteryRange, isLoading: isUpdating } =
    useUpdateBikeBatteryRange();

  const _create = async (data: IBikeBatteryRangePayload) => {
    if (data == null) return;
    const response = await createBikeBatteryRange(data);
    setIsFormOpen(false);
    setCurrentBikeBatteryRange(null);
    message.success('Battery Range Created Successfully');
    return response;
  };

  const _edit = async (data: IBikeBatteryRangePayload) => {
    if (data == null) return;
    const response = await updateBikeBatteryRange({
      id: currentBikeBatteryRange!.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentBikeBatteryRange(null);
    message.success('Battery Range Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        title={isEditing ? 'Edit Battery Range' : 'Add Battery Range'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentBikeBatteryRange?.id}
        destroyOnClose
      >
        <BikeBatteryRangesAddEditForm
          bikeBatteryRange={currentBikeBatteryRange}
          bikeEngineTypes={bikeEngineTypes}
          onSubmit={isEditing ? _edit : _create}
          isEditing={isEditing}
          isLoading={isCreating || isUpdating}
          onClose={() => setIsFormOpen(false)}
        />
      </Modal>
    </>
  );
};
