import api from '../api/apiIndex';

const countriesEndpoint = 'v2/admin/resources/countries';

type CreateCountryRequest = {
  name: string;
  arabic_name: string;
  isActive: number;
};

type UpdateCountryRequest = {
  name?: string;
  arabic_name?: string;
  isActive?: number;
};

const getCountries = async () => {
  const response = await api.get(countriesEndpoint);
  return response.data;
};

const getActiveCountries = async () => {
  const response = await api.get(`${countriesEndpoint}`, {
    params: { isActive: true },
  });
  return response.data;
};

const createCountry = async (request: CreateCountryRequest) => {
  const response = await api.post(countriesEndpoint, request);
  return response.data;
};

const updateCountry = async (id: number, request: UpdateCountryRequest) => {
  const response = await api.put(`${countriesEndpoint}/${id}`, request);
  return response.data;
};

const deleteCountry = async (id: number) => {
  const response = await api.delete(`${countriesEndpoint}/${id}`);
  return response.data.message;
};

const getCitiesByCountry = async (id: number) => {
  if (!id) {
    return [];
  }
  const response = await api.get(`${countriesEndpoint}/cities/${id}`);
  return response.data;
};

export {
  getCountries,
  getActiveCountries,
  createCountry,
  updateCountry,
  deleteCountry,
  getCitiesByCountry,
};
