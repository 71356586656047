import { RouteType } from '../../../routes/routesConfig';
import { UserGroups } from '../../../../common/constants';
import BikeModelsLayout from '../../../../resources/bikes/bike_models/components/BikeModelsLayout';

const BikeModels = () => {
  return <BikeModelsLayout />;
};

const bikeModelsRoute: RouteType = {
  element: <BikeModels />,
  state: 'models',
  index: true,
  path: '/resources/bikes/models',
  sideBarProps: {
    displayText: 'Models',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC],
};

export { BikeModels, bikeModelsRoute };
