import { UserGroups } from '../../../common/constants';
import { RamadanOfferLeadsDetailsLayout } from '../../../leads/ramadan_offers/components/RamadanOfferLeadsDetailsLayout';

const RamadanOfferLeadsDetails = () => {
  return <RamadanOfferLeadsDetailsLayout />;
};

const ramadanOfferLeadsDetailsRoute = {
  path: '/leads/ramadan-offers/:dealerSlug/:brandSlug?/:modelSlug?',
  index: true,
  element: <RamadanOfferLeadsDetails />,
  state: 'ramadanOfferLeadsDetails',
  sideBarProps: {
    displayText: 'Ramadan Offer Leads Details',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { RamadanOfferLeadsDetails, ramadanOfferLeadsDetailsRoute };
