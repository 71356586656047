import { Table, Button, Popconfirm, Tag, message } from 'antd';
import { Layout } from '../../../../components/Layout';
import {
  useActivateLocation,
  useDeactivateLocation,
  useDeleteLocation,
  useGetLocations,
} from '../useLocationsApi';
import { useCountries } from '../../../../countries/hooks/useCountries';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { LocationsModal } from './LocationsModal';
import { ILocation } from '../../../../models/locations/Location';
import InactiveIcon from '../../../../components/icons/InactiveIcon';
import ActiveIcon from '../../../../components/icons/ActiveIcon';

export const LocationsLayout = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [currentLocation, setCurrentLocation] = useState<ILocation | null>(
    null,
  );

  const { data: locations, isLoading } = useGetLocations({});
  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });
  const { mutateAsync: deleteLocation, isLoading: isDeleting } =
    useDeleteLocation();

  const { mutateAsync: activateLocation, isLoading: isActivating } =
    useActivateLocation();

  const { mutateAsync: deactivateLocation, isLoading: isDeactivating } =
    useDeactivateLocation();

  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ILocation | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ILocation | null>(
    null,
  );

  //Handlers
  const handleDelete = (id: number) => {
    deleteLocation(id, {
      onSuccess: () => {
        message.success('Location deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateLocation(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Location deactivated successfully');
          },
        })
      : activateLocation(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Location activated successfully');
          },
        });
  };

  const onEditButtonPress = (location: ILocation) => {
    setCurrentLocation(location);
    setIsEditing(true);
    setIsFormOpen(true);
  };

  const openEmptyForm = () => {
    setCurrentLocation(null);
    setIsEditing(false);
    setIsFormOpen(true);
  };
  //Table Columns
  const tableColumns: ColumnsType<ILocation> = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
    },
    {
      title: 'Arabic Address',
      dataIndex: `${locations?.map(
        (location: ILocation) => location.t?.[0]?.address,
      )}`,
      key: `${locations?.map(
        (location: ILocation) => location.t?.[0]?.address,
      )}`,
      render: (text: any, record: ILocation) => {
        return record.t?.[0]?.address;
      },
      ellipsis: true,
    },
    {
      title: 'Country',
      dataIndex: 'country_id',
      key: 'country_id',
      width: 150,
      align: 'center',
      render: (countryId: number) => {
        const country = countries?.find(country => country.id === countryId);
        return country?.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ILocation) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: rowData => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(rowData);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this location will delete all its related data."
            open={openDeleteDialog === rowData}
            onConfirm={() => {
              handleDelete(rowData.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone
              onClick={() => {
                setOpenDeleteDialog(rowData);
              }}
            />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              rowData.is_active ? 'deactivate' : 'activate'
            } this location.`}
            open={openToggleActivateDialog === rowData}
            onConfirm={() =>
              handleToggleActivate(rowData.id, rowData.is_active)
            }
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {rowData.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(rowData);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(rowData);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={openEmptyForm}>
          Add Location
        </Button>
      </div>
      <Table
        columns={tableColumns}
        dataSource={locations}
        loading={isLoading || isLoadingCountries}
        rowKey={rowData => rowData.id}
        scroll={{ x: 1300, y: 720 }}
        pagination={false}
      />
      <LocationsModal
        isOpen={isFormOpen}
        setIsOpen={setIsFormOpen}
        isEditing={isEditing}
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        locationLoading={isLoading}
        setIsEditing={setIsEditing}
      />
    </Layout>
  );
};
