import { Modal, message } from 'antd';
import AddEditBikeBrandForm from './AddEditBikeBrandForm';
import { useState } from 'react';
import { IBikeBrandLogos } from '../../../../models/resources/bikes/BikeBrand';
import { useCreateBikeBrand, useEditBikeBrand } from '../hooks';
import { UploadFileStatus } from 'antd/es/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

interface BikeBrandsModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  currentBrand: any;
  setCurrentBrand: (value: any) => void;
  onClose: () => void;
}

export type CustomUploadFileStatus = UploadFileStatus | 'old';

export interface IBikeBrandAddedLogos extends IBikeBrandLogos {
  file: UploadRequestOption['file'];
  status: CustomUploadFileStatus;
}

export const BikeBrandsModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentBrand,
  setCurrentBrand,
  onClose,
}: BikeBrandsModalProps) => {
  const [addedLogos, setAddedLogos] = useState<IBikeBrandAddedLogos[]>([]);
  const [removedLogos, setRemovedLogos] = useState<
    Array<Record<string, string>>
  >([]);
  const { mutateAsync: createBrand, isLoading: isCreating } =
    useCreateBikeBrand();
  const { mutateAsync: updateBrand, isLoading: isUpdating } =
    useEditBikeBrand();

  const _create = async (brand: any) => {
    if (brand == null) return;
    delete brand.original_logo;
    delete brand.white_logo;
    brand['add_logos'] = addedLogos
      .filter(logo => logo.status === 'done')
      .map(logo => ({ id: logo.id }));
    const response = await createBrand(brand);
    setIsFormOpen(false);
    setCurrentBrand(null);
    message.success('Bike Brand Created Successfully');
    return response;
  };

  const _edit = async (data: any) => {
    delete data.original_logo;
    delete data.white_logo;
    data['add_logos'] = addedLogos
      .filter(logo => logo.status === 'done')
      .map(logo => ({ id: logo.id }));
    data['remove_logos'] = removedLogos;
    const response = await updateBrand({
      id: currentBrand!.id,
      data,
    });
    setIsFormOpen(false);
    setCurrentBrand(null);
    message.success('Bike Brand Updated Successfully');
    return response;
  };

  return (
    <>
      <Modal
        className="crud-dialog"
        title={isEditing ? 'Edit Bike Brand' : 'Add Bike Brand'}
        open={isFormOpen}
        onCancel={onClose}
        footer={null}
        width={1200}
        key={currentBrand?.id}
        style={{ top: 20 }}
      >
        <AddEditBikeBrandForm
          addedLogos={addedLogos}
          removedLogos={removedLogos}
          setAddedLogos={setAddedLogos}
          setRemovedLogos={setRemovedLogos}
          isLoading={isCreating || isUpdating}
          isEditing={isEditing}
          brand={currentBrand}
          onClose={onClose}
          onSubmit={isEditing ? _edit : _create}
        />
      </Modal>
    </>
  );
};
