import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ModelDisplayCard from '../../../../resources/common/components/ModelDisplayCard';
import { Input } from 'antd';
import { useSearch } from '../../../../hooks/useSearch';
import { EmptyState } from '../../../../components/emptyState/EmptyState';
import { Spin } from 'antd';
import { Layout } from '../../../../components/Layout';
import { useGetModelsByBrandV2 } from '../../../../resources/cars/car_models/hooks/useModels';
import { ICarModel } from '../../../../models/resources';

const { Search } = Input;

const NewCar = () => {
  const { brandId } = useParams();
  const { data: models, isLoading: isLoadingModels } = useGetModelsByBrandV2(
    Number(brandId),
  );
  const [modelsSearchValue, setModelsSearchValue] = useState<string>('');
  const [modelFilteredData, setModelFilteredData] = useState(models);
  const { handleSearchNewCarsModel } = useSearch();

  useEffect(() => {
    const newBrandData = handleSearchNewCarsModel(models, modelsSearchValue);
    setModelFilteredData(newBrandData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelsSearchValue]);

  useEffect(() => {
    setModelFilteredData(models);
  }, [models]);

  return (
    <Layout>
      {isLoadingModels ? (
        <div
          className="w-full p-5 flex justify-content-center align-items-center"
          style={{ height: '400px' }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          <div className="flex flex-column">
            <div className="flex justify-content-between w-full mt-2 mb-5">
              <Search
                placeholder="Search Here"
                style={{ width: 200 }}
                onChange={e => setModelsSearchValue(e.target.value)}
              />
            </div>
            <div className="flex flex-wrap gap-5">
              {modelFilteredData?.length > 0 ? (
                modelFilteredData.map((model: ICarModel) => (
                  <Link
                    key={model?.id}
                    className="link"
                    to={`/listings/cars/new-cars/${model.brand?.id}/${model.slug}`}
                  >
                    <ModelDisplayCard
                      canEdit={false}
                      coverImageUrl={model?.cover}
                      brandLogoUrl={model?.brand?.original_logo}
                      brandName={model?.brand?.name}
                      modelName={model?.name}
                      onEdit={() => {}}
                      onDelete={() => {}}
                    />
                  </Link>
                ))
              ) : (
                <EmptyState type="models" />
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default NewCar;
