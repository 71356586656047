import { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import appRoutes from './routes';
import { RouteType } from './routesConfig';

const generateRoutes = (routes: Array<RouteType>): ReactNode => {
  return routes.map(route =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={route.element}
        key={route.state}
        loader={route.api}
      />
    ) : (
      <Route path={route.path} element={route.element} key={route.state}>
        {route.children && generateRoutes(route.children)}
      </Route>
    ),
  );
};

export const generateRoute = (route: RouteType): ReactNode => {
  return route.index ? (
    <Route index path={route.path} element={route.element} key={route.state} />
  ) : (
    <Route path={route.path} element={route.element} key={route.state}>
      {route.children && generateRoutes(route.children)}
    </Route>
  );
};

export const routes: ReactNode = generateRoutes(appRoutes);
