import api from '../../../../api/apiIndex';

const bikeConditionEndpoint = 'v2/admin/resources/bikes/conditions';

export interface IBikeConditionPayload {
  name: string;
  arabic_name: string;
}

export interface IGetBikeConditions {
  active?: boolean;
}

export const getBikeConditions = async ({ active }: IGetBikeConditions) => {
  try {
    const response = await api.get(bikeConditionEndpoint, {
      params: {
        active,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createBikeCondition = async (data: IBikeConditionPayload) => {
  const response = await api.post(bikeConditionEndpoint, data);
  return response.data;
};

export const updateBikeCondition = async (
  id: number,
  data: IBikeConditionPayload,
) => {
  const response = await api.put(`${bikeConditionEndpoint}/${id}`, data);
  return response.data;
};

export const deleteBikeCondition = async (id: number) => {
  try {
    const response = await api.delete(`${bikeConditionEndpoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const activateBikeCondition = async (id: number) => {
  try {
    const response = await api.put(`${bikeConditionEndpoint}/activate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deactivateBikeCondition = async (id: number) => {
  try {
    const response = await api.put(`${bikeConditionEndpoint}/deactivate/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
