/* eslint-disable react-hooks/exhaustive-deps */
import { RouteType } from '../../../routes/routesConfig';
import NewCarsBrand from '../../../../listings/cars/new_cars/components/NewCarsBrand';

const Brands = () => {
  return <NewCarsBrand />;
};

const newCarsBrandRoute: RouteType = {
  element: <Brands />,
  state: 'new_cars',
  index: true,
  path: '/listings/cars/new-cars/:brandId',
  sideBarProps: {
    displayText: 'New Cars',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR', 'Automotive'],
};

export { Brands, newCarsBrandRoute };
