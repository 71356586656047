import { UserGroups } from '../../../common/constants';
import { SettingsLayout } from '../../../settings/SettingsLayout';
import { RouteType } from '../../routes/routesConfig';

export const Settings = () => {
  return <SettingsLayout />;
};

export const settingsRoute: RouteType = {
  element: <Settings />,
  state: 'lead-emails',
  index: true,
  path: '/settings',
  sideBarProps: {
    displayText: 'Settings',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive, UserGroups.Rental],
};
