import { useEffect } from 'react';
import ModelsLayout from '../../../resources/cars/car_models/components/ModelsLayout';
import { RouteType } from '../../routes/routesConfig';

const Models = () => {
  return <ModelsLayout />;
};

const modelsRoute: RouteType = {
  element: <Models />,
  state: 'models',
  index: true,
  path: '/resources/cars/models',
  sideBarProps: {
    displayText: 'Models',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { Models, modelsRoute };
