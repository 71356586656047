import { useMutation, useQuery } from '@tanstack/react-query';
import {
  IRamadanOfferPayload,
  activateRamadanOffer,
  createRamadanOffer,
  deactivateRamadanOffer,
  deleteRamadanOffer,
  getRamadanOffers,
  updateRamadanOffer,
} from '../apis';
import { queryClient } from '../../../../App';

interface IGetRamadanOffers {
  dealerId?: number;
  waitForDealerId?: boolean;
}

export const useGetRamadanOffers = ({
  dealerId,
  waitForDealerId = false,
}: IGetRamadanOffers) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['ramadan_offers', dealerId],
    queryFn: () => getRamadanOffers(dealerId),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: waitForDealerId ? !!dealerId : true,
  });
};

export const useActivateRamadanOffer = () => {
  return useMutation({
    mutationFn: (id: number) => activateRamadanOffer(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ramadan_offers'],
      });
    },
  });
};

export const useDeactivateRamadanOffer = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateRamadanOffer(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ramadan_offers'],
      });
    },
  });
};

export const useDeleteRamadanOffer = () => {
  return useMutation({
    mutationFn: (id: number) => deleteRamadanOffer(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ramadan_offers'],
      });
    },
  });
};

export const useCreateRamadanOffer = () => {
  return useMutation({
    mutationFn: (request: IRamadanOfferPayload) => createRamadanOffer(request),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ramadan_offers'],
      });
    },
  });
};

export const useUpdateRamadanOffer = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: IRamadanOfferPayload }) =>
      updateRamadanOffer(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ramadan_offers'],
      });
    },
  });
};
