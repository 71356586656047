import { Skeleton } from 'antd';

export const SkeletonLoader = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          className="flex justify-content-between w-full mb-3"
          style={{ flexDirection: 'row-reverse' }}
        >
          <Skeleton.Button
            style={{ width: 100, height: 30, marginBottom: 16 }}
            active
          />
          <Skeleton.Avatar shape="square" size={50} active />
        </div>
        <div>
          <Skeleton active />
        </div>
      </div>
      <div className="flex flex-column row-gap-5">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 50,
          }}
        >
          <Skeleton.Image
            style={{
              width: '300px',
              height: '300px',
            }}
          />
          <Skeleton active />
        </div>
        <div className="flex column-gap-3">
          <Skeleton.Avatar shape="square" size={70} active />
          <Skeleton.Avatar shape="square" size={70} active />
        </div>
        <Skeleton active />
        <Skeleton active />
      </div>
    </>
  );
};
