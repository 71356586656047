import { Tag, Button, Table, Popconfirm, message } from 'antd';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import {
  useActivateCountry,
  useCountries,
  useDeactivateCountry,
  useDeleteCountry,
} from './hooks';
import { ICountry } from '../../models/countries/Country';
import { ColumnsType } from 'antd/es/table';
import InactiveIcon from '../../components/icons/InactiveIcon';
import ActiveIcon from '../../components/icons/ActiveIcon';
import { Layout } from '../../components/Layout';
import { CountriesModal } from './components';

export const CountriesLayout = () => {
  const { data: countries, isLoading: isCountriesLoading } = useCountries({
    enabled: true,
  });
  const { mutateAsync: deleteCountry, isLoading: isDeleting } =
    useDeleteCountry();
  const { mutateAsync: activateCountry, isLoading: isActivating } =
    useActivateCountry();
  const { mutateAsync: deactivateCountry, isLoading: isDeactivating } =
    useDeactivateCountry();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentCountry, setCurrentCountry] = useState<ICountry | null>(null);
  const [openToggleActivateDialog, setOpenToggleActivateDialog] =
    useState<ICountry | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<ICountry | null>(
    null,
  );

  const handleToggleActivate = (id: number, is_active: boolean) => {
    is_active
      ? deactivateCountry(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Country deactivated successfully');
          },
        })
      : activateCountry(id, {
          onSuccess: () => {
            setOpenToggleActivateDialog(null);
            message.success('Country activated successfully');
          },
        });
  };

  const handleDelete = (id: number) => {
    deleteCountry(id, {
      onSuccess: () => {
        message.success('Country deleted successfully');
        setOpenDeleteDialog(null);
      },
    });
  };

  const onEditButtonPress = (country: ICountry) => {
    setCurrentCountry(country);
    setIsFormOpen(true);
    setIsEditing(true);
  };

  const openEmptyForm = () => {
    setIsFormOpen(true);
    setIsEditing(false);
    setCurrentCountry(null);
  };

  const tableColumns: ColumnsType<ICountry> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Arabic Name',
      dataIndex: `${countries?.map(
        (country: ICountry) => country?.t?.[0]?.name,
      )}`,
      key: `${countries?.map((country: ICountry) => country?.t?.[0]?.name)}`,
      render(text: any, record) {
        return record.t[0].name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      fixed: 'right',
      align: 'center',
      render: (text: any, record: ICountry) =>
        record.is_active === true ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      fixed: 'right',
      render: (text: any, record: ICountry) => (
        <div
          className="flex justify-content-around"
          style={{
            width: 'fit-content',
            gap: '2rem',
          }}
        >
          <EditTwoTone
            onClick={() => {
              onEditButtonPress(record);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            description="Deleting this country will delete all its related data."
            open={openDeleteDialog === record}
            onConfirm={() => {
              handleDelete(record.id);
            }}
            okButtonProps={{ loading: isDeleting }}
            onCancel={() => setOpenDeleteDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            <DeleteTwoTone onClick={() => setOpenDeleteDialog(record)} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            description={`Clicking "Yes" will ${
              record.is_active ? 'deactivate' : 'activate'
            } this country.`}
            open={openToggleActivateDialog === record}
            onConfirm={() => handleToggleActivate(record.id, record.is_active)}
            okButtonProps={{
              loading: isActivating || isDeactivating,
            }}
            onCancel={() => setOpenToggleActivateDialog(null)}
            okText="Yes"
            placement="topRight"
          >
            {record.is_active ? (
              <InactiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            ) : (
              <ActiveIcon
                onClick={() => {
                  setOpenToggleActivateDialog(record);
                }}
              />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div className="flex justify-content-end w-full mb-5">
        <Button type="primary" onClick={openEmptyForm}>
          Add Country
        </Button>
      </div>
      <Table
        loading={countries?.length === 0 || isCountriesLoading}
        dataSource={countries}
        columns={tableColumns}
        scroll={{ x: 1300, y: 720 }}
        rowKey={record => record.id}
        pagination={false}
      />
      <CountriesModal
        currentCountry={currentCountry}
        isEditing={isEditing}
        isFormOpen={isFormOpen}
        setCurrentCountry={setCurrentCountry}
        setIsEditing={setIsEditing}
        setIsFormOpen={setIsFormOpen}
      />
    </Layout>
  );
};
