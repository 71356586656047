import { UserGroups } from '../../../common/constants';
import { NewCarLeadsLayout } from '../../../leads/new_cars/components/NewCarLeadsLayout';

const NewCarLeads = () => {
  return <NewCarLeadsLayout />;
};

const newCarLeadsRoute = {
  path: '/leads/new-cars',
  index: true,
  element: <NewCarLeads />,
  state: 'newCarLeads',
  sideBarProps: {
    displayText: 'New Cars',
    icon: <></>,
  },
  allowedGroups: [UserGroups.OAC, UserGroups.Automotive],
};

export { NewCarLeads, newCarLeadsRoute };
