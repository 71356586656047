import UploadedImagePreview from '../../../../../../components/misc/UploadedImagePreview';

interface ImageGalleryProps {
  displayedImage: string;
  modelImages: string[];
  onImageClick: (image: string) => void;
  currentImageIndex: number;
}

export const ImageGallery = ({
  displayedImage,
  modelImages,
  onImageClick,
  currentImageIndex,
}: ImageGalleryProps) => {
  return (
    <div className="flex flex-column row-gap-5">
      <div
        style={{
          height: '320px',
          alignSelf: 'center',
        }}
      >
        <UploadedImagePreview url={displayedImage} preview={true} />
      </div>
      <div className="flex column-gap-3">
        {modelImages.map((image, index) => (
          <div
            key={index}
            onClick={() => onImageClick(image)}
            className={`relative ${
              index === currentImageIndex ? 'border-2 border-cyan-500' : ''
            }`}
            style={{ height: '70px', aspectRatio: '1', cursor: 'pointer' }}
          >
            <UploadedImagePreview url={image} preview={false} />
          </div>
        ))}
      </div>
    </div>
  );
};
