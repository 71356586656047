import { useMutation, useQuery } from '@tanstack/react-query';
import { CarCategory } from '../../../../listings/cars/used_cars/carsApi';
import {
  ICarModelPayload,
  activateModel,
  createModel,
  deactivateModel,
  deleteModel,
  getAllModels,
  getModelBySlug,
  getModelsByBrand,
  getModelsByBrandV2,
  updateModel,
} from '../apis';
import { queryClient } from '../../../../App';

export interface IUseGetAllModel {
  enabled: boolean;
  dealerId?: number;
  brandId?: number;
  active?: boolean;
  type?: string;
}

interface IUseModelNames {
  enabled: boolean;
  brandId: number | undefined;
  carCategory: CarCategory;
}

export const buildModelNamesById = (id: number) => [
  'MODEL_NAMES_BY_BRAND_ID:',
  id,
];

export const useGetAllModels = ({
  enabled,
  dealerId,
  brandId,
  active,
  type,
}: IUseGetAllModel) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['models', dealerId, brandId, active],
    queryFn: () => getAllModels({ dealerId, brandId, active, type }),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useModelNames = ({
  enabled,
  brandId,
  carCategory,
}: IUseModelNames) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: buildModelNamesById(brandId as number),
    queryFn: () => getModelsByBrand(carCategory, brandId as number),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled,
    placeholderData: [],
  });
};

export const useGetModelsByBrandV2 = (id: number) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['MODELS_BY_BRAND_ID_V2', id],
    queryFn: () => getModelsByBrandV2(id),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useGetModelBySlug = (slug: string) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['MODEL_BY_Slug', slug],
    queryFn: () => getModelBySlug(slug),
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
    enabled: true,
  });
};

export const useDeleteModel = () => {
  return useMutation({
    mutationFn: (id: number) => deleteModel(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
    },
  });
};

export const useActivateModel = () => {
  return useMutation({
    mutationFn: (id: number) => activateModel(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
    },
  });
};

export const useDeactivateModel = () => {
  return useMutation({
    mutationFn: (id: number) => deactivateModel(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
    },
  });
};

export const useCreateModel = () => {
  return useMutation({
    mutationFn: (data: ICarModelPayload) => createModel(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
    },
  });
};

export const useEditModel = () => {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: ICarModelPayload }) =>
      updateModel(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['models']);
    },
  });
};
