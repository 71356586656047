import CarSeatMaterialsLayout from '../../../resources/cars/car_seat_material/components/CarSeatMaterialsLayout';
import { RouteType } from '../../routes/routesConfig';

const SeatMaterial = () => {
  return <CarSeatMaterialsLayout />;
};

const seatMaterialRoute: RouteType = {
  element: <SeatMaterial />,
  state: 'seat-material',
  index: true,
  path: '/resources/cars/seat-material',
  sideBarProps: {
    displayText: 'Seat Material',
    icon: <></>,
  },
  allowedGroups: ['ONE AUTOCAR'],
};

export { SeatMaterial, seatMaterialRoute };
