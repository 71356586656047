import { useEffect, useState } from 'react';
import { Option } from '../../components/inputs/DropDown';
import User from '../../models/User';
import { useGetRentalDealers } from '../../dealers/RentalDealer/hooks';
import { Form, Input, Button, Row, Col, Space, Select, Checkbox } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { CustomizedRequiredMark } from '../../components/misc/RequiredMark';
import { useCountries } from '../../countries/hooks/useCountries';
import { ICity } from '../../models/countries/City';
import { useGetAllBanks } from '../hooks/useGetAllUsers';
import { UserGroups } from '../../common/constants';
import { useGetAllDealers } from '../../dealers/Automotive/hooks';

const { Item } = Form;

type AddEditUserFormProps = {
  user?: User | null;
  onSubmit: (user: User) => void;
  isLoading: boolean;
  isEditing: boolean;
  onClose: () => void;
  isErrors: boolean;
};

const adminGroups = [
  UserGroups.OAC,
  UserGroups.Automotive,
  UserGroups.Banking,
  UserGroups.User,
  UserGroups.Rental,
];

const levelAccesses = [
  { groups: [UserGroups.OAC], value: 'Super Admin' },
  {
    groups: [
      UserGroups.OAC,
      UserGroups.Automotive,
      UserGroups.Banking,
      UserGroups.Rental,
    ],
    value: 'Admin',
  },
  {
    groups: [
      UserGroups.OAC,
      UserGroups.Automotive,
      UserGroups.Banking,
      UserGroups.Rental,
    ],
    value: 'Analyst',
  },
  { groups: [UserGroups.OAC], value: 'Editor' },
];

export const AddEditUserForm = ({
  user,
  isLoading,
  onSubmit,
  isEditing,
  onClose,
  isErrors,
}: AddEditUserFormProps) => {
  const [form] = Form.useForm();
  const [selectedGroup, setSelectedGroup] = useState<String | undefined>(
    user?.group || undefined,
  );
  const [selectedAccessLevel, setSelectedAccessLevel] = useState<
    Option | undefined
  >(user?.access ? { code: user.access, name: user.access } : undefined);
  const [cityOptions, setCityOptions] = useState<Array<Option>>();

  const countryId = Form.useWatch('country_id', form);

  const { data: countries, isLoading: isLoadingCountries } = useCountries({
    enabled: true,
  });

  const { data: dealers, isLoading: isLoadingDealers } = useGetAllDealers({
    enabled: true,
  });

  const { data: banks, isLoading: isLoadingBanks } = useGetAllBanks(true);

  const [selectedBank, setSelectedBank] = useState<Option>();

  const { data: rentalDealers, isLoading: isLoadingRentalDealers } =
    useGetRentalDealers({ enabled: true });

  const initialValues = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    group: user?.group,
    access: user?.access,
    email: user?.email,
    country_id: user?.country_id,
    city_id: user?.city_id,
    dealer_id: user?.dealer_id,
    rental_dealer_id: user?.rental_dealer_id,
    bank_id: user?.bank_id,
    receive_email_notifications: user?.receive_email_notifications,
  };

  const resetForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    const cityOptions = countries
      ?.find(country => country.id === countryId)
      ?.cities?.map((city: ICity) => {
        return { code: city.id, name: city.name };
      });
    setCityOptions(cityOptions);
  }, [countries, countryId]);

  useEffect(() => {
    form.resetFields();
  }, [user, form, countries]);

  return (
    <Form
      form={form}
      onFinish={values => {
        onSubmit(values);
        resetForm();
      }}
      initialValues={initialValues}
      layout="vertical"
      requiredMark={CustomizedRequiredMark}
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Last Name is required' }]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={20}>
          <Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Email is required' }]}
          >
            <Input />
          </Item>
        </Col>
        <Col span={4}>
          <Item
            name="receive_email_notifications"
            valuePropName="checked"
            label="Receive Emails"
          >
            <Checkbox
              disabled={
                selectedGroup !== UserGroups.OAC &&
                selectedGroup !== UserGroups.Automotive &&
                selectedGroup !== UserGroups.Rental
              }
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            ></Checkbox>
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="password"
            label="Password"
            rules={[
              {
                required: isEditing ? false : true,
                message: 'Password is required',
              },
            ]}
          >
            <Input.Password
              placeholder="input password"
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="group"
            label="Group"
            rules={[{ required: true, message: 'Group is required' }]}
          >
            <Select
              onChange={value => {
                setSelectedGroup(value);
              }}
            >
              {adminGroups.map(group => (
                <Select.Option key={group} value={group}>
                  {group}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>

      {selectedGroup === UserGroups.Automotive && (
        <Row gutter={16} wrap>
          <Col span={24}>
            <Item
              name="dealer_id"
              label="Dealer"
              rules={[{ required: true, message: 'Dealer is required' }]}
            >
              <Select loading={isLoadingDealers}>
                {dealers?.map((dealer: any) => (
                  <Select.Option key={dealer.id} value={dealer.id}>
                    {dealer?.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
      )}

      {selectedGroup === UserGroups.Rental && (
        <Row gutter={16} wrap>
          <Col span={24}>
            <Item
              name="rental_dealer_id"
              label="Rental Dealer"
              rules={[{ required: true, message: 'Rental Dealer is required' }]}
            >
              <Select loading={isLoadingRentalDealers}>
                {(rentalDealers || []).map(dealer => (
                  <Select.Option key={dealer.id} value={dealer.id}>
                    {dealer.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
      )}

      {selectedGroup === UserGroups.Banking && (
        <Row gutter={16} wrap>
          <Col span={24}>
            <Item
              name="bank_id"
              label="Bank"
              rules={[{ required: true, message: 'Bank is required' }]}
            >
              <Select
                loading={isLoadingBanks}
                onChange={value => {
                  setSelectedBank(value);
                }}
                value={selectedBank}
              >
                {banks?.map((bank: any) => (
                  <Select.Option key={bank.id} value={bank.id}>
                    {bank.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
      )}

      {selectedGroup !== UserGroups.User && (
        <Row gutter={16} wrap>
          <Col span={24}>
            <Form.Item
              name="access"
              label="Access Level"
              rules={[
                {
                  required: selectedGroup !== 'Users',
                  message: 'Please select Access Level',
                },
              ]}
            >
              <Select
                value={selectedAccessLevel}
                onChange={value => setSelectedAccessLevel(value)}
                placeholder="Please Select Access Level."
                disabled={!selectedGroup}
              >
                {levelAccesses
                  .filter(access =>
                    access.groups.includes(selectedGroup as UserGroups),
                  )
                  .map(access => {
                    return (
                      <Select.Option key={access.value} value={access.value}>
                        {access.value}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            label="Country"
            name="country_id"
            rules={[
              {
                required: true,
                message: 'Please select the country of the user',
              },
            ]}
          >
            <Select
              disabled={!countries || isLoadingCountries}
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={() => {
                form.setFieldsValue({
                  city_id: undefined,
                });
              }}
              size="large"
            >
              {countries?.map(countries => {
                return (
                  <Select.Option key={countries.id} value={countries.id}>
                    {countries.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="City"
            name="city_id"
            rules={[
              {
                required: true,
                message: 'Please select the city of the user',
              },
            ]}
          >
            <Select
              disabled={!countryId}
              showSearch
              placeholder="Select a city"
              optionFilterProp="children"
              size="large"
            >
              {cityOptions?.map((city: Option) => {
                return (
                  <Select.Option key={city.code} value={city.code}>
                    {city.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
              form.resetFields();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
