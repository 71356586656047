import { Modal, message } from 'antd';
import { EngineSizeAddEditForm } from './EngineSizeAddEditForm';
import { useCreateEngineSizes, useUpdateEngineSizes } from '../hooks';
import { CarEngineSize } from '../../../../models/resources/CarEngineSize';

interface EngineSizeModalProps {
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentEngineSize: any;
  setCurrentEngineSize: (value: any) => void;
  isLoadingEngineSizes: boolean;
  engineTypes: any;
}

export const EngineSizeModal = ({
  isFormOpen,
  setIsFormOpen,
  isEditing,
  currentEngineSize,
  isLoadingEngineSizes,
  engineTypes,
  setCurrentEngineSize,
}: EngineSizeModalProps) => {
  const { mutateAsync: createEngineSize, isLoading: isCreating } =
    useCreateEngineSizes();
  const { mutateAsync: updateEngineSize, isLoading: isUpdating } =
    useUpdateEngineSizes();

  const _create = async (data: any) => {
    if (data == null) return;
    try {
      const response = await createEngineSize(data);
      message.success('Engine Size Created Successfully');
      setIsFormOpen(false);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const _edit = async (data: any) => {
    try {
      const response = await updateEngineSize({
        id: currentEngineSize!.id,
        data,
      });
      setIsFormOpen(false);
      setCurrentEngineSize(null);
      message.success('Engine Size Updated Successfully');
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Modal
        title={isEditing ? 'Edit Engine Size' : 'Add Engine Size'}
        className="crud-dialog"
        open={isFormOpen}
        onCancel={() => setIsFormOpen(false)}
        footer={null}
        width={1200}
        key={currentEngineSize?.id}
      >
        <EngineSizeAddEditForm
          resourceSize={currentEngineSize}
          onClose={() => setIsFormOpen(false)}
          isEditing={isEditing}
          isLoading={isLoadingEngineSizes}
          engineTypes={engineTypes}
          onSubmit={isEditing ? _edit : _create}
          isUpdating={isUpdating}
          isCreating={isCreating}
        />
      </Modal>
    </>
  );
};
