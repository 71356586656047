import { Image } from 'antd';

type UploadedImagePreviewProps = {
  url?: string;
  file?: Blob;
  preview?: boolean;
};

const UploadedImagePreview = ({
  url,
  file,
  preview,
}: UploadedImagePreviewProps) => {
  const imageUrl =
    url == null && file != undefined ? URL.createObjectURL(file) : url;

  return (
    <>
      {imageUrl && (
        <Image
          preview={preview}
          src={imageUrl}
          alt="uploaded-image"
          style={{
            objectFit: 'contain',
            maxHeight: '320px',
          }}
        />
      )}
    </>
  );
};

export default UploadedImagePreview;
