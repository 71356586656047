import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import { IBikeEngineSize } from '../../../../models/resources/bikes/BikeEngineSize';
import { IBikeEngineType } from '../../../../models/resources/bikes/BikeEngineType';
import { IBikeEngineSizePayload } from '../apis';

interface BikeEngineSizeAddEditFormProps {
  bikeEngineSize: IBikeEngineSize | null;
  bikeEngineTypes: IBikeEngineType[] | null;
  isEditing: boolean;
  onClose: () => void;
  onSubmit: (values: IBikeEngineSizePayload) => Promise<void>;
  isLoading: boolean;
}

const { Item } = Form;

export const AddEditBikeEngineSizeForm = ({
  bikeEngineSize,
  bikeEngineTypes,
  isLoading,
  isEditing,
  onClose,
  onSubmit,
}: BikeEngineSizeAddEditFormProps) => {
  const [form] = Form.useForm();
  const initialValues = {
    name: bikeEngineSize?.name,
    arabic_name: bikeEngineSize?.t?.[0]?.name,
    bike_engine_types: bikeEngineSize?.bike_engine_size_bike_engine_type?.map(
      bikeEngineType => bikeEngineType?.bike_engine_types?.id,
    ),
  };

  return (
    <Form
      form={form}
      onFinish={async (values: IBikeEngineSizePayload) => {
        try {
          await onSubmit(values);
        } catch (error) {
          console.error(error);
        }
      }}
      initialValues={initialValues}
      layout="vertical"
    >
      <Row gutter={16} wrap>
        <Col span={12}>
          <Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter name' }]}
          >
            <Input placeholder="Please enter name" />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="arabic_name"
            label="Arabic Name"
            rules={[{ required: true, message: 'Please enter arabic name' }]}
          >
            <Input placeholder="Please enter arabic name" />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} wrap>
        <Col span={24}>
          <Item
            name="bike_engine_types"
            label="Engine Type"
            rules={[{ required: true, message: 'Please select engine type' }]}
          >
            <Checkbox.Group>
              {bikeEngineTypes?.map((bikeEngineType: IBikeEngineType) => (
                <Checkbox value={bikeEngineType?.id}>
                  {bikeEngineType?.name}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Item>
        </Col>
      </Row>
      <Row
        style={{
          paddingTop: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Space>
          <Button
            onClick={() => {
              onClose();
            }}
            danger
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEditing ? 'Edit' : 'Add'}
          </Button>
        </Space>
      </Row>
    </Form>
  );
};
